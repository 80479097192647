import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import Badge from 'react-badger';
import { connect } from 'react-redux';
import Shapeshifter from 'react-shapeshifter';

import {
  closeSidebar,
  unlockAndOpenSidebar,
  unlockSidebar,
} from '../../actions/sidebar';
import { isSidebarLocked, isSidebarOpen } from '../../selectors/sidebar';
import { hasUpdateAvailable } from '../../selectors/updates';
import { countUnsynchronizedSurveys } from '../../selectors/surveys';

import './SidebarToggleButton.css';

/**
 * React component that toggles the open/closed state of the sidebar.
 */
const SidebarToggleButton = ({
  badgeVisible,
  floating,
  locked,
  onClose,
  onOpen,
  onUnlock,
  open,
}) => (
  <div className={clsx('SidebarToggleButton', { floating })}>
    <Shapeshifter
      onClick={!locked && open ? onClose : onOpen}
      aria-label={open ? 'Close sidebar' : 'Open sidebar'}
      shape={open ? 'close' : 'menu'}
      color="white"
    />
    {badgeVisible ? <Badge offset={8} /> : null}
  </div>
);

SidebarToggleButton.propTypes = {
  badgeVisible: PropTypes.bool,
  floating: PropTypes.bool,
  locked: PropTypes.bool,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  onUnlock: PropTypes.func,
  open: PropTypes.bool,
};

export default connect(
  // mapStateToProps
  (state) => ({
    badgeVisible:
      countUnsynchronizedSurveys(state) > 0 || hasUpdateAvailable(state),
    locked: isSidebarLocked(state),
    open: isSidebarOpen(state),
  }),
  // mapDispatchToProps
  (dispatch) => ({
    onClose: () => {
      dispatch(closeSidebar());
    },
    onOpen: () => {
      dispatch(unlockAndOpenSidebar());
    },
    onUnlock: () => {
      dispatch(unlockSidebar());
    },
  })
)(SidebarToggleButton);
