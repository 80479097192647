import { DateTime } from 'luxon';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import Cancel from '@material-ui/icons/Cancel';
import Edit from '@material-ui/icons/Edit';
import Lock from '@material-ui/icons/Lock';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import AutoSyncSwitch from './AutoSyncSwitch';
import DeviceNameDisplay from './DeviceNameDisplay';
import SyncStatusSummary from './SyncStatusSummary';
import UpdateStatusDisplay from './UpdateStatusDisplay';

import { setAPICredentialsForAdminDialog } from '../../actions/admin';
import { closeSidebar, closeAndLockSidebar } from '../../actions/sidebar';
import { restartSurvey, setCurrentSurveyMode } from '../../actions/surveys';
import config from '../../config';
import { SurveyMode } from '../../model';
import { isSidebarOpen } from '../../selectors/sidebar';
import {
  getCurrentSurveyMode,
  isSurveyInProgress,
} from '../../selectors/surveys';
import { fromMillis } from '../../utils';

import './Sidebar.css';

const buildDateAsNumber = process.env.REACT_APP_BUILD_DATE
  ? Number(process.env.REACT_APP_BUILD_DATE)
  : NaN;
const buildDate =
  !isNaN(buildDateAsNumber) && buildDateAsNumber > 0
    ? fromMillis(buildDateAsNumber, 'local').toLocaleString(
        DateTime.DATETIME_MED
      )
    : undefined;

/**
 * The sidebar of the application.
 */
const Sidebar = ({
  isSurveyInProgress,
  onCancelCurrentSurvey,
  onInstallUpdate,
  onLockSidebar,
  onRequestClose,
  onTogglePaperAndPencilMode,
  open,
  surveyMode,
}) => (
  <Drawer
    PaperProps={{ className: 'Sidebar' }}
    open={open}
    onClose={onRequestClose}
  >
    <div
      style={{
        display: 'flex',
        flexFlow: 'column',
        height: '100%',
        alignItems: 'justifyContent',
      }}
    >
      <List style={{ flex: 1, overflowY: 'auto' }}>
        <DeviceNameDisplay />
        <UpdateStatusDisplay />

        <Divider />
        <SyncStatusSummary />
        <AutoSyncSwitch />
        <Divider />

        <ListItem button onClick={onTogglePaperAndPencilMode}>
          <ListItemIcon>
            <Edit />
          </ListItemIcon>
          <ListItemText primary="Paper-and-pencil mode" />
          <ListItemSecondaryAction>
            <Switch
              checked={surveyMode === SurveyMode.PAPER_AND_PENCIL}
              onChange={onTogglePaperAndPencilMode}
            />
          </ListItemSecondaryAction>
        </ListItem>

        <ListItem
          button
          onClick={onCancelCurrentSurvey}
          disabled={!isSurveyInProgress}
        >
          <ListItemIcon>
            <Cancel />
          </ListItemIcon>
          <ListItemText primary="Cancel current survey" />
        </ListItem>

        <Divider />

        <ListItem button onClick={onLockSidebar}>
          <ListItemIcon>
            <Lock />
          </ListItemIcon>
          <ListItemText primary="Lock sidebar" />
        </ListItem>
      </List>

      {buildDate && (
        <footer>
          <Typography color="textSecondary" align="center" gutterBottom>
            Build date: {buildDate}
          </Typography>
        </footer>
      )}
    </div>
  </Drawer>
);

Sidebar.propTypes = {
  isSurveyInProgress: PropTypes.bool,
  onCancelCurrentSurvey: PropTypes.func,
  onLockSidebar: PropTypes.func,
  onRequestClose: PropTypes.func,
  onTogglePaperAndPencilMode: PropTypes.func,
  open: PropTypes.bool,
  surveyMode: PropTypes.oneOf(Object.values(SurveyMode)),
};

export default connect(
  // mapStateToProps
  (state) => ({
    isSurveyInProgress: isSurveyInProgress(state),
    open: isSidebarOpen(state),
    surveyMode: getCurrentSurveyMode(state),
  }),
  // mapDispatchToProps
  (dispatch) => ({
    onCancelCurrentSurvey: () => {
      dispatch(restartSurvey());
      dispatch(closeSidebar());
    },
    onInstallUpdate: () => {
      window.location.reload(true);
    },
    onLockSidebar: () => {
      dispatch(setAPICredentialsForAdminDialog(null));
      dispatch(closeAndLockSidebar());
    },
    onTogglePaperAndPencilMode: () => {
      dispatch((dispatch, getState) => {
        const { mode } = getState().surveys.current.meta;
        dispatch(
          setCurrentSurveyMode(
            mode === config.primarySurveyMode
              ? SurveyMode.PAPER_AND_PENCIL
              : config.primarySurveyMode
          )
        );
      });
    },
    onRequestClose: () => {
      dispatch(closeSidebar());
    },
  })
)(Sidebar);
