/**
 * @file Survey-related action factories.
 */

import { createAction } from 'redux-actions';
import { nanoid } from 'nanoid';

import Api from '../api';

import { SynchronizationStatus } from '../model';
import { getAuthenticationCredentials } from '../selectors/admin';
import { getDeviceName } from '../selectors/onboarding';
import {
  encryptCurrentSurveyData,
  getCurrentPageIndex,
  getCurrentResponses,
  getCurrentSurveyData,
  getCurrentSurveyLanguage,
  getCurrentSurveyVariant,
  getExistingSurveyIds,
  proposeSurveyVariantToStart,
} from '../selectors/surveys';
import { now, pickUnused } from '../utils';

import {
  FINISHED_LOADING_SURVEY_STATISTICS,
  FINISH_SURVEY,
  MARK_SURVEY_AS_UNSUBMITTED_BY_ID,
  REMOVE_SURVEY_BY_ID,
  REMOVE_SURVEYS_BY_IDS,
  RESTART_SURVEY,
  SAVE_SURVEY,
  SET_CURRENT_SURVEY_MODE,
  SET_CURRENT_SURVEY_PAGE,
  SHOW_BACKMATTER,
  STARTED_LOADING_SURVEY_STATISTICS,
  START_NEW_SURVEY,
  UPDATE_RESPONSE,
  SURVEY_STATISTICS_LOADING_ERROR,
} from './types';

/**
 * Creates an action that navigates to the given page on the survey.
 */
export const navigateToSurveyPage = createAction(
  SET_CURRENT_SURVEY_PAGE,
  (page) => ({
    index: page,
    time: now(),
  })
);

export const invokeSoftValidatorForSurvey = async (survey, state) => {
  const currentPageIndex = getCurrentPageIndex(state);
  const language = getCurrentSurveyLanguage(state);
  const variant = getCurrentSurveyVariant(state);
  const currentPageId = survey.getPageIdFromIndex(currentPageIndex, variant);
  const softValidator = survey.getSoftValidatorOfPageById(
    currentPageId,
    language
  );
  if (softValidator) {
    const responses = getCurrentResponses(state);
    return await softValidator(responses);
  } else {
    return true;
  }
};

/**
 * Wrapper that wraps an action with another one that checks the soft validator
 * function of the current page (if any) and asks for confirmation if needed.
 */
const withSoftValidator = (func) => (survey, ...args) => async (
  dispatch,
  getState
) => {
  const state = getState();
  const isValid = await invokeSoftValidatorForSurvey(survey, state);
  if (isValid) {
    dispatch(func(survey, ...args));
  }
};

/**
 * Creates an action that navigates to the next page on the survey unconditionally,
 * irrespectively of whether the current page has a soft validator function or
 * not.
 */
const navigateToNextSurveyPageUnconditionally = (survey) => (
  dispatch,
  getState
) => {
  const state = getState();
  const page = getCurrentPageIndex(state);
  const language = getCurrentSurveyLanguage(state);
  const variant = getCurrentSurveyVariant(state);
  const responses = getCurrentResponses(state);
  const allPageIds = survey.getPagesInVariant(variant);

  for (let newPage = page + 1; newPage < allPageIds.length; newPage++) {
    if (survey.isPageVisible(allPageIds[newPage], { language, responses })) {
      dispatch(navigateToSurveyPage(newPage));
      return;
    }
  }

  console.warn('No visible pages left in survey after the current one.');
};

/**
 * Creates an action that navigates to the next page on the survey, optionally
 * calling the soft validator function and waiting for the confirmation of the
 * user if needed.
 */
export const navigateToNextSurveyPage = withSoftValidator(
  navigateToNextSurveyPageUnconditionally
);

/**
 * Creates an action that navigates to the previous page on the survey,
 * not allowing the user to step back to page 0 (which is the title page).
 */
export const navigateToPreviousSurveyPage = (survey) => (
  dispatch,
  getState
) => {
  const state = getState();
  const page = getCurrentPageIndex(state);
  const language = getCurrentSurveyLanguage(state);
  const variant = getCurrentSurveyVariant(state);
  const responses = getCurrentResponses(state);
  const allPageIds = survey.getPagesInVariant(variant);

  for (let newPage = page - 1; newPage >= 0; newPage--) {
    if (survey.isPageVisible(allPageIds[newPage], { language, responses })) {
      dispatch(navigateToSurveyPage(newPage));
      return;
    }
  }

  console.warn('No visible pages left in survey before the current one.');
};

/**
 * Creates an action that calculates and stores the time it took to record
 * the survey (based on the start time of the survey and the current time)
 * and also updates the metadata with the non-sensitive response values
 * that are allowed in the metadata.
 *
 * This function is not exported to prevent the user from setting arbitrary
 * dates and metadata manually. Use `saveCurrentSurvey()` instead - it
 * will take care of passing the right finish time.
 *
 * @param {object} extraMetadata  extra key-value pairs to merge into the
 *        metadata of the finished survey
 * @param {boolean} reason  whether the survey was completed normally
 *        ("ok"), was cancelled by the respondent before it has ended
 *        ("cancelled") or was cancelled due to inactivity ("inactive")
 */
const finishSurvey = createAction(
  FINISH_SURVEY,
  (extraMetadata, reason = 'ok') => ({
    extraMetadata,
    cancelled: reason !== 'ok',
    time: now(),
  })
);

/**
 * Marks the stored survey with the given ID as ready for submission.
 */
export const markSurveyAsUnsubmittedById = createAction(
  MARK_SURVEY_AS_UNSUBMITTED_BY_ID,
  (id) => ({
    id,
  })
);

/**
 * Removes the stored survey with the given ID from the device.
 */
export const removeSurveyById = createAction(REMOVE_SURVEY_BY_ID, (id) => ({
  id,
}));

/**
 * Removes multiple stored surveys with the given IDs from the device.
 */
export const removeSurveysByIds = createAction(
  REMOVE_SURVEYS_BY_IDS,
  (ids) => ({
    ids,
  })
);

/**
 * Creates an action that drops the responses from the current survey and
 * navigates back to the initial page where a new survey can be started.
 */
export const restartSurvey = createAction(RESTART_SURVEY);

/**
 * Creates an action that shows the backmatter of the survey.
 */
export const showBackmatter = createAction(SHOW_BACKMATTER);

/**
 * Creates a thunk that shows the backmatter of the current survey if it has
 * any for the current language, or returns to the front page otherwise.
 */
export const showBackmatterOrRestartSurvey = (survey) => (
  dispatch,
  getState
) => {
  const state = getState();
  const language = getCurrentSurveyLanguage(state);

  if (survey.hasBackmatter(language)) {
    dispatch(showBackmatter());
  } else {
    dispatch(restartSurvey());
  }
};

/**
 * Creates an action that saves a new survey to the list of stored surveys.
 * The action creator requires the ID of the survey and its encrypted
 * representation, as returned by the `encryptCurrentSurveyData()`
 *
 * The ID *must not* exist in the store; the reducer handling this action
 * will throw an error if the ID already exists.
 *
 * This function is not exported to prevent the user from submitting
 * arbitrary (unencrypted) data to this action. Use `saveCurrentSurvey()`
 * instead.
 */
const saveSurvey = createAction(SAVE_SURVEY, (id, encryptedSurvey) => ({
  id,
  encryptedSurvey,
}));

/**
 * Creates a thunk that saves the current survey in encrypted form to
 * the state store.
 */
export const saveCurrentSurvey = (survey, reason = 'ok') => (
  dispatch,
  getState
) => {
  const metadataCreator = survey.getMetadataCreator();
  const extraMetadata = metadataCreator({
    responses: getCurrentResponses(getState()),
    data: getCurrentSurveyData(getState()),
  });
  extraMetadata.deviceName = getDeviceName(getState());
  if (reason !== 'ok') {
    extraMetadata.cancellationReason = reason;
  }
  dispatch(finishSurvey(extraMetadata, reason));

  const state = getState();
  const encryptedSurvey = encryptCurrentSurveyData(state);

  const existingIds = getExistingSurveyIds(state);
  const surveyId = pickUnused(() => nanoid(12), existingIds);

  encryptedSurvey.status = SynchronizationStatus.NEW;

  dispatch(saveSurvey(surveyId, encryptedSurvey));
};

/**
 * Creates a thunk that cancels the current survey (by the respondent),
 * and saves the partial result in encrypted form to the state store.
 */
export const cancelCurrentSurveyByRespondent = (survey) =>
  saveCurrentSurvey(survey, 'cancelled');

/**
 * Creates a thunk that cancels the current survey due to inactivity,
 * and saves the partial result in encrypted form to the state store.
 */
export const cancelCurrentSurveyWithTimeout = (survey) =>
  saveCurrentSurvey(survey, 'inactive');

/**
 * Creates an action that sets the current survey mode to automatic or
 * manual.
 */
export const setCurrentSurveyMode = createAction(
  SET_CURRENT_SURVEY_MODE,
  (mode) => ({
    mode,
  })
);

/**
 * Creates an action that starts a new survey.
 *
 * The variant of the survey will be picked to ensure that the different
 * variants are nicely counter-balanced. The picked variant is passed in to
 * the action as an argument to ensure that the reducer remains pure even
 * if randomness is involved in the choice.
 *
 * @param {Survey} survey  the survey that is going to be started
 * @param {string} language  code of the language in which the survey will
 *        be completed
 */
export const startNewSurvey = (survey, language) => (dispatch, getState) => {
  const variant = proposeSurveyVariantToStart(getState())(survey);
  const numPages = survey.getNumPagesInVariant(variant);
  const dataCreator = survey.getDataCreator();
  const data = dataCreator({ language, survey, variant });

  const defaultResponses = survey.getDefaultResponses(language, variant);

  dispatch(
    createAction(START_NEW_SURVEY)({
      data,
      defaultResponses,
      language,
      numPages,
      time: now(),
      variant,
    })
  );
};

/**
 * Submits some responses to the current survey.
 *
 * The first argument of the action must be an object; this object
 * will be merged into the current responses of the current survey,
 * possibly overwriting previous responses.
 */
export const updateResponse = createAction(
  UPDATE_RESPONSE,
  (responses, options) => ({
    responses,
    options,
    time: now(),
  })
);

/**
 * Thunk action that will make the app load the statistics of the
 * surveys from the server and then dispatch appropriate actions.
 */
export const loadSurveyStatistics = () => (dispatch, getState) => {
  const { surveys } = getState();
  const credentials = getAuthenticationCredentials(getState());
  const { username, password } = credentials || {};
  return Api.withCredentials({ username, password }, async () => {
    if (!surveys.stats.loading) {
      dispatch({ type: STARTED_LOADING_SURVEY_STATISTICS });

      try {
        const result = await Api.fetchStatistics();
        dispatch(createAction(FINISHED_LOADING_SURVEY_STATISTICS)(result));
      } catch (error) {
        dispatch(createAction(SURVEY_STATISTICS_LOADING_ERROR)(error));
      }
    }
  });
};
