/**
 * @file Exports a reducer that maintains the part of the state object that
 *       stores the synchronization state.
 */

import get from 'lodash-es/get';
import { handleActions } from 'redux-actions';
import u from 'updeep';

import config from '../../config';

/**
 * Default synchronization state.
 */
const defaultState = {
  automatic: get(config, 'sync.automatic'),
  online: !!navigator.onLine,
};

/**
 * Reducer that handles synchronization-related actions.
 */
const reducer = handleActions(
  {
    DISABLE_AUTOMATIC_SYNC: u({ automatic: false }),
    ENABLE_AUTOMATIC_SYNC: u({ automatic: true }),
    SET_ONLINE_STATE: (state, { payload }) =>
      u({ online: payload.online }, state),
  },
  defaultState
);

export default reducer;
