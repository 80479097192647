import { useCallback, useEffect, useState } from 'react';

import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

/**
 * React hook to force a functional component to re-render itself.
 */
export const useForcedUpdate = () => {
  const [value, set] = useState(true);
  return () => set(!value);
};

/**
 * React hook to force a functional component to re-render itself after a
 * given number of seconds.
 */
export const useAutoRefresh = (interval = 1000) => {
  const update = useForcedUpdate();
  useEffect(() => {
    const handle = setTimeout(update, interval);
    return () => clearTimeout(handle);
  });
};

/**
 * React hook to obtain the number of seconds elapsed since the component
 * was mounted.
 */
export const useSecondsSinceMount = () => {
  // eslint-disable-next-line
  const [firstMount, _] = useState(new Date().getTime());
  return (new Date().getTime() - firstMount) / 1000;
};

/**
 * React hook that behaves similarly to `useState()` but that returns a promise
 * that resolves when the new state has been set and the component has
 * re-rendered.
 */
export const useStateWithPromise = (initialState) => {
  const [state, setState] = useState(initialState);
  const [resolver, setResolver] = useState(null);

  useEffect(() => {
    if (resolver && resolver.funcWrapper) {
      resolver.funcWrapper(state);
      setResolver(null);
    }
  }, [state, resolver]);

  const handleSetState = useCallback(
    (stateAction) => {
      setState(stateAction);
      return new Promise((resolve) => {
        setResolver({ funcWrapper: resolve });
      });
    },
    [setState, setResolver]
  );

  return [state, handleSetState];
};

/**
 * React hook that returns whether the app is being used on an extra-small
 * (narrow) screen.
 */
export const useIsExtraSmallScreen = () => {
  const theme = useTheme();
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.only('xs'));
  return isExtraSmallScreen;
};
