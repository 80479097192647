/**
 * @file This file contains the specification of the survey that will be
 * rendered by the app.
 */

import { Survey } from '../model';
import spec from './spec';

/**
 * The survey instance, created from the survey specification.
 */
export default Survey.createFromSpecification(spec);
