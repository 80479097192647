/**
 * @file Part of the state object that holds the state of the sidebar.
 */

import { handleActions } from 'redux-actions';
import u from 'updeep';

/**
 * Default state of the sidebar.
 */
const defaultState = {
  locked: true,
  open: false,
};

/**
 * Reducer that handles actions related to changing the state of the sidebar.
 */
const reducer = handleActions(
  {
    LOCK_SIDEBAR: (state) => u({ locked: true, open: false }, state),
    SET_SIDEBAR_VISIBILITY: (state, { payload }) =>
      u({ open: payload.visible }, state),
    START_NEW_SURVEY: (state, { payload }) => u({ locked: true }, state),
    UNLOCK_SIDEBAR: (state) => u({ locked: false }, state),
  },
  defaultState
);

export default reducer;
