/**
 * React component that renders the background of the main application.
 */

import React from 'react';
import './Background.css';

const Background = (props) => <div className="Background" />;

export default Background;
