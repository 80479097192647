/**
 * @file Sidebar-related action factories.
 */

import { createAction } from 'redux-actions';

import { LOCK_SCREEN, UNLOCK_SCREEN } from './types';

/**
 * Creates an action that locks the main app screen.
 */
export const lockScreen = createAction(LOCK_SCREEN);

/**
 * Creates an action that unlocks the main app screen.
 */
export const unlockScreen = createAction(UNLOCK_SCREEN);
