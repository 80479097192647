/**
 * @file Exports the application-wide reducer for the application state.
 */

import { combineReducers } from 'redux';

import dialogs from './dialogs';
import onboarding from './onboarding';
import surveys from './surveys';
import sync from './sync';
import ui from './ui';

export default combineReducers({
  dialogs,
  onboarding,
  surveys,
  sync,
  ui,
});
