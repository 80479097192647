import get from 'lodash-es/get';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import config from './config';
import { preparePWA } from './pwa';

import 'typeface-roboto';

import './css/index.css';
import './css/animations.css';
import './css/swipeable.css';

ReactDOM.render(<App />, document.getElementById('root'));

if (get(config, 'allowOfflineUsage', true)) {
  preparePWA();
}
