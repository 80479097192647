/**
 * @file Part of the state object that holds the state related to app
 * updates.
 */

import { handleActions } from 'redux-actions';
import u from 'updeep';

/**
 * Default state of the propertie related to application updates.
 */
const defaultState = {
  available: false,
  cachedForOfflineUse: undefined,
  checking: false,
  downloading: false,
  downloaded: false,
  error: false,
  progress: 0,
};

/**
 * Reducer that handles actions related to changing the state of the sidebar.
 */
const reducer = handleActions(
  {
    NOTIFY_CHECKING_FOR_UPDATES: u({
      checking: true,
      error: false,
      progress: 0,
    }),

    NOTIFY_ERROR_WHILE_UPDATING: u({
      available: false,
      checking: false,
      downloading: false,
      downloaded: false,
      error: true,
      progress: 0,
    }),

    NOTIFY_NOT_CACHED_FOR_OFFLINE_USE: u({
      cachedForOfflineUse: false,
    }),

    NOTIFY_UPDATE_AVAILABLE: u({
      available: true,
      checking: false,
      downloading: false,
      downloaded: false,
      error: false,
      progress: 0,
    }),

    NOTIFY_READY_FOR_OFFLINE_USE: u({
      cachedForOfflineUse: true,
    }),

    NOTIFY_UPDATE_DOWNLOADING: u({
      available: true,
      checking: false,
      downloading: true,
      downloaded: false,
      error: false,
    }),

    NOTIFY_UPDATE_DOWNLOADED: u({
      available: true,
      checking: false,
      downloading: false,
      downloaded: true,
      error: false,
      progress: 0,
    }),

    NOTIFY_UPDATE_INSTALLED: u({
      available: false,
      cachedForOfflineUse: true,
      checking: false,
      downloading: false,
      downloaded: false,
      error: false,
      progress: 0,
    }),

    SET_UPDATE_PROGRESS: (state, { payload }) =>
      u(
        {
          progress: Math.max(0, Math.min(1, payload.progress)),
        },
        state
      ),
  },
  defaultState
);

export default reducer;
