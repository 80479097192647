import MarkdownText from 'markdown-to-jsx';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * React component that renders Markdown content.
 *
 * This component mostly comes from `markdown-to-jsx`,  but it is
 * extended with a `url` prop. When the prop is present, it will
 * fetch the given URL and render the response as Markdown instead
 * of rendering the children as Markdown.
 */
export default class Markdown extends React.Component {
  /**
   * Constructor.
   *
   * @param {object} props  the initial props
   */
  constructor(props) {
    super(props);

    this._promise = undefined;
    this.state = {
      content: undefined,
      error: undefined,
      loading: false,
    };

    this._handleError = this._handleError.bind(this);
    this._handlePromiseResolved = this._handlePromiseResolved.bind(this);
  }

  /**
   * Callback that is called when the component is mounted.
   */
  componentDidMount() {
    this._maybePropsChanged({}, this.props);
  }

  /**
   * Callback that is called when the component receives new props.
   */
  componentDidUpdate(prevProps) {
    this._maybePropsChanged(prevProps, this.props);
  }

  /**
   * Renders the component and returns the rendered representation.
   */
  render() {
    const { children, url, ...rest } = this.props;
    const { loading, content } = this.state;

    if (loading || content !== undefined) {
      return (
        <MarkdownText {...rest}>{loading ? '...' : content || ''}</MarkdownText>
      );
    } else if (children) {
      return <MarkdownText {...rest}>{children}</MarkdownText>;
    } else {
      return null;
    }
  }

  /**
   * Handles an error that happens while loading the URL.
   *
   * @param {object} error  the error that happened
   */
  _handleError(error) {
    this.setState({
      content: undefined,
      error: error,
      loading: false,
    });
  }

  /**
   * Handles the text that the current promise being watched resolved to.
   *
   * @param {string} text  the text that the promise resolved to
   */
  _handlePromiseResolved(text) {
    this.setState({
      content: text,
      error: undefined,
      loading: false,
    });
  }

  _maybePropsChanged(oldProps, newProps) {
    let { promise, url } = newProps;

    if (promise && url) {
      console.warn(
        'promise and url cannot be given at the same time; ignoring url'
      );
      url = undefined;
    }

    if (oldProps.promise !== promise) {
      this._reset();
      this._setPromise(promise);
    } else if (oldProps.url !== url) {
      this._reset();
      if (newProps.url !== undefined) {
        this._setPromise(
          fetch(newProps.url).then((response) => {
            if (response.ok) {
              return response.text();
            } else {
              this._handleError(new Error('response.ok was false'));
            }
          })
        );
      }
    }
  }

  /**
   * Sets the promise that the component is waiting for to be resolved.
   */
  _setPromise(promise) {
    if (this._promise === promise) {
      return;
    }

    this.setState({
      content: undefined,
      error: undefined,
      loading: promise !== undefined,
    });

    if (promise !== undefined) {
      this._promise = promise.then(
        this._handlePromiseResolved,
        this._handleError
      );
    } else {
      this._promise = undefined;
    }
  }

  /**
   * Resets the component to its ground state as if there was no URL
   * passed to it.
   */
  _reset() {
    this._setPromise(undefined);
  }
}

Markdown.propTypes = {
  ...MarkdownText.propTypes,
  url: PropTypes.string,
  children: PropTypes.string,
};
