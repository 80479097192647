import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import PropTypes from 'prop-types';
import React from 'react';

import { ResponseButtonBar } from '../components';

import './MultipleChoicePage.css';

/**
 * React component that shows a generic text on a page and then allows
 * the user to pick multiple responses from a set of pre-defined responses.
 *
 * The children of the component must contain the question itself.
 * Buttons will be rendered at the bottom.
 */
const MultipleChoicePage = ({
  allowOtherResponse,
  children,
  footer,
  getCurrentResponse,
  header,
  headerClassName,
  onSubmit,
  onUpdate,
  otherResponseDialogTitle,
  otherResponseLabel,
  responseKey,
  responses,
  variant,
  vertical,
}) => (
  <React.Fragment>
    {(children || header) && (
      <Card
        raised
        className={clsx({
          MultipleChoicePage: true,
          ['MultipleChoicePage-' + headerClassName]: !!headerClassName,
        })}
      >
        {header && <header className={headerClassName}>{header}</header>}
        <CardContent>{children}</CardContent>
      </Card>
    )}
    {footer}
    <ResponseButtonBar
      allowOtherResponse={allowOtherResponse}
      multiple
      onUpdate={(response) => onUpdate({ [responseKey]: response })}
      onSubmit={(response) => onSubmit({ [responseKey]: response })}
      otherResponseDialogTitle={otherResponseDialogTitle}
      otherResponseLabel={otherResponseLabel}
      responses={responses}
      value={getCurrentResponse(responseKey)}
      variant={variant}
      vertical={vertical}
    />
  </React.Fragment>
);

MultipleChoicePage.propTypes = {
  allowOtherResponse: PropTypes.bool,
  getCurrentResponse: PropTypes.func,
  onSubmit: PropTypes.func,
  onUpdate: PropTypes.func,
  otherResponseDialogTitle: PropTypes.string,
  otherResponseLabel: PropTypes.string,
  responseKey: PropTypes.string,
  responses: PropTypes.array.isRequired,
  variant: PropTypes.oneOf(['dense', 'normal', 'large']),
  vertical: PropTypes.bool,
};

MultipleChoicePage.defaultProps = {
  otherResponseLabel: 'Other',
};

export default MultipleChoicePage;
