/**
 * @file Action factories related to the admin dialog.
 */

import { createAction } from 'redux-actions';

import { promptAndValidateSidebarPassword } from './sidebar';
import {
  CLOSE_ADMIN_DIALOG,
  OPEN_ADMIN_DIALOG,
  SET_ADMIN_DIALOG_SELECTED_TAB,
  SET_API_CREDENTIALS,
} from './types';

/**
 * Action factory that creates an action that closes the admin dialog.
 */
export const closeAdminDialog = createAction(CLOSE_ADMIN_DIALOG);

/**
 * Action factory that creates an action that opens the admin dialog
 * and optionally activates the given tab in the admin dialog.
 */
export const openAdminDialogUnconditionally = createAction(
  OPEN_ADMIN_DIALOG,
  (tab) => ({
    tab,
  })
);

/**
 * Creates an action that opens the admin dialog _after_ prompting the user for
 * the sidebar password.
 */
export const openAdminDialogAfterPasswordPrompt = ({
  keepAsking,
  tab,
} = {}) => async (dispatch) => {
  while (true) {
    const {
      cancelled,
      password,
      passwordValid,
    } = await promptAndValidateSidebarPassword(true, {
      message: 'Please enter your password to access this page',
    });

    if (passwordValid) {
      dispatch(
        setAPICredentialsForAdminDialog({
          username: 'admin',
          password,
        })
      );
      dispatch(openAdminDialogUnconditionally(tab));
      return true;
    } else if (cancelled && !keepAsking) {
      return false;
    }
  }
};

/**
 * Action factory that sets the selected tab in the admin dialog.
 */
export const setAdminDialogSelectedTab = createAction(
  SET_ADMIN_DIALOG_SELECTED_TAB,
  (tab) => ({ tab })
);

/**
 * Action factory that creates an action that sets the API credentials used by
 * the admin dialog.
 */
export const setAPICredentialsForAdminDialog = createAction(
  SET_API_CREDENTIALS
);
