import IconButton from '@material-ui/core/IconButton';
import Lock from '@material-ui/icons/Lock';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { lockScreen } from '../../actions/lock';
import { isScreenLockable } from '../../selectors/lock';

import './LockScreenButton.css';

/**
 * React component that renders a button that can be used to lock the screen
 * when no survey is in progress.
 */
const LockScreenButton = ({ onClick, visible }) =>
  visible ? (
    <div className="LockScreenButton">
      <IconButton onClick={onClick}>
        <Lock style={{ color: 'white' }} />
      </IconButton>
    </div>
  ) : null;

LockScreenButton.propTypes = {
  onClick: PropTypes.func,
  visible: PropTypes.bool,
};

export default connect(
  // mapStateToProps
  (state) => ({
    visible: isScreenLockable(state),
  }),
  // mapDispatchToProps
  (dispatch) => ({
    onClick: () => {
      dispatch(lockScreen());
    },
  })
)(LockScreenButton);
