/**
 * @file This file contains constants for the names of all the actions
 * used in the application. This makes it easy to add new action names
 * and avoid naming conflicts.
 */

// Admin dialog related actions
export const CLOSE_ADMIN_DIALOG = 'CLOSE_ADMIN_DIALOG';
export const OPEN_ADMIN_DIALOG = 'OPEN_ADMIN_DIALOG';
export const SET_ADMIN_DIALOG_SELECTED_TAB = 'SET_ADMIN_DIALOG_SELECTED_TAB';
export const SET_API_CREDENTIALS = 'SET_API_CREDENTIALS';

// Inactivity dialog related actions
export const CLOSE_INACTIVITY_WARNING_DIALOG =
  'CLOSE_INACTIVITY_WARNING_DIALOG';
export const OPEN_INACTIVITY_WARNING_DIALOG = 'OPEN_INACTIVITY_WARNING_DIALOG';

// Lock screen related actions
export const LOCK_SCREEN = 'LOCK_SCREEN';
export const UNLOCK_SCREEN = 'UNLOCK_SCREEN';

// Onboarding-related actions
export const ONBOARING_PASSWORD_ENTERED = 'ONBOARING_PASSWORD_ENTERED';
export const SET_DEVICE_NAME = 'SET_DEVICE_NAME';
export const FINISH_ONBOARDING = 'FINISH_ONBOARDING';

// Sidebar-related actions
export const LOCK_SIDEBAR = 'LOCK_SIDEBAR';
export const SET_SIDEBAR_VISIBILITY = 'SET_SIDEBAR_VISIBILITY';
export const UNLOCK_SIDEBAR = 'UNLOCK_SIDEBAR';

// Survey-related actions
export const FINISH_SURVEY = 'FINISH_SURVEY';
export const MARK_SURVEY_AS_UNSUBMITTED_BY_ID =
  'MARK_SURVEY_AS_UNSUBMITTED_BY_ID';
export const REMOVE_SURVEY_BY_ID = 'REMOVE_SURVEY_BY_ID';
export const REMOVE_SURVEYS_BY_IDS = 'REMOVE_SURVEYS_BY_IDS';
export const RESTART_SURVEY = 'RESTART_SURVEY';
export const SAVE_SURVEY = 'SAVE_SURVEY';
export const SET_CURRENT_SURVEY_MODE = 'SET_CURRENT_SURVEY_MODE';
export const SET_CURRENT_SURVEY_PAGE = 'SET_CURRENT_SURVEY_PAGE';
export const SHOW_BACKMATTER = 'SHOW_BACKMATTER';
export const START_NEW_SURVEY = 'START_NEW_SURVEY';
export const UPDATE_RESPONSE = 'UPDATE_RESPONSE';

// Survey statistics related actions
export const STARTED_LOADING_SURVEY_STATISTICS =
  'STARTED_LOADING_SURVEY_STATISTICS';
export const FINISHED_LOADING_SURVEY_STATISTICS =
  'FINISHED_LOADING_SURVEY_STATISTICS';
export const SURVEY_STATISTICS_LOADING_ERROR =
  'SURVEY_STATISTICS_LOADING_ERROR';

// Synchronization-related actions
export const CANCEL_SYNCHRONIZATION = 'CANCEL_SYNCHRONIZATION';
export const DISABLE_AUTOMATIC_SYNC = 'DISABLE_AUTOMATIC_SYNC';
export const ENABLE_AUTOMATIC_SYNC = 'ENABLE_AUTOMATIC_SYNC';
export const NOTIFY_SURVEY_SUBMISSION_CANCELLED =
  'NOTIFY_SURVEY_SUBMISSION_CANCELLED';
export const NOTIFY_SURVEY_SUBMISSION_ERROR = 'NOTIFY_SURVEY_SUBMISSION_ERROR';
export const NOTIFY_SURVEY_SUBMISSION_STARTED =
  'NOTIFY_SURVEY_SUBMISSION_STARTED';
export const NOTIFY_SURVEY_SUBMISSION_SUCCESSFUL =
  'NOTIFY_SURVEY_SUBMISSION_SUCCESSFUL';
export const REQUEST_SYNCHRONIZATION = 'REQUEST_SYNCHRONIZATION';
export const SET_ONLINE_STATE = 'SET_ONLINE_STATE';
export const SET_SURVEY_SUBMISSION_STATUS = 'SET_SURVEY_SUBMISSION_STATUS';

// Update-related actions
export const NOTIFY_CHECKING_FOR_UPDATES = 'NOTIFY_CHECKING_FOR_UPDATES';
export const NOTIFY_ERROR_WHILE_UPDATING = 'NOTIFY_ERROR_WHILE_UPDATING';
export const NOTIFY_READY_FOR_OFFLINE_USE = 'NOTIFY_READY_FOR_OFFLINE_USE';
export const NOTIFY_UPDATE_AVAILABLE = 'NOTIFY_UPDATE_AVAILABLE';
export const NOTIFY_UPDATE_DOWNLOADING = 'NOTIFY_UPDATE_DOWNLOADING';
export const NOTIFY_UPDATE_DOWNLOADED = 'NOTIFY_UPDATE_DOWNLOADED';
export const NOTIFY_UPDATE_INSTALLED = 'NOTIFY_UPDATE_INSTALLED';
export const REQUEST_CHECK_FOR_UPDATES = 'REQUEST_CHECK_FOR_UPDATES';
export const SET_UPDATE_PROGRESS = 'SET_UPDATE_PROGRESS';
