/**
 * @file Exports a reducer that combines all survey-related reducers.
 */

import { combineReducers } from 'redux';

import current from './current';
import stats from './stats';
import stored from './stored';
import timeout from './timeout';

export default combineReducers({
  current,
  stats,
  stored,
  timeout,
});
