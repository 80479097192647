/**
 * @file Selectors related to survey data.
 */

import fromPairs from 'lodash-es/fromPairs';
import identity from 'lodash-es/identity';
import mapValues from 'lodash-es/mapValues';
import min from 'lodash-es/min';
import property from 'lodash-es/property';
import sortBy from 'lodash-es/sortBy';
import sum from 'lodash-es/sum';
import values from 'lodash-es/values';
import pickRandom from 'pick-random';
import { createSelector } from 'reselect';

import { encryptObject } from '../crypto';
import { SynchronizationStatus } from '../model';
import { obfuscateTimestamp } from '../utils';

/**
 * Helper function that takes a selector and returns another selector
 * that simply returns the number of items selected by the first selector.
 *
 * @param  {function}  selector  the selector to wrap
 * @return {function}  the wrapped selector that counts the items selected
 *         by the first selector
 */
const createCounter = (selector) =>
  createSelector(selector, property('length'));

/**
 * Encryption function that takes a survey object and returns an
 * encrypted representation that can be stored in the state tree.
 */
const encryptSurvey = process.env.REACT_APP_SURVEY_ENCRYPTION_KEY
  ? encryptObject(process.env.REACT_APP_SURVEY_ENCRYPTION_KEY)
  : JSON.stringify;
if (encryptSurvey === JSON.stringify) {
  console.warn(
    'No survey encryption key was defined; survey data will be stored unencrypted.'
  );
}

/**
 * Selector that calculates the data object of the current survey
 * that is to be stored in the part of the state tree that stores
 * the recorded surveys.
 *
 * Also erases the time portion of the start time for greater protection
 * of the respondents.
 */
export const encryptCurrentSurveyData = createSelector(
  (state) => state.surveys.current,
  (survey) => ({
    meta: {
      ...survey.meta,
      startedAt: obfuscateTimestamp(survey.meta.startedAt),
    },
    payload: encryptSurvey({
      data: survey.data,
      log: survey.log,
      responses: survey.responses,
    }),
  })
);

/**
 * Returns the responses of the current survey being recorded.
 */
export const getCurrentResponses = (state) => state.surveys.current.responses;

/**
 * Returns the data object corresponding to the current survey being recorded.
 */
export const getCurrentSurveyData = (state) => state.surveys.current.data;

/**
 * Returns the metadata object corresponding to the current survey being recorded.
 */
export const getCurrentSurveyMetadata = (state) => state.surveys.current.meta;

/**
 * Returns whether the current survey is being recorded in normal or paper-and-pencil
 * mode.
 */
export const getCurrentSurveyMode = (state) => state.surveys.current.meta.mode;

/**
 * Returns the index of the current page of the survey being completed, or -1
 * if we are not in a survey (we are either at the front page or at the
 * backmatter).
 */
export const getCurrentPageIndex = (state) => {
  const { currentPage } = state.surveys.current;
  if (typeof currentPage === 'number') {
    return currentPage;
  } else {
    return -1;
  }
};

/**
 * Returns the language of the current survey being recorded.
 */
export const getCurrentSurveyLanguage = createSelector(
  getCurrentSurveyMetadata,
  (meta) => (meta ? meta.language : undefined)
);

/**
 * Returns the variant ID of the current survey being recorded.
 */
export const getCurrentSurveyVariant = createSelector(
  getCurrentSurveyMetadata,
  (meta) => (meta ? meta.variant : undefined)
);

/**
 * Returns the number of maximum page that was reached during the survey.
 */
export const getMaxPageReached = (state) =>
  state.surveys.current.maxPageReached;

/**
 * Returns whether the page currently being shown is the backmatter of the
 * survey.
 */
export const isShowingBackmatter = (state) => {
  const { currentPage } = state.surveys.current;
  return currentPage === 'backmatter';
};

/**
 * Returns a fractional number representing the progress of the user in the
 * survey, between 0 and 1. The progress will be 1 if the user is on the
 * backmatter page.
 */
export const getSurveyProgress = createSelector(
  getCurrentPageIndex,
  (state) => state.surveys.current.numPages,
  isShowingBackmatter,
  (current, total, isBackmatter) =>
    isBackmatter ? 1.0 : total > 0 && current >= 0 ? current / total : 0.0
);

/**
 * Returns the current page index and the total number of pages such that their
 * ratio represents the progress in the survey. The current page index will
 * never be negative; if the survey has not been started, the current page index
 * will be zero. If the user is at the backmatter page, the current page index
 * will be the number of pages.
 */
export const getSurveyProgressAsFraction = createSelector(
  getCurrentPageIndex,
  (state) => state.surveys.current.numPages,
  isShowingBackmatter,
  (current, total, isBackmatter) => [
    isBackmatter ? total : Math.max(current, 0),
    total,
  ]
);

/**
 * Selector that returns all the survey IDs that are currently in
 * use in the state object.
 */
export const getExistingSurveyIds = createSelector(
  (state) => state.surveys.stored.byId,
  (surveys) => Object.keys(surveys)
);

const filterSurveysByStatus = (statusFunc) =>
  createSelector(
    (state) => state.surveys.stored,
    ({ byId, order }) =>
      sortBy(
        Object.keys(byId).filter((key) => statusFunc(byId[key].status)),
        [(value) => order.indexOf(value), identity]
      )
  );

/**
 * Selector that calculates the list of survey IDs that have already
 * been submitted to the server.
 */
export const getSubmittedSurveyIds = filterSurveysByStatus(
  SynchronizationStatus.isSynchronized
);

/**
 * Selector that calculates the number of surveys that have already
 * been submitted to the server.
 */
export const countSubmittedSurveys = createCounter(getSubmittedSurveyIds);

/**
 * Selector that calculates the list of survey IDs that have not been
 * synchronized to the server yet. This includes surveys that are
 * being synchronized, those that are not synchronized yet but should
 * be synchronized, and those for which we have attempted Synchronization
 * but failed due to a server error.
 */
export const getUnsynchronizedSurveyIds = filterSurveysByStatus(
  SynchronizationStatus.isNotSynchronizedYet
);

/**
 * Selector that calculates the number of surveys that have not been
 * synchronized to the server yet.
 */
export const countUnsynchronizedSurveys = createCounter(
  getUnsynchronizedSurveyIds
);

/**
 * Selector that returns whether there is at least one item that needs
 * to be synchronized.
 */
export const needsSynchronization = createSelector(
  countUnsynchronizedSurveys,
  (count) => count > 0
);

/**
 * Selector that calculates the list of survey IDs where an error has
 * happened while synchronizing.
 */
export const getSurveyIdsWithSynchronizationErrors = filterSurveysByStatus(
  SynchronizationStatus.isError
);

/**
 * Selector that calculates the number of surveys where an error has
 * happened while synchronizing.
 */
export const countSurveysWithSynchronizationErrors = createCounter(
  getSurveyIdsWithSynchronizationErrors
);

/**
 * Selector that returns the IDs of the surveys that are currently
 * being synchronized.
 */
export const getSurveyIdsBeingSynced = filterSurveysByStatus(
  SynchronizationStatus.isBeingSynchronized
);

/**
 * Selector that returns the number of surveys that are currently
 * being synchronized.
 */
export const countSurveysBeingSynced = createCounter(getSurveyIdsBeingSynced);

/**
 * Selector that returns whether a survey is currently being completed.
 */
export const isSurveyInProgress = createSelector(
  getCurrentPageIndex,
  (index) => index >= 0
);

/**
 * Selector that returns whether synchronization is currently in progress
 * for at least one survey item.
 */
export const isSyncInProgress = createSelector(
  countSurveysBeingSynced,
  (count) => count > 0
);

/**
 * Selector that returns the IDs of the surveys that need to be synchronized
 * to the server.
 */
export const getSurveyIdsThatNeedToBeSynced = filterSurveysByStatus(
  SynchronizationStatus.needsToBeSynchronized
);

/**
 * Selector that returns the ID of the next survey to be synchronized
 * with the server or <code>undefined</code> if there is no such survey.
 */
export const getNextSurveyIdToSync = createSelector(
  getSurveyIdsThatNeedToBeSynced,
  (surveyIds) => (surveyIds.length ? min(surveyIds) : undefined)
);

/**
 * Selector _factory_ that receives a list of excluded survey IDs and
 * then returns a selector that will return the ID of the next survey
 * to be synchronized with the server, excluding the ones given in the
 * exclusion list, or <code>undefined</code> if there is no such survey.
 * I know, this is very meta. :)
 *
 * @param {string[]} excludedIds  the IDs of the surveys to exclude
 * @return {function} an appropriately configured selector that will
 *         return the ID of the next survey to sync given the state
 *         object
 */
export const getNextSurveyIdToSyncExcluding = (excludedIds) =>
  createSelector(getSurveyIdsThatNeedToBeSynced, (surveyIds) => {
    const filteredSurveyIds = surveyIds.filter(
      (surveyId) => excludedIds.indexOf(surveyId) < 0
    );
    return filteredSurveyIds.length ? min(filteredSurveyIds) : undefined;
  });

/**
 * Creates a selector that returns a survey given its ID.
 *
 * @param {string} surveyId  the ID of the survey to fetch.
 * @return {function} an appropriately configured selector that will
 *         return the survey with the given ID when invoked with the
 *         state object
 */
export const getSurveyById = (surveyId) =>
  createSelector(
    (state) => state.surveys.stored.byId,
    (surveys) => surveys[surveyId]
  );

/**
 * A selector that returns a _function_ that will count the occurrences of
 * various survey variants when invoked with a survey specification.
 */
export const getOccurrenceCounter = createSelector(
  (state) => state.surveys.stored.byId,
  (surveyMap) => (survey) => {
    const variants = survey.getVariants();
    const counts = fromPairs(variants.map((variant) => [variant, 0]));
    for (const surveyId in surveyMap) {
      const data = surveyMap[surveyId];
      const { meta } = data || {};
      if (meta) {
        const consent = meta.hasOwnProperty('consent') ? meta.consent : true;
        if (counts.hasOwnProperty(meta.variant) && consent) {
          counts[meta.variant] += 1;
        }
      }
    }
    return counts;
  }
);

/**
 * A selector that returns a _function_ that will propose a survey variant
 * to start when invoked with a survey specification.
 */
export const proposeSurveyVariantToStart = createSelector(
  getOccurrenceCounter,
  (counter) => (survey) => {
    const counts = counter(survey);
    const variants = Object.keys(counts);
    const total = sum(values(counts));
    const deviation = mapValues(counts, (value, key) =>
      Number(
        // deviations are rounded to two decimal digits to smooth off numerical
        // roundoff errors
        (value - total * survey.getProbabilityOfVariant(key)).toFixed(2)
      )
    );
    const largestNegativeDeviation = min(
      variants.map((variant) => deviation[variant])
    );
    const candidates = variants.filter(
      (variant) => deviation[variant] === largestNegativeDeviation
    );
    return pickRandom(candidates)[0];
  }
);
