/**
 * @file Part of the state object that holds the state of the inactivity
 * warning dialog.
 */

import { handleActions } from 'redux-actions';
import u from 'updeep';

/**
 * Default state of the inactivity warning dialog.
 */
const defaultState = {
  open: false,
  shownAt: undefined,
};

/**
 * Reducer that handles actions related to changing the state of the
 * inactivity warning dialog.
 */
const reducer = handleActions(
  {
    CLOSE_INACTIVITY_WARNING_DIALOG: (state) => u({ open: false }, state),

    OPEN_INACTIVITY_WARNING_DIALOG: (state, { payload }) =>
      u(
        {
          open: true,
          shownAt: payload.shownAt,
        },
        state
      ),
  },
  defaultState
);

export default reducer;
