/* eslint import/no-webpack-loader-syntax: off */

import Box from '@material-ui/core/Box';

import dotProp from 'dot-prop';
import pick from 'lodash-es/pick';
import React from 'react';

import { LinkInNewTab, Markdown, NumberList, QRCode } from '../components';
import { confirm } from '../components/dialogs';
import {
  CWMPage,
  GenericTextPage,
  IntroductionPage,
  SelectCountryPage,
  SelectSportFromADAMSPage,
  SingleChoicePage,
  TextFieldPage,
  YesNoChoicePage,
} from '../pages';
import {
  fetchText,
  generateCompletionCode,
  sanitizeUrl,
  toIndexedChoices,
} from '../utils';
import { maybeContainsEmailAddress } from '../utils/patterns';

import { fetchTemplateAndFormat, pickNumbersForCWM } from './cwm-numbers';

const translations = {
  en: require('./en').default,
  fr: require('./fr').default,
  // es: require('./es').default,
};

/**
 * List of sports to show on the "Select sport" page.
 */
/*
const sports = reduce(
  Object.keys(translations),
  (acc, language) => ({ ...acc, [language]: createSportList(language) }),
  {}
);
*/

const { pathname } = window.location;
const _isUserInRegisteredTestingPoolBasedOnUrl =
  pathname.toLowerCase() === '/start';

/**
 * Helper function that returns whether the user is in the registered testing
 * pool, based on the URL that the user used to start the survey.
 */
const isUserInRegisteredTestingPoolBasedOnUrl = () => {
  return _isUserInRegisteredTestingPoolBasedOnUrl;
};

/**
 * Creates a localized version of the survey specification, given the
 * language code.
 */
const createSurveySpecification = (language) => {
  const _b = (bundleName) =>
    (translations[language]
      ? dotProp.get(translations[language], bundleName)
      : undefined) || {};
  const _t = (what, def) =>
    dotProp.get(_b('messages'), what) || (def === undefined ? `%${what}` : def);

  /**
   * Helper function for creating a basic response page with title and subtitle
   * only.
   */
  const createBasicPage = (responseKey, component) => ({
    title: _t(`pages.${responseKey}.title`, null),
    subtitle: _t(`pages.${responseKey}.subtitle`, null),
    component:
      typeof component === 'function' ? component(responseKey) : component,
  });

  /**
   * Helper function for creating a single-choice response page specification
   * as we have many of these and they all follow roughly the same format.
   */
  const createSingleChoicePage = (responseKey, options = {}) => {
    const { allowOther, indexed, ...props } = options;
    const header = _t(`pages.${responseKey}.header`, null);
    const text = _t(`pages.${responseKey}.text`, null);
    const responses = indexed
      ? toIndexedChoices(_t(`pages.${responseKey}.responses`, []))
      : _t(`pages.${responseKey}.responses`, []);
    return createBasicPage(
      responseKey,
      <SingleChoicePage
        responseKey={responseKey}
        responses={responses}
        allowOtherResponse={allowOther}
        otherResponseDialogTitle={_t(`pages.${responseKey}.title`)}
        otherResponseLabel={_t(`pages.${responseKey}.other`)}
        vertical
        header={header}
        {...props}
      >
        {text}
      </SingleChoicePage>
    );
  };

  /**
   * Helper function for creating a multi-response page specification as we have
   * many of these and they all follow roughly the same format.
   */
  /*
  const createMultipleChoicePage = (responseKey, options = {}) => {
    const { allowEmpty, allowOther, ...props } = options;
    const result = {
      ...createBasicPage(responseKey),
      component: (
        <MultipleChoicePage
          responseKey={responseKey}
          responses={_t(`pages.${responseKey}.responses`, [])}
          allowOtherResponse={allowOther}
          otherResponseDialogTitle={_t(`pages.${responseKey}.title`)}
          otherResponseLabel={_t(`pages.${responseKey}.other`)}
          vertical
          {...props}
        />
      ),
      defaults: {
        [responseKey]: [],
      },
      manualSubmission: true,
    };

    if (!allowEmpty && !result.validator) {
      result.validator = atLeastOneResponseFor(responseKey);
    }

    return result;
  };
  */

  /**
   * Helper function for creating a yes-no question page specification as we have
   * many of these and they all follow roughly the same format.
   */
  const createYesNoChoicePage = (responseKey, props) => ({
    ...createBasicPage(responseKey),
    component: <YesNoChoicePage responseKey={responseKey} {...props} />,
  });

  /**
   * Helper function for creating a number picker page to be placed in front of
   * the corresponding CWM question page.
   */
  const createCWMNumberPickerPage = (responseKey, props) => ({
    ...createBasicPage('pickNumber'),
    component: ({ data: { numbers } }) => (
      <GenericTextPage submitText={_t('nextPage')} {...props}>
        <NumberList>
          {numbers && numbers[responseKey]
            ? numbers[responseKey].initialSet
            : undefined}
        </NumberList>
      </GenericTextPage>
    ),
  });

  /**
   * Helper function for creating a CWM question page specification as we have
   * many of these and they all follow roughly the same format.
   */
  const createCWMPage = (responseKey, props) => ({
    ...createBasicPage(responseKey),
    title: _t('cwmPageTitle'),
    component: ({ data: { numbers } }) => (
      <CWMPage responseKey={responseKey} {...props}>
        <Markdown
          promise={fetchTemplateAndFormat(_b('cwmPages')[responseKey], {
            numbers:
              numbers && numbers[responseKey]
                ? numbers[responseKey].dilutedSet
                : undefined,
          })}
        />
      </CWMPage>
    ),
  });

  /**
   * Creates a validator function that checks whether the given key (which is
   * supposed to belong to a multi-response question) has at least one response.
   */
  /*
  const atLeastOneResponseFor = (key) => (responses) => {
    const response = responses[key];
    return response && response.length > 0;
  };
  */

  // The pages of the survey
  const pages = {
    intro: {
      title: _t('introTitle'),
      subtitle: _t('introSubtitle'),
      component: (
        <IntroductionPage
          submitText={_t('startSurvey')}
          cancelText={_t('cancelSurvey')}
          responseKey="consent"
        >
          <Markdown promise={fetchText(_b('introPage'))} />
          <Box display="flex" justifyContent="center">
            <QRCode
              size={192}
              value={sanitizeUrl(_t('urlForMoreInformation'))}
            />
          </Box>
        </IntroductionPage>
      ),
    },

    age: createSingleChoicePage('age'),
    sex: createSingleChoicePage('sex'),
    competitionLevel: createSingleChoicePage('competitionLevel'),
    sport: {
      ...createBasicPage('sport'),
      component: <SelectSportFromADAMSPage responseKey="sport" />,
    },
    paraSport: createYesNoChoicePage('paraSport'),
    country: {
      ...createBasicPage('country'),
      component: <SelectCountryPage responseKey="country" />,
    },
    registeredTestingPool: createYesNoChoicePage('registeredTestingPool'),
    hadEducation: createYesNoChoicePage('hadEducation'),

    /*
    educationOrganization: {
      ...createMultipleChoicePage('educationOrganization', {
        allowOther: true,
      }),
      visible: (responses) => hadEducation(responses),
    },

    informationSources: {
      ...createMultipleChoicePage('informationSources', { allowOther: true }),
    },
    */

    cwmInstructions: createBasicPage(
      'cwmInstructions',
      <GenericTextPage submitText={_t('nextPage')}>
        <Markdown promise={fetchText(_b('cwmPages.instructions'))} />
      </GenericTextPage>
    ),

    pickNumberForCWMUrineOrBloodSample: createCWMNumberPickerPage(
      'cwmUrineOrBloodSample'
    ),
    cwmUrineOrBloodSample: createCWMPage('cwmUrineOrBloodSample'),

    pickNumberForCWMSupplements: createCWMNumberPickerPage('cwmSupplements'),
    cwmSupplements: createCWMPage('cwmSupplements'),

    pickNumberForCWMMedicine: createCWMNumberPickerPage('cwmMedicine'),
    cwmMedicine: createCWMPage('cwmMedicine'),

    pickNumberForCWMDoping: createCWMNumberPickerPage('cwmDoping'),
    cwmDoping: createCWMPage('cwmDoping'),

    pickNumberForCWMLicense: createCWMNumberPickerPage('cwmLicense'),
    cwmLicense: createCWMPage('cwmLicense'),

    comments: {
      ...createBasicPage('comments'),
      component: (
        <TextFieldPage
          instructions={
            <Markdown
              options={{ forceBlock: true, overrides: { a: LinkInNewTab } }}
            >
              {_t('pages.comments.instructions')}
            </Markdown>
          }
          responseKey="comments"
          fullWidth
          multiline
          minRows={10}
        />
      ),
      defaults: {
        comments: [],
      },
      manualSubmission: true,
      softValidator: async (responses) => {
        const { comments } = responses;
        if (
          typeof comments === 'string' &&
          maybeContainsEmailAddress(comments)
        ) {
          return await confirm('', _t('pages.comments.confirmPersonalData'), {
            noLabel: _t('pages.comments.no'),
            yesLabel: _t('pages.comments.yes'),
          });
        } else {
          return true;
        }
      },
    },
  };

  // The backmatter of the survey
  const backmatter = {
    title: _t('backmatter.title'),
    subtitle: _t('backmatter.subtitle', ''),
    component: ({ data: { completionCode } }) => (
      <GenericTextPage preventSubmission>
        <Markdown
          options={{ forceBlock: true, overrides: { a: LinkInNewTab } }}
        >
          {_t('backmatter.text').replace('@COMPLETION_CODE@', completionCode)}
        </Markdown>
        {/* <QRCode size={256} value={sanitizeUrl(_t('urlForMoreInformation'))} /> */}
      </GenericTextPage>
    ),
  };

  return { pages, backmatter };
};

/**
 * The supported languages.
 */
const languages = [
  {
    id: 'en',
    nativeName: 'English',
  },
  {
    id: 'fr',
    nativeName: 'Français',
    englishName: 'French',
  },
  /*
  {
    id: 'es',
    nativeName: 'Español',
    englishName: 'Spanish',
  },
  */
];

// Create the language-specific parts of the survey specification
const pages = {};
const backmatter = {};
for (const { id } of languages) {
  const spec = createSurveySpecification(id);
  pages[id] = spec.pages;
  backmatter[id] = spec.backmatter;
}

/**
 * The specification of the survey pages in a simple, human-readable format.
 */
export default {
  // The title of the survey
  title: 'Welcome',

  // The subtitle of the survey
  subtitle: 'Prevalence Survey',

  // The supported languages
  languages,

  // Hook function called before the survey to provide random data for some
  // of the pages
  data: ({ variant }) => ({
    // isInRegisteredTestingPool: isUserInRegisteredTestingPoolBasedOnUrl(),
    numbers: pickNumbersForCWM({
      count: 5,
      keep: {
        cwmUrineOrBloodSample: variant === 'cwmA' ? 1 : 4,
        cwmSupplements: variant === 'cwmA' ? 4 : 1,
        cwmMedicine: variant === 'cwmA' ? 1 : 4,
        cwmDoping: variant === 'cwmA' ? 4 : 1,
        cwmLicense: variant === 'cwmA' ? 0 : 5,
      },
    }),
    completionCode: generateCompletionCode([
      {
        type: 'choice',
        items: ['clean', 'fair', 'honest', 'true'],
      },
      {
        type: 'number',
        range: [1000, 10000],
        divisibleBy: 3,
      },
      {
        type: 'choice',
        items: ['sport', 'play', 'competition', 'game'],
      },
      {
        type: 'number',
        range: [100, 1000],
        divisibleBy: 3,
      },
    ]),
  }),

  // The pages of the survey
  pages,

  // Optional page that appears on the survey after the user has submitted
  // the results and before the survey returned to the main page. Typically a
  // "thank you" page.
  backmatter,

  // The different variants of the survey, for counter-balancing purposes
  variants: {
    cwmA: {
      items: [
        'intro',

        'age',
        'sex',
        // 'competitionLevel',
        'registeredTestingPool',
        'sport',
        'paraSport',
        'country',

        'cwmInstructions',

        'pickNumberForCWMUrineOrBloodSample',
        'cwmUrineOrBloodSample',

        'pickNumberForCWMSupplements',
        'cwmSupplements',

        'pickNumberForCWMMedicine',
        'cwmMedicine',

        'pickNumberForCWMDoping',
        'cwmDoping',

        'pickNumberForCWMLicense',
        'cwmLicense',

        'hadEducation',
        'comments',
      ],
    },

    cwmB: {
      items: [
        'intro',

        'age',
        'sex',
        // 'competitionLevel',
        'registeredTestingPool',
        'sport',
        'paraSport',
        'country',

        'cwmInstructions',

        'pickNumberForCWMUrineOrBloodSample',
        'cwmUrineOrBloodSample',

        'pickNumberForCWMSupplements',
        'cwmSupplements',

        'pickNumberForCWMMedicine',
        'cwmMedicine',

        'pickNumberForCWMDoping',
        'cwmDoping',

        'pickNumberForCWMLicense',
        'cwmLicense',

        'hadEducation',
        'comments',
      ],
    },
  },

  // The function that generates the metadata of the survey after
  // it is completed
  meta: ({ data, responses }) => {
    const result = pick(responses, [
      'language',
      'sport',
      'paraSport',
      'country',
      // 'competitionLevel',
      'registeredTestingPool',
    ]);
    result['partOfDay'] = new Date().getHours() < 12 ? 'morning' : 'afternoon';
    return result;
  },
};
