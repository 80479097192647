import Modal from '@material-ui/core/Modal';
import LockOpen from '@material-ui/icons/LockOpen';
import PropTypes from 'prop-types';
import React, { forwardRef, useState } from 'react';
import Draggable from 'react-draggable';
import { connect } from 'react-redux';
import { useMountedState } from 'react-use';
import url from 'url';

import { unlockScreen } from '../actions/lock';
import { isScreenLocked } from '../selectors/lock';

import './SlideToUnlock.css';

const webRoot = process.env.PUBLIC_URL;

/**
 * React component that renders a slide-to-unlock slider.
 */
const Slider = forwardRef((props, ref) => {
  const { buttonWidth, onSlideToEnd, width } = props;
  const isMounted = useMountedState();
  const [sliderPosition, setSliderPosition] = useState(0);

  const onDrag = (event, data) => {
    setSliderPosition(data.x);
    return true;
  };

  const onStop = (event, data) => {
    if (data.x > (width - buttonWidth) * 0.6) {
      setSliderPosition(width - buttonWidth);
      setTimeout(() => {
        onSlideToEnd();
        setTimeout(() => {
          if (isMounted()) {
            setSliderPosition(0);
          }
        }, 1000);
      }, 300);
    } else {
      setSliderPosition(0);
    }
  };

  return (
    <div className="SlideToUnlock" tabIndex={-1} ref={ref} style={{ width }}>
      <h2>
        <div className="SlideToUnlockLabel">
          <LockOpen style={{ height: 72, width: 72 }} />
        </div>
        <Draggable
          axis="x"
          bounds="parent"
          onDrag={onDrag}
          onStop={onStop}
          position={{ x: sliderPosition, y: 0 }}
        >
          <img
            alt=""
            className="SlideToUnlockButton"
            src={url.resolve(webRoot, 'img/buttons/slide-to-unlock.png')}
          />
        </Draggable>
      </h2>
    </div>
  );
});

Slider.propTypes = {
  buttonWidth: PropTypes.number,
  onSlideToEnd: PropTypes.func,
  width: PropTypes.number,
};

Slider.defaultProps = {};

/**
 * React component that shows a slide-to-unlock slider on top of the
 * entire screen.
 */
const SlideToUnlock = ({ onSlideToEnd, visible }) => (
  <Modal open={visible}>
    <Slider onSlideToEnd={onSlideToEnd} buttonWidth={186} width={680} />
  </Modal>
);

SlideToUnlock.propTypes = {
  onSlideToEnd: PropTypes.func,
  visible: PropTypes.bool,
};

export default connect(
  // mapStateToProps
  (state) => ({
    visible: isScreenLocked(state),
  }),
  // mapDispatchToProps
  (dispatch) => ({
    onSlideToEnd: () => dispatch(unlockScreen()),
  })
)(SlideToUnlock);
