/**
 * @file Part of the state object that handles the timeout of the survey.
 * When the user does not interact with the survey for a given amount of time,
 * the survey will automatically cancel itself.
 */

import { handleActions } from 'redux-actions';
import u from 'updeep';

import { now } from '../../utils';

/**
 * Default state of the timeout mechanism of the survey.
 */
const defaultState = {
  deadline: undefined,
};

/**
 * Function that returns the deadline for the next user interaction as a
 * UNIX timestamp, assuming that the last interaction happened now.
 *
 * @return {number}  the current time plus one minute, as a UNIX timestamp
 */
function getNextDeadline() {
  return now() + 5 * 1000;
}

/**
 * Function that can be invoked on the state object to clear the deadline.
 *
 * @param {object} state  the slice of the current state object related to
 *        the timeout reducer
 * @return {object} the new state
 */
function clearDeadline(state) {
  return u({ deadline: undefined }, state);
}

/**
 * Function that can be invoked on the state object to update the deadline
 * to the current timestamp plus one minute.
 *
 * @param {object} state  the slice of the current state object related to
 *        the timeout reducer
 * @return {object} the new state
 */
function updateDeadline(state) {
  return u({ deadline: getNextDeadline() }, state);
}

/**
 * Reducer that handles actions related to loading the statistics of the
 * submitted surveys from the server.
 */
const reducer = handleActions(
  {
    FINISH_SURVEY: clearDeadline,
    RESTART_SURVEY: clearDeadline,
    SET_CURRENT_SURVEY_PAGE: updateDeadline,
    START_NEW_SURVEY: updateDeadline,
    UPDATE_RESPONSE: updateDeadline,
  },
  defaultState
);

export default reducer;
