import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import PropTypes from 'prop-types';
import React from 'react';

import './ResponseButton.css';

/**
 * React component that renders a button that submits a response on
 * behalf of the user. It is specifically styled to make the button
 * easy to hit.
 */
export const ResponseButton = ({
  children,
  fullHeight,
  onClick,
  selected,
  variant,
  ...rest
}) => (
  <Card raised className={clsx('ResponseButtonCard', { fullHeight })}>
    <Button
      className={clsx({
        ResponseButton: true,
        dense: variant === 'dense',
        large: variant === 'large',
        selected,
      })}
      fullWidth
      onClick={onClick}
      {...rest}
    >
      {children}
    </Button>
  </Card>
);

ResponseButton.propTypes = {
  fullHeight: PropTypes.bool,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  variant: PropTypes.oneOf(['dense', 'normal', 'large']),
};

ResponseButton.defaultProps = {
  variant: 'normal',
};

export default ResponseButton;
