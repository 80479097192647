/**
 * @file Saga that automatically locks the sidebar after a given number
 * of seconds when the sidebar is not open.
 */

import { call, delay, put, race } from 'redux-saga/effects';

import { closeAndLockSidebar } from '../actions/sidebar';
import { isSidebarClosedButUnlocked } from '../selectors/sidebar';
import { waitFor, waitWhile } from './helpers';

/**
 * Saga that locks the sidebar automatically when it is closed but stays
 * unlocked for more than the given number of seconds.
 *
 * @param  {Number}  seconds  the maximum number of seconds the sidebar
 *         is allowed to stay unlocked (but closed) for.
 */
export default function* lockSidebarSaga(interval = 30) {
  while (true) {
    yield call(waitFor, isSidebarClosedButUnlocked);

    const result = yield race({
      notDangerousAnymore: call(waitWhile, isSidebarClosedButUnlocked),
      timeout: delay(interval * 1000),
    });

    if (result.timeout) {
      yield put(closeAndLockSidebar());
    }
  }
}
