/**
 * @file Part of the state object that holds the state of the lock screen.
 */

import { handleActions } from 'redux-actions';
import u from 'updeep';

/**
 * Default state of the lock screen.
 */
const defaultState = {
  locked: false,
};

/**
 * Reducer that handles actions related to changing the state of the sidebar.
 */
const reducer = handleActions(
  {
    LOCK_SCREEN: (state) => u({ locked: true }, state),
    UNLOCK_SCREEN: (state) => u({ locked: false }, state),
  },
  defaultState
);

export default reducer;
