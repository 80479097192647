/**
 * @file Implementation of functions that allow us to communicate
 * with the server storing the submitted surveys.
 */

const fetch = require('node-fetch');
const { Headers } = fetch;

class Api {
  /**
   * Constructor. Creates a new API accessor object that will talk to
   * the API at the given URL.
   *
   * @param {string} options.rootUrl  the root URL of the API.
   * @param {object} options.headers  extra headers to pass with each API
   *        request. The object must map header names to values.
   */
  constructor({ rootUrl, headers }) {
    const { Authorization, ...rest } = headers;

    this._rootUrl = rootUrl;
    this._headers = rest || {};
  }

  /**
   * Tells the API object the authentication credentials needed to access the
   * sensitive operations.
   */
  authenticate = ({ username, password } = {}) => {
    if (typeof username === 'string' && typeof password === 'string') {
      this._username = username;
      this._password = password;
    } else {
      delete this._username;
      delete this._password;
    }
  };

  deauthenticate = () => {
    return this.authenticate({});
  };

  /**
   * Returns a promise that will resolve to a readable stream containing
   * the backup of all the surveys on the server.
   *
   * @return {Promise<ReadableStream>} a promise that resolves to a
   *         readable stream containing the backup of all the surveys on the server
   */
  downloadArchive = async () => {
    const response = await this._fetch('/archive', { authenticate: true });
    if (response.ok) {
      return response.body;
    } else {
      throw new Error('Request returned status ' + response.status);
    }
  };

  /**
   * Returns a promise that will resolve to the general survey statistics
   * from the server.
   *
   * @return {Promise<object>} an object that contains the survey
   *         statistics as returned by the server
   */
  fetchStatistics() {
    return this._fetch('/stats', { authenticate: true }).then(
      this._responseToJSON
    );
  }

  /**
   * Returns the root URL of the API.
   *
   * @type {string}
   */
  get rootUrl() {
    return this._rootUrl;
  }

  /**
   * Sets the root URL of the API.
   */
  set rootUrl(value) {
    this._rootUrl = value;
  }

  /**
   * Submits a survey to the server.
   *
   * @param  {object}  survey  The survey to submit to the server. The
   *         object must have the following keys: `id` to store the ID
   *         of the survey, `meta` to store the survey metadata, and
   *         `payload` to store the encrypted responses of the survey.
   */
  submit = (survey) => {
    // Submissions don't require a password
    return this._fetch('/surveys', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(this._sanitizeSurvey(survey)),
    }).then(this._responseToJSON);
  };

  /**
   * Executes the given function in a context where the API is provided the
   * given authentication credentials. Clears the credentials when the function
   * terminates.
   */
  withCredentials = (credentials, func) => {
    this.authenticate(credentials);
    try {
      return func();
    } finally {
      this.deauthenticate();
    }
  };

  /**
   * Creates the authentication headers for a new request.
   */
  _createAuthHeaders() {
    if (this._hasAuthCredentials()) {
      return {
        Authorization: 'Basic ' + btoa(`${this._username}:${this._password}`),
      };
    } else {
      return {};
    }
  }

  /**
   * Returns whether the API object already possesses the authentication
   * credentials.
   */
  _hasAuthCredentials() {
    return (
      this._username &&
      typeof this._username === 'string' &&
      this._password &&
      typeof this._password === 'string'
    );
  }

  /**
   * Checks the response status of the API request and returns a promise
   * that resolves to an ArrayBuffer containing the response body if the
   * request returned a valid response.
   *
   * @param {Response} response  the response of an API request
   * @return {ArrayBuffer} the response body
   * @throws {Error} if the request failed
   */
  _responseToArrayBuffer(response) {
    if (response.ok) {
      return response.arrayBuffer();
    } else {
      throw new Error('Request returned status ' + response.status);
    }
  }

  /**
   * Checks the response status of the API request and returns a promise
   * that resolves to the JSON response if the request returned a valid
   * response.
   *
   * @param {Response} response  the response of an API request
   * @return {object} the JSON response body
   * @throws {Error} if the request failed
   */
  _responseToJSON(response) {
    if (response.ok) {
      return response.json();
    } else {
      return response.json().then(
        (body) => {
          throw new Error(body.message);
        },
        (error) => {
          throw new Error('Request returned status ' + response.status);
        }
      );
    }
  }

  /**
   * Fetches a response to an API request.
   *
   * This function has the same interface as the `fetch()` function of
   * the standard Fetch API, but the first argument is always a relative
   * path that is resolved relative to the root URL of the API.
   *
   * Error handling is taken care of by this function; all errors are
   * logged to the console and then re-thrown.
   *
   * When the response arrives, the JSON object from the body is
   * extracted and the promise returned from this function will resolve
   * to the JSON response object.
   *
   * @param {string} url  URL of the resource to fetch, relative to the
   *        root of the API
   * @param {object} opts further options to pass to the underlying
   *        `fetch()` call.
   * @return {Promise<Response>} a promise that will resolve to the
   *         response object of the request
   */
  _fetch = (url, { authenticate, headers, ...rest } = {}) => {
    if (authenticate && !this._hasAuthCredentials()) {
      throw new Error('This request requires authentication');
    }

    return fetch(this._rootUrl + url, {
      redirect: 'follow',
      headers: new Headers({
        ...this._headers,
        ...(authenticate ? this._createAuthHeaders() : {}),
        ...headers,
      }),
      ...rest,
    });
  };

  /**
   * Sanitizes the survey objects being submitted to the server.
   *
   * @param {object} survey  the survey to sanitize
   * @return {object}  the sanitized survey; a copy of the input object
   */
  _sanitizeSurvey(survey) {
    return {
      id: survey.id,
      payload: survey.payload,
      meta: survey.meta,
    };
  }
}

export default new Api({
  rootUrl:
    process.env.REACT_APP_SERVER_API_URL ||
    (process.env.NODE_ENV === 'production' ? '/api' : ''),
  headers: {},
});
