import get from 'lodash-es/get';

import { isOnboardingComplete } from './onboarding';
import { isSurveyInProgress } from './surveys';

import config from '../config';

/**
 * Returns whether the screen lock is enabled.
 */
export const isScreenLockEnabled = () => get(config, 'lock.enabled');

/**
 * Returns whether the screen is locked.
 */
export const isScreenLocked = (state) => state.ui.lock.locked;

/**
 * Returns whether it is safe to lock the screen now.
 *
 * The screen can be locked if the screen lock is not disabled entirely,
 * we have completed the onboarding process and no survey is in progress.
 */
export const isScreenLockable = (state) =>
  !isScreenLocked(state) &&
  isScreenLockEnabled(state) &&
  isOnboardingComplete(state) &&
  !isSurveyInProgress(state);
