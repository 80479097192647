/**
 * @file Localization-related helper functions.
 */

import mapValues from 'lodash-es/mapValues';

/**
 * Returns the localized string corresponding to the given key and
 * language from a localization bundle.
 *
 * Localization bundles are nested objects where the top-level keys
 * are language codes that are mapped to other objects holding a
 * mapping from keys to localized strings.
 *
 * The function will retrieve the localized string corresponding to
 * the given key in the given language; if such a key does not exist,
 * the function falls back to the given fallback locale instead.
 * When the fallback locale does not exist in the bundle either,
 * the function will also try the special key named ``__default``
 * before bailing out and returning the input key itself.
 *
 * Multiple lookups are supported when passing an array or an object
 * in place of the key. When the key is an array, each item in the
 * array will be attempted to be localized individually (recursively)
 * and the function will return the localized array. When the key is an
 * object, another object will be returned where the keys are identical to
 * the original object and the corresponding values are localized
 * recursively.
 *
 * @param {object} bundle    the localization bundle
 * @param {string} language  the language to look up
 * @param {string|string[]|object} key       the key to look up in the
 *        bundle in the appropriate part corresponding to the given language,
 *        or an array of keys, or an object where the values are the keys
 *        to be localized. See above for more details.
 * @param {string} fallback  the language to fall back to if the given
 *        language does not exist in the bundle or the language does
 *        not have the given key.
 * @return {string|undefined} the localized string or the key itself if
 *         the key cannot be localized
 */
export function localize(bundle, language, key, fallback = 'en') {
  if (key === undefined && arguments.length === 2) {
    // curried function
    return (newKey) => localize(bundle, language, newKey);
  }

  if (!bundle) {
    return key;
  }

  if (Array.isArray(key)) {
    return key.map((item) => localize(bundle, language, item, fallback));
  } else if (typeof key === 'object') {
    return mapValues(key, (value) =>
      localize(bundle, language, value, fallback)
    );
  }

  const bundlePart = bundle[language];
  const value = bundlePart ? bundlePart[key] : undefined;

  if (value === undefined) {
    const fallbackBundlePart = bundle[fallback];
    const fallbackValue = fallbackBundlePart
      ? fallbackBundlePart[key]
      : undefined;

    if (fallbackValue === undefined) {
      const defaultBundlePart = bundle['__default'];
      const defaultValue = defaultBundlePart ? defaultBundlePart[key] : key;
      return defaultValue;
    } else {
      return fallbackValue;
    }
  } else {
    return value;
  }
}
