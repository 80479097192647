import loadable from '@loadable/component';
import { ThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import { Provider as StoreProvider } from 'react-redux';
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
} from 'react-router-dom';

import './App.css';

import store from './store';
import theme from './theme';

const AdminPageRoute = loadable(() => import('./routes/AdminPageRoute'));

const MainSurveyRoute = loadable(() => import('./routes/MainSurveyRoute'));

const App = () => (
  <ThemeProvider theme={theme}>
    <StoreProvider store={store}>
      <div className="App">
        <Router>
          <Switch>
            <Route path="/god-mode">
              <AdminPageRoute />
            </Route>
            <Route path="/" exact>
              <MainSurveyRoute />
            </Route>
            <Redirect from="/" to="/" />
          </Switch>
        </Router>
      </div>
    </StoreProvider>
  </ThemeProvider>
);

export default App;
