import React from 'react';
import { TransitionGroup } from 'react-transition-group';
import { connect } from 'react-redux';

import { describeMode } from '../../model/SurveyMode';
import { getCurrentSurveyMode } from '../../selectors/surveys';
import { isApplicationOnline, isSyncInProgress } from '../../selectors/sync';
import { Fade } from '../../transitions';

import './ModeIndicator.css';

/**
 * React component that indicates the current surveying mode to the user, and
 * optionally also shows a message when the user is offline or when the app is
 * syncing.
 */
const ModeIndicator = ({ description, isOnline, isSyncing }) => {
  const message = !isOnline
    ? 'You are offline, please try to reconnect.'
    : isSyncing
    ? 'Synchronization in progress...'
    : description;
  return (
    <TransitionGroup>
      {message ? (
        <Fade>
          <div className="ModeIndicator">{message}</div>
        </Fade>
      ) : null}
    </TransitionGroup>
  );
};

export default connect(
  // mapStateToProps
  (state, ownProps) => ({
    description: describeMode(getCurrentSurveyMode(state)),
    isOnline: isApplicationOnline(state),
    isSyncing: isSyncInProgress(state),
  })
)(ModeIndicator);
