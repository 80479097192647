// import React from 'react';

import './Backmatter.css';

/**
 * Component that shows the backmatter of the survey once the survey has been
 * completed and the responses saved.
 */
const Backmatter = ({ content }) => content;

export default Backmatter;
