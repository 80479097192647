import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import Backmatter from './Backmatter';
import LanguageSelector from './LanguageSelector';
import NavigationControls from './NavigationControls';
import SurveyPage from './SurveyPage';

import {
  invokeSoftValidatorForSurvey,
  navigateToNextSurveyPage,
  navigateToPreviousSurveyPage,
  restartSurvey,
  saveCurrentSurvey,
  showBackmatterOrRestartSurvey,
  updateResponse,
} from '../actions/surveys';
import {
  getCurrentResponses,
  getCurrentPageIndex,
  getCurrentSurveyData,
  getCurrentSurveyLanguage,
  getCurrentSurveyVariant,
  getMaxPageReached,
  isShowingBackmatter,
} from '../selectors/surveys';
import { useStateWithPromise } from '../utils/hooks';

import './SurveyView.css';

/**
 * Component implementing the main survey view of the application.
 */
const SurveyView = ({
  canGoBack,
  canGoForward,
  canStart,
  content,
  currentPage,
  language,
  onBack,
  onFinishSurvey,
  onForward,
  onReturnToFrontPage,
  onSubmit,
  reachedLastPage,
  showingBackmatter,
  subtitle,
  survey,
  title,
}) => {
  const [direction, setDirection] = useStateWithPromise('forward');

  const onBackAndSwitchTransition = useCallback(
    async (...args) => {
      // Let the component know that it needs to use a "back" animation now, and
      // call the callback only after the component has re-rendered
      await setDirection('back');
      onBack(...args);
    },
    [onBack, setDirection]
  );

  const onForwardAndSwitchTransition = useCallback(
    async (...args) => {
      // Let the component know that it needs to use a "forward" animation now, and
      // call the callback only after the component has re-rendered
      await setDirection('forward');
      onForward(...args);
    },
    [onForward, setDirection]
  );

  useEffect(() => {
    const handle = setTimeout(() => {
      if (direction === 'back') {
        setDirection('forward');
      }
    }, 1500);

    return () => {
      clearTimeout(handle);
    };
  }, [direction, setDirection]);

  return (
    <div className="SurveyView">
      <TransitionGroup appear={false} component={null}>
        <CSSTransition
          key={currentPage}
          classNames={
            direction === 'back'
              ? 'SurveyPageTransitionBack'
              : 'SurveyPageTransitionForward'
          }
          timeout={1000}
        >
          <div className="SurveyPage">
            <header>
              {title && title.length ? <h1>{title}</h1> : null}
              {subtitle && subtitle.length ? <h2>{subtitle}</h2> : null}
            </header>
            <div className="SurveyPageContents">
              {showingBackmatter ? (
                <Backmatter content={content} />
              ) : (
                <SurveyPage
                  content={content}
                  onFinishSurvey={onFinishSurvey}
                  onSubmit={onSubmit}
                  survey={survey}
                />
              )}
              {canStart && !showingBackmatter && (
                <LanguageSelector survey={survey} />
              )}
            </div>
            <footer>
              <NavigationControls
                language={language}
                onBack={canGoBack ? onBackAndSwitchTransition : null}
                onForward={canGoForward ? onForwardAndSwitchTransition : null}
                onFinishSurvey={reachedLastPage ? () => onFinishSurvey() : null}
                onReturnToFrontPage={
                  showingBackmatter ? () => onReturnToFrontPage() : null
                }
              />
            </footer>
          </div>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
};

export default connect(
  // mapStateToProps
  (state, ownProps) => {
    const currentPage = getCurrentPageIndex(state);
    const data = getCurrentSurveyData(state);
    const language = getCurrentSurveyLanguage(state);
    const maxPageReached = getMaxPageReached(state);
    const responses = getCurrentResponses(state);
    const variant = getCurrentSurveyVariant(state);
    const { survey } = ownProps;

    const currentPageId = survey.getPageIdFromIndex(currentPage, variant);
    const started = currentPage >= 0;
    const showingBackmatter = isShowingBackmatter(state);
    const startedAndNotFinished = started && !showingBackmatter;
    const reachedLastPage =
      variant && currentPage >= survey.getNumPagesInVariant(variant) - 1;
    const content =
      (showingBackmatter
        ? survey.getContentOfBackmatter({ data, language, responses })
        : survey.getContentOfPageById(currentPageId, {
            data,
            language,
            responses,
          })) || null;

    const canGoBack =
      (!reachedLastPage || survey.hasBackmatter(language)) && currentPage > 0;
    const canGoForward =
      !reachedLastPage &&
      !showingBackmatter &&
      survey.validatePageById(currentPageId, {
        language,
        responses,
      }) &&
      (!survey.willSubmitPageImplicitly(currentPageId, language) ||
        maxPageReached > currentPage);
    const saveOnSubmit = survey.shouldSaveAfterSubmittingPage(
      currentPageId,
      language
    );

    return {
      canGoBack,
      canGoForward,
      canStart: !started,
      content,
      currentPage,
      language: language || 'en',
      reachedLastPage,
      saveOnSubmit,
      showingBackmatter,
      subtitle: startedAndNotFinished
        ? survey.getSubtitleOfPageById(currentPageId, language)
        : showingBackmatter
        ? survey.getBackmatterSubtitle(language)
        : survey.getSubtitle(),
      title: startedAndNotFinished
        ? survey.getTitleOfPageById(currentPageId, language)
        : showingBackmatter
        ? survey.getBackmatterTitle(language)
        : survey.getTitle(),
    };
  },

  // mapDispatchToProps
  (dispatch, ownProps) => ({
    onBack: () => {
      const { survey } = ownProps;
      dispatch(navigateToPreviousSurveyPage(survey));
    },
    onForward: () => {
      const { survey } = ownProps;
      dispatch(navigateToNextSurveyPage(survey));
    },
    onFinishSurvey: (response) => {
      const { survey } = ownProps;
      dispatch(async (innerDispatch, getState) => {
        if (response) {
          innerDispatch(updateResponse(response));
        }
        const isValid = await invokeSoftValidatorForSurvey(survey, getState());
        if (isValid) {
          innerDispatch(saveCurrentSurvey(survey));
          innerDispatch(showBackmatterOrRestartSurvey(survey));
        }
      });
    },
    onReturnToFrontPage: () => {
      dispatch(restartSurvey());
    },
    onSaveSurvey: () => {
      const { survey } = ownProps;
      dispatch(saveCurrentSurvey(survey));
    },
  }),

  // mergeProps
  (stateProps, dispatchProps, ownProps) => {
    const { saveOnSubmit, ...stateRest } = stateProps;
    const { onSaveSurvey, ...dispatchRest } = dispatchProps;

    if (saveOnSubmit) {
      dispatchRest.onSubmit = onSaveSurvey;
    }

    return {
      ...ownProps,
      ...stateRest,
      ...dispatchRest,
    };
  }
)(SurveyView);
