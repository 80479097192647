import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import find from 'lodash-es/find';
import { matchSorter } from 'match-sorter';
import React, { useCallback, useMemo } from 'react';

import { localize } from '../utils/localization';

const localizationData = {
  en: {
    label: 'Choose your country',
    helperText: 'Please click on the outlined area to select your country',
  },
  fr: {
    label: 'Choisissez votre pays',
    helperText:
      'Veuillez cliquer sur la zone délimitée pour sélectionner votre pays',
  },
  es: {
    label: 'Elige tu país',
    helperText:
      'Por favor, haz clic en el área marcada para seleccionar tu país',
  },
};

const filterOptions = (options, { inputValue }) =>
  matchSorter(options, inputValue, { keys: ['name'] });

/**
 * Converts a country object to a Unicode flag emoji using the two-letter
 * ISO-3166-1 alpha-2 country code and Unicode Regional Indicator Symbols.
 */
const countryToFlag = (country) => {
  const code = country.isoAlpha2Code;

  if (code === undefined) {
    return '\u{1f3f3}';
  }

  return typeof String.fromCodePoint !== 'undefined'
    ? code
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : code;
};

/**
 * Extracts the English name of a country from the data object received in the
 * country selector.
 */
const countryToEnglishName = (country) => country.name;

/**
 * Extracts the localized name of a country from the data object received in the
 * country selector.
 */
const countryToLocalizedName = (country, language) => {
  const translations = country?.translations;
  return translations && translations[language]
    ? translations[language]
    : countryToEnglishName(country);
};

/**
 * Converts a country to the country code that we will enter in the response.
 */
const countryToValue = (country) => country.iocCode;

/**
 * Render function that renders the main textbox of the country selector.
 */
const renderInput = (localize) => ({ inputProps, ...props }) => (
  <TextField
    {...props}
    label={localize('label')}
    helperText={localize('helperText')}
    variant="outlined"
    fullWidth
    inputProps={{
      ...inputProps,
      autoComplete: 'disabled',
    }}
  />
);

/**
 * Render function that renders a single item in the autocompletion dropdown
 * of the country selector.
 */
const renderLocalizedOption = (country, language) => (
  <React.Fragment>
    <span>{countryToFlag(country)}</span>
    {'\u00a0'}
    <span>
      {country.iocCode ? <b>{`${country.iocCode}\u00a0`}</b> : null}
      {countryToLocalizedName(country, language)}
    </span>
  </React.Fragment>
);

/**
 * List of countries supported by the selector.
 *
 * These were obtained from:
 *
 * https://github.com/lukes/ISO-3166-Countries-with-Regional-Codes/blob/master/slim-2/slim-2.json
 *
 * IOC country codes were obtained from:
 *
 * https://raw.githubusercontent.com/DevopediaOrg/data-analytics/master/olympics/data/country.codes.csv
 */
const countries = require('../assets/country-codes.json');

const lookupCountryByIOCCode = (code) => find(countries, ['iocCode', code]);

/**
 * React component that allows the user to select a country from a list of
 * countries.
 */
const CountrySelector = ({ language, onChange, value }) => {
  const inputRenderer = useMemo(
    () => renderInput(localize(localizationData, language)),
    [language]
  );

  const selectedCountry = useMemo(() => lookupCountryByIOCCode(value), [value]);
  const countryToName = useCallback(
    (country) => countryToLocalizedName(country, language),
    [language]
  );
  const renderOption = useCallback(
    (country) => renderLocalizedOption(country, language),
    [language]
  );

  return (
    <Autocomplete
      autoComplete
      disableClearable
      options={countries}
      onChange={(_event, value) => onChange(countryToValue(value))}
      style={{ width: 640 }}
      filterOptions={filterOptions}
      getOptionLabel={countryToName}
      renderInput={inputRenderer}
      renderOption={renderOption}
      value={selectedCountry}
    />
  );
};

export default CountrySelector;
