/**
 * @file Saga that shows an inactivity warning dialog when the user is
 * completing a survey and he has been inactive for a long time.
 */

import { call, delay, put, race, select, take } from 'redux-saga/effects';

import {
  openInactivityWarningDialog,
  closeInactivityWarningDialog,
} from '../actions/inactivity';
import {
  cancelCurrentSurveyWithTimeout,
  restartSurvey,
} from '../actions/surveys';
import { SET_CURRENT_SURVEY_PAGE } from '../actions/types';
import {
  isInactivityDialogVisible,
  shouldWarnUserAboutInactivity,
  shouldWatchUserInactivity,
} from '../selectors/inactivity';
import { waitFor, waitWhile } from './helpers';

/**
 * Saga that shows an inactivity warning dialog when the user is
 * completing a survey and he has been inactive for a long time.
 *
 * @param  {Number}  config.interval  the maximum number of seconds the user is
 *         allowed to stay idle before showing the dialog.
 * @param  {Number}  config.dialogInterval  the maximum number of seconds the warning
 *         dialog should be shown for before the dialog is dismissed and the
 *         survey is cancelled
 */
export default function* inactivitySaga(survey, config) {
  const { interval, dialogInterval } = {
    interval: 5,
    dialogInterval: 5,
    ...config,
  };

  while (true) {
    yield call(waitFor, shouldWatchUserInactivity);

    const result = yield race({
      notWatchingAnymore: call(waitWhile, shouldWatchUserInactivity),
      userBecameActive: take(SET_CURRENT_SURVEY_PAGE),
      timeout: delay(interval * 1000),
    });

    if (result.timeout) {
      const shouldWarn = yield select(shouldWarnUserAboutInactivity);
      if (shouldWarn) {
        // User is not on the backmatter page so show a warning
        yield put(openInactivityWarningDialog());
        const result2 = yield race({
          dialogClosedByUser: call(waitWhile, isInactivityDialogVisible),
          timeout: delay(dialogInterval * 1000),
        });

        if (result2.timeout) {
          yield put(closeInactivityWarningDialog());
          yield put(cancelCurrentSurveyWithTimeout(survey));
          yield put(restartSurvey());
        }
      } else {
        // User is on the backmatter page, so we can safely restart the survey
        yield put(restartSurvey());
      }
    }
  }
}
