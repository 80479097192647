import mapValues from 'lodash-es/mapValues';
import PropTypes from 'prop-types';
import React from 'react';
import url from 'url';
import { useIsExtraSmallScreen } from '../utils/hooks';

import SingleChoicePage from './SingleChoicePage';

import './CWMPage.css';

const webRoot = process.env.PUBLIC_URL;

const headings = {
  de: 'Wählen Sie eine Antwort aus:',
  en: 'Select one from:',
  es: 'Seleccione una opción:',
  fr: 'Réponse - Sélectionnez-en une parmi:',
  it: 'Selezioni una delle opzioni:',
  nl: 'Kies één van onderstaande antwoorden:',
  pl: 'Wybierz jedną z:',
  pt: 'Selecione uma opção:',
  ru: 'Выберите один из вариантов:',
};
headings.br = headings.pt;

const responseOptions = {
  de: [
    'Beide Aussagen sind wahr ODER keine ist wahr',
    'Nur EINE Aussage ist wahr',
  ],
  en: [
    'SAME - Both statements are true OR none of the statements are true',
    'DIFFERENT - Only ONE statement is true',
  ],
  es: [
    'IGUALES - Los dos enunciados son verdaderos O ninguno de los dos enunciados son verdaderos',
    'DIFERENTES - Solamente UNO de los enunciados es verdadero',
  ],
  fr: [
    'IDENTIQUE - Les deux énoncés sont vrais OU aucune de ces affirmations n’est vraie',
    'DIFFERENTE - Une seule instruction est vraie',
  ],
  it: [
    'Entrambe le affermazioni sono vere OPPURE nessuna delle affermazioni è vera',
    'Solo UNA delle affermazioni è vera',
  ],
  nl: [
    'Beide stellingen zijn waar OF geen van beide stellingen is waar',
    'Slechts ÉÉN stelling is waar',
  ],
  pl: [
    'Oba stwierdzenia są prawdziwe LUB żadne ze stwierdzeń nie jest prawdziwe',
    'Tylko JEDNO stwierdzenie jest prawdziwe',
  ],
  pt: [
    'Ambas as afirmações são verdadeiras OU nenhuma afirmação é verdadeira',
    'Apenas UMA afirmação é verdadeira',
  ],
  ru: [
    'Оба утверждения являются верными ИЛИ ни одно из утверждений не является верным',
    'Только ОДНО из утверждений является верным',
  ],
};
responseOptions.br = responseOptions.pt;

const images = {
  onlyOne: url.resolve(webRoot, 'img/buttons/only-1.png'),
  zeroOrTwo: url.resolve(webRoot, 'img/buttons/0-or-2.png'),
};

const responses = mapValues(responseOptions, (value) => [
  {
    image: images.zeroOrTwo,
    imageHeight: 100,
    label: value[0],
    value: 'zeroOrTwo',
  },
  {
    image: images.onlyOne,
    imageHeight: 100,
    label: value[1],
    value: 'onlyOne',
  },
]);

/**
 * React component that shows a CWM-type question and the corresponding
 * response buttons.
 *
 * The children of the component must contain the question itself.
 * Buttons will be rendered at the bottom.
 */
const CWMPage = ({ language, ...rest }) => {
  const buttonsShouldFitHorizontally = !useIsExtraSmallScreen();
  return (
    <SingleChoicePage
      footer={<h2 style={{ marginBottom: 8 }}>{headings[language]}</h2>}
      {...rest}
      responses={responses[language]}
      vertical={!buttonsShouldFitHorizontally}
      variant="dense"
    />
  );
};

CWMPage.propTypes = {
  ...SingleChoicePage.propTypes,
  language: PropTypes.string,
};
// The next condition is needed because we strip the propTypes completely in
// production builds
/* eslint-disable react/forbid-foreign-prop-types */
if (CWMPage.propTypes) {
  delete CWMPage.propTypes.responses;
}
/* eslint-enable react/forbid-foreign-prop-types */

export default CWMPage;
