/**
 * Enum containing constants describing the different states that a
 * synchronizable item (e.g,. a survey) can be in.
 */
export const SynchronizationStatus = {
  NEW: 'new',
  UPLOADED: 'uploaded',
  UPLOADING: 'uploading',
  ERROR: 'error',

  isError: (status) => status === 'error',
  isNotSynchronizedYet: (status) => status !== 'uploaded',
  isSynchronized: (status) => status === 'uploaded',
  isBeingSynchronized: (status) => status === 'uploading',
  needsToBeSynchronized: (status) =>
    status !== 'uploaded' && status !== 'uploading',
};

const descriptions = {
  [SynchronizationStatus.NEW]: 'Not synchronized yet',
  [SynchronizationStatus.UPLOADED]: 'Uploaded to server',
  [SynchronizationStatus.UPLOADING]: 'Synchronization in progress...',
  [SynchronizationStatus.ERROR]: 'Error while uploading',
};

/**
 * Function that maps a synchronization status to a human-readable
 * description.
 */
export function describeSynchronizationStatus(status) {
  return descriptions[status];
}

export default SynchronizationStatus;
