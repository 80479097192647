import isNil from 'lodash-es/isNil';
import partial from 'lodash-es/partial';
import range from 'lodash-es/range';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Star from '@material-ui/icons/Star';
import StarBorder from '@material-ui/icons/StarBorder';
import PropTypes from 'prop-types';
import React from 'react';

const tableCellStyle = {
  fontSize: '1em',
  padding: '4px 24px 4px 24px',
  textAlign: 'center',
};

const tableRowHeadingStyle = {
  fontSize: '1em',
  padding: 8,
};

/**
 * Renders the header cells in the rating matrix.
 *
 * @param {string} opts.labels  labels for all the ratings
 * @param {number} opts.minRating  the minimum rating
 * @param {number} opts.maxRating  the maximum rating
 * @param {string} opts.minRatingLabel  helper label for the minimum rating;
 *        overrides whatever is given in 'labels'
 * @param {string} opts.maxRatingLabel  helper label for the maximum rating;
 *        overrides whatever is given in 'labels'
 */
const renderHeaderCells = ({
  labels,
  minRating,
  maxRating,
  minRatingLabel,
  midRatingLabel,
  maxRatingLabel,
}) => {
  const effectiveLabels = [...(labels || [])];
  if (minRatingLabel) {
    effectiveLabels[0] = minRatingLabel;
  }
  if (maxRatingLabel) {
    effectiveLabels[maxRating - minRating + 1] = maxRatingLabel;
  }
  return range(minRating, maxRating + 1).map((rating) => (
    <TableCell key={rating} style={tableCellStyle}>
      {effectiveLabels[rating - minRating] || rating}
    </TableCell>
  ));
};

/**
 * Renders a single React component for a statement.
 *
 * @param {number} opts.minRating  the minimum rating
 * @param {number} opts.maxRating  the maximum rating
 * @param {string} opts.index  the index of the statement
 * @param {string} opts.statement  the statement
 * @param {number} opts.value  the currently selected rating
 * @param {function} opts.onUpdate  function to call to update a rating.
 *        It will be called with the statement index and the rating.
 */
const renderStatement = ({
  index,
  minRating,
  maxRating,
  onUpdate,
  statement,
  value,
}) => (
  <TableRow key={index}>
    <TableCell style={tableRowHeadingStyle}>{statement}</TableCell>
    {range(minRating, maxRating + 1).map((rating) => (
      <TableCell key={rating} style={tableCellStyle}>
        <IconButton
          onClick={partial(onUpdate, index, rating)}
          color={value !== undefined && value >= rating ? 'primary' : 'default'}
        >
          {value !== undefined && value >= rating ? <Star /> : <StarBorder />}
        </IconButton>
      </TableCell>
    ))}
  </TableRow>
);

/**
 * React component that allows the user to select ratings on a numeric
 * scale (typically from 1 to 5) for a set of statements.
 */
const RatingsPage = ({
  children,
  getCurrentResponse,
  labels,
  minRating,
  minRatingLabel,
  maxRating,
  maxRatingLabel,
  onSubmit,
  onUpdate,
  responseKey,
  statements,
}) => {
  let currentResponse = getCurrentResponse(responseKey);

  currentResponse = currentResponse ? [...currentResponse] : [];
  currentResponse.length = statements.length;

  const onUpdateRating = (index, rating) => {
    const newResponse = [...currentResponse];
    if (index >= 0 && index < statements.length) {
      newResponse[index] = rating;
    }

    onUpdate({
      [responseKey]: newResponse,
    });

    if (!newResponse.some(isNil)) {
      // All responses are ready, so we can move to the next page.
      // We do that after a small delay to give the user feedback that
      // the rating was recorded.
      setTimeout(() => onSubmit(), 500);
    }
  };

  return (
    <Card raised className="RatingsPage">
      <CardContent>
        {children}
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              {renderHeaderCells({
                labels,
                minRating,
                maxRating,
                minRatingLabel,
                maxRatingLabel,
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {statements.map((statement, index) =>
              renderStatement({
                statement,
                index,
                minRating,
                maxRating,
                onUpdate: onUpdateRating,
                value: currentResponse[index],
              })
            )}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

RatingsPage.propTypes = {
  getCurrentResponse: PropTypes.func,
  labels: PropTypes.arrayOf(PropTypes.string),
  minRating: PropTypes.number.isRequired,
  minRatingLabel: PropTypes.string,
  maxRating: PropTypes.number.isRequired,
  maxRatingLabel: PropTypes.string,
  onSubmit: PropTypes.func,
  onUpdate: PropTypes.func,
  responseKey: PropTypes.string,
  statements: PropTypes.array.isRequired,
};

RatingsPage.defaultProps = {
  minRating: 1,
  maxRating: 6,
};

export default RatingsPage;
