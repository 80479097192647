import IconButton from '@material-ui/core/IconButton';
import Exit from '@material-ui/icons/ExitToApp';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import {
  cancelCurrentSurveyByRespondent,
  restartSurvey,
} from '../../actions/surveys';
import { confirm } from '../../components/dialogs';
import {
  getCurrentSurveyLanguage,
  isSurveyInProgress,
} from '../../selectors/surveys';
import { localize } from '../../utils/localization';

import './ExitButton.css';

const localizationData = {
  de: {
    dialogTitle: 'Umfrage abbrechen',
    dialogMessage:
      'Möchten Sie die Umfrage wirklich abbrechen und zur Startseite zurückkehren?',
    yes: 'Ja',
    no: 'Nein',
  },

  en: {
    dialogTitle: 'Cancel survey',
    dialogMessage:
      'Are you sure you wish to cancel the survey and return to the start page?',
    yes: 'Yes',
    no: 'No',
  },

  es: {
    dialogTitle: 'Cancelar la encuesta',
    dialogMessage:
      '¿Confirma que desea cancelar la encuesta y volver a la página de inicio?',
    yes: 'Sí',
    no: 'No',
  },

  fr: {
    dialogTitle: 'Annuler le questionnaire',
    dialogMessage:
      'Êtes-vous certain(e) de vouloir annuler le questionnaire et revenir à la page d’accueil?',
    yes: 'Oui',
    no: 'Non',
  },

  it: {
    dialogTitle: 'Annulla il sondaggio',
    dialogMessage:
      'Si desidera davvero annullare il sondaggio e tornare alla pagina iniziale?',
    yes: 'Si',
    no: 'No',
  },

  nl: {
    dialogTitle: 'Enquête annuleren',
    dialogMessage:
      'Weet je zeker dat je de enquête wilt annuleren en wilt terugkeren naar de startpagina?',
    yes: 'Ja',
    no: 'Nee',
  },

  pl: {
    dialogTitle: 'Anuluj ankietę',
    dialogMessage:
      'Czy na pewno chcesz anulować ankietę i wrócić do strony głównej?',
    yes: 'Tak',
    no: 'Nie',
  },

  pt: {
    dialogTitle: 'Cancelar o questionário',
    dialogMessage:
      'Tem a certeza que pretende cancelar o inquérito e voltar à página inicial?',
    yes: 'Sim',
    no: 'Não',
  },

  ru: {
    dialogTitle: 'Отменить опрос',
    dialogMessage:
      'Вы действительно хотите отменить опрос и вернуться на начальную страницу?',
    yes: 'Да',
    no: 'Нет',
  },
};

localizationData.br = localizationData.pt;

/**
 * React component that renders a button that can be used to exit from
 * the survey at any point.
 */
const ExitButton = ({ onClick, visible }) =>
  visible && (
    <div className="ExitButton">
      <IconButton onClick={onClick}>
        <Exit style={{ color: 'white' }} />
      </IconButton>
    </div>
  );

ExitButton.propTypes = {
  onClick: PropTypes.func,
  visible: PropTypes.bool,
};

export default connect(
  // mapStateToProps
  (state) => ({
    language: getCurrentSurveyLanguage(state),
    visible: isSurveyInProgress(state),
  }),
  // mapDispatchToProps
  (dispatch, ownProps) => ({
    onClick: (language) => async () => {
      const ok = await confirm(
        localize(localizationData, language, 'dialogTitle'),
        localize(localizationData, language, 'dialogMessage'),
        localize(localizationData, language, {
          yesLabel: 'yes',
          noLabel: 'no',
        })
      );
      if (ok) {
        dispatch(cancelCurrentSurveyByRespondent(ownProps.survey));
        dispatch(restartSurvey());
      }
    },
  }),
  // mergeProps
  (stateProps, dispatchProps, ownProps) => ({
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    onClick: dispatchProps.onClick(stateProps.language),
  })
)(ExitButton);
