/**
 * @file Inactivity dialog related action factories.
 */

import { createAction } from 'redux-actions';

import {
  CLOSE_INACTIVITY_WARNING_DIALOG,
  OPEN_INACTIVITY_WARNING_DIALOG,
} from './types';

/**
 * Creates an action that closes the inactivity warning dialog.
 */
export const closeInactivityWarningDialog = createAction(
  CLOSE_INACTIVITY_WARNING_DIALOG
);

/**
 * Creates an action that shows the inactivity warning dialog.
 */
export const openInactivityWarningDialog = createAction(
  OPEN_INACTIVITY_WARNING_DIALOG,
  () => ({
    shownAt: new Date().getTime(),
  })
);
