import clsx from 'clsx';
import isNil from 'lodash-es/isNil';
import reject from 'lodash-es/reject';
import startsWith from 'lodash-es/startsWith';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import Box from '@material-ui/core/Box';

import { prompt } from './dialogs';
import ResponseButton from './ResponseButton';

import './ResponseButtonBar.css';

/**
 * Special value prefix for "other" responses if the response bar supports
 * that the user types in his/her own answer.
 */
const OTHER_ID = 'other: ';

/**
 * Returns whether the given response item is considered as a custom response
 * item entered by the user.
 */
const isCustomResponse = (response) => startsWith(response, OTHER_ID);

/**
 * Ensures that the incoming response object is in the right format for the
 * <code>renderResponseButton()</code> function call.
 *
 * When the incoming response object is a string, it returns an object that
 * contains the same string both for the "value" and the "label" keys. Returns
 * the object itself in all other cases.
 */
const ensureResponseObject = (response) => {
  if (typeof response === 'string') {
    return {
      value: response,
      label: response,
    };
  } else if (typeof response === 'object') {
    if (response.value !== undefined && response.label === undefined) {
      return {
        ...response,
        label: response.value,
      };
    } else if (response.label !== undefined && response.value === undefined) {
      return {
        ...response,
        value: response.label,
      };
    } else {
      return response;
    }
  } else {
    throw new Error('invalid response object');
  }
};

/**
 * Renders a response button from an object having a "value" and a "label"
 * prop.
 *
 * @param  {object}  response  the response object to render
 * @param  {string} response.icon   the icon to show on the response button
 * @param  {string} response.image  the URL of the image to show on the response button
 * @param  {string} response.imageHeight  height of the image to show on the response button (optional)
 * @param  {string} response.label  the label to show on the response button
 * @param  {string} response.explanation  additional explanatory text to show besides
 *         the response button with smaller font size
 * @param  {string|number} response.value  the key of the response (this
 *         will be stored in the survey results)
 * @param  {boolean} selected  whether the response is currently selected
 * @param  {object} onSubmit  function that can be called with a single
 *         argument to store the response in the survey
 * @param  {string} variant    whether the response button should be smaller
 *         than usual ("dense"), larger than usual ("large") or normal size
 *         ("normal")
 */
const renderResponseButton = (
  { explanation, icon, image, imageHeight, label, value },
  selected,
  onSubmit,
  variant
) => {
  image = image ? (
    imageHeight !== undefined ? (
      <img src={image} alt={label} height={imageHeight} />
    ) : (
      <img src={image} alt={label} />
    )
  ) : null;

  const button = (
    <ResponseButton
      fullHeight={!!explanation}
      key={value}
      onClick={() => onSubmit(value)}
      selected={selected}
      startIcon={icon}
      variant={variant}
    >
      {image}
      {label || (image ? null : value)}
    </ResponseButton>
  );

  if (explanation) {
    return (
      <Box key={value} className="buttonAndExplanationContainer" py={1}>
        <Box className="buttonBox" px={2}>
          {button}
        </Box>
        <Box className="explanationBox" px={2}>
          {explanation}
        </Box>
      </Box>
    );
  }
  return button;
};

/**
 * React component that renders a horizontal or vertical bar of response
 * buttons, allowing the user to select a single response only.
 */
const SingleResponseButtonBar = ({
  allowOtherResponse,
  children,
  onSubmit,
  otherResponseDialogTitle,
  otherResponseLabel,
  responses,
  value,
  variant,
  vertical,
}) => {
  const renderer = children || renderResponseButton;

  const onEnterOtherResponse = useCallback(
    async (item) => {
      if (!isCustomResponse(item)) {
        return;
      }

      const previousResponse = item.substr(OTHER_ID.length);
      const newResponse = await prompt('', otherResponseDialogTitle, {
        defaultValue: previousResponse,
      });

      if (!isNil(newResponse) && newResponse.length > 0) {
        onSubmit(OTHER_ID + newResponse);
      }
    },
    [onSubmit, otherResponseDialogTitle]
  );

  return (
    <div
      className={clsx({
        ResponseButtonBar: true,
        dense: variant === 'dense',
        large: variant === 'large',
        vertical,
      })}
    >
      {(responses || []).map((response) => {
        response = ensureResponseObject(response);
        return renderer(response, response.value === value, onSubmit, variant);
      })}
      {allowOtherResponse &&
        renderer(
          {
            value: isCustomResponse(value) ? value : OTHER_ID,
            label: otherResponseLabel,
          },
          isCustomResponse(value),
          onEnterOtherResponse,
          variant
        )}
    </div>
  );
};

SingleResponseButtonBar.propTypes = {
  allowOtherResponse: PropTypes.bool,
  children: PropTypes.func,
  onSubmit: PropTypes.func,
  otherResponseDialogTitle: PropTypes.string,
  otherResponseLabel: PropTypes.string,
  responses: PropTypes.array,
  variant: PropTypes.oneOf(['dense', 'normal', 'large']),
  vertical: PropTypes.bool,
};

/**
 * React component that renders a horizontal or vertical bar of response
 * buttons, allowing the user to select multiple responses.
 */
const MultiResponseButtonBar = ({
  allowOtherResponse,
  children,
  onSubmit,
  onUpdate,
  otherResponseDialogTitle,
  otherResponseLabel,
  responses,
  value,
  variant,
  vertical,
}) => {
  const onToggle = useCallback(
    (item) => {
      const newValue = value ? [...value] : [];
      const index = newValue.indexOf(item);
      if (index >= 0) {
        newValue.splice(index, 1);
      } else {
        newValue.push(item);
      }
      onUpdate(newValue);
    },
    [onUpdate, value]
  );

  const onEnterOtherResponse = useCallback(
    async (item) => {
      if (!isCustomResponse(item)) {
        return;
      }

      const previousResponse = item.substr(OTHER_ID.length);
      const newResponse = await prompt('', otherResponseDialogTitle, {
        defaultValue: previousResponse,
      });

      if (!isNil(newResponse)) {
        const newValue = reject(value || [], isCustomResponse);
        if (newResponse.length > 0) {
          newValue.push(OTHER_ID + newResponse);
        }
        onUpdate(newValue);
      }
    },
    [onUpdate, value, otherResponseDialogTitle]
  );

  const renderer = children || renderResponseButton;
  const customResponses = (value || []).filter(isCustomResponse);

  return (
    <div
      className={clsx({
        ResponseButtonBar: true,
        dense: variant === 'dense',
        large: variant === 'large',
        multiple: true,
        vertical,
      })}
    >
      {(responses || []).map((response) => {
        response = ensureResponseObject(response);
        return renderer(
          response,
          value && value.indexOf(response.value) >= 0,
          onToggle,
          variant
        );
      })}
      {allowOtherResponse &&
        renderer(
          {
            value: customResponses.length > 0 ? customResponses[0] : OTHER_ID,
            label: otherResponseLabel,
          },
          value && value.some(isCustomResponse),
          onEnterOtherResponse,
          variant
        )}
    </div>
  );
};

MultiResponseButtonBar.propTypes = {
  allowOtherResponse: PropTypes.bool,
  children: PropTypes.func,
  onSubmit: PropTypes.func,
  otherResponseDialogTitle: PropTypes.string,
  otherResponseLabel: PropTypes.string,
  responses: PropTypes.array,
  variant: PropTypes.oneOf(['dense', 'normal', 'large']),
  vertical: PropTypes.bool,
};

MultiResponseButtonBar.defaultProps = {
  otherResponseLabel: 'Other',
  variant: 'normal',
};

/**
 * React component that renders a horizontal or vertical bar of response buttons.
 */
export const ResponseButtonBar = ({ multiple, ...rest }) =>
  multiple ? (
    <MultiResponseButtonBar {...rest} />
  ) : (
    <SingleResponseButtonBar {...rest} />
  );

ResponseButtonBar.propTypes = {
  allowOtherResponse: PropTypes.bool,
  children: PropTypes.func,
  multiple: PropTypes.bool,
  onSubmit: PropTypes.func,
  otherResponseDialogTitle: PropTypes.string,
  otherResponseLabel: PropTypes.string,
  responses: PropTypes.array,
  variant: PropTypes.oneOf(['dense', 'normal', 'large']),
  vertical: PropTypes.bool,
};

ResponseButtonBar.defaultProps = {
  otherResponseLabel: 'Other',
  variant: 'normal',
};

export default ResponseButtonBar;
