/**
 * Selector function that returns the name of the device.
 */
export const getDeviceName = (state) => state.onboarding.deviceName;

/**
 * Selector that returns the state of the onboarding process from the
 * given state store.
 */
export const getOnboardingStatus = (state) => {
  const { onboarding } = state;
  const { complete, passwordValid } = onboarding || {};

  if (complete) {
    return 'completed';
  } else if (passwordValid) {
    return 'passwordValid';
  } else {
    return 'notStarted';
  }
};

/**
 * Selector function that returns whether the onboarding process
 * has finished or not.
 */
export const isOnboardingComplete = (state) => state.onboarding.complete;
