/**
 * Functions related to the usage of the survey app as a progressive web
 * application.
 */

import get from 'lodash-es/get';

import {
  notifyReadyForOfflineUse,
  notifyUpdateDownloaded,
  notifyUpdateInstalled,
} from './actions/updates';
import config from './config';
import * as serviceWorker from './serviceWorker';
import store from './store';

export function preparePWA() {
  serviceWorker.register({
    onReady() {
      store.dispatch(notifyReadyForOfflineUse());
    },

    onSuccess() {
      store.dispatch(notifyUpdateInstalled());
    },

    onUpdate() {
      store.dispatch(notifyUpdateDownloaded());
    },
  });

  if (!get(config, 'allowInstallationPrompt', true)) {
    window.addEventListener('beforeinstallprompt', (event) => {
      event.preventDefault();
    });
  }
}
