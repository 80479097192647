export { isSyncInProgress } from './surveys';

/**
 * Selector that returns whether automatic synchronization is enabled.
 */
export const isAutomaticSyncEnabled = (state) => state.sync.automatic;

/**
 * Selector that returns whether the application is currently online.
 */
export const isApplicationOnline = (state) => state.sync.online;
