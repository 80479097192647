/**
 * @file Root saga that controls all the background processes
 * across the application.
 */

import { all } from 'redux-saga/effects';

import inactivitySaga from './inactivity';
import lockScreenSaga from './lockScreen';
import lockSidebarSaga from './lockSidebar';
import onboardingSaga from './onboarding';
import syncSaga from './sync';
import updaterSaga from './updates';

import config from '../config';
import survey from '../survey';

export default function* rootSaga() {
  const sagas = [
    lockScreenSaga(),
    inactivitySaga(survey, config.inactivity),
    onboardingSaga(),
    syncSaga(),
    updaterSaga(),
  ];

  if (process.env.NODE_ENV === 'production') {
    sagas.push(lockSidebarSaga());
  }

  yield all(sagas);
}
