import React from 'react';
import { CSSTransition } from 'react-transition-group';

import './transitions.css';

/**
 * Function that creates a React component that wraps its children in a
 * CSS transition with the given props.
 */
export function wrapInCSSTransition(transitionName, options = {}) {
  const { defaultProps, name, timeout } = {
    timeout: 300,
    defaultProps: undefined,
    name: undefined,
    ...options,
  };
  const transitionNameIsFunction = typeof transitionName === 'function';
  const result = ({ children, ...props }) => (
    <CSSTransition
      {...props}
      timeout={timeout}
      classNames={
        transitionNameIsFunction ? transitionName(props) : transitionName
      }
    >
      {children}
    </CSSTransition>
  );
  result.displayName = `withTransition(${name || transitionName})`;
  if (defaultProps) {
    result.defaultProps = { ...defaultProps };
  }
  return result;
}

/**
 * CSS transition that fades an element in or out.
 */
export const Fade = wrapInCSSTransition('fade');

/**
 * CSS transition that fades an element and slides it in a given direction.
 */
export const FadeAndSlide = wrapInCSSTransition(
  ({ direction }) => `fade-and-slide-${direction}`,
  {
    defaultProps: {
      direction: 'down',
    },
    name: 'FadeAndSlide',
  }
);

/**
 * CSS transition that slides an element in some direction by its full width
 * or height.
 */
export const Slide = wrapInCSSTransition(
  ({ direction }) => `slide-${direction}`,
  {
    defaultProps: {
      direction: 'down',
    },
    name: 'Slide',
  }
);
