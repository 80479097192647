import { green, red } from '@material-ui/core/colors';
import Check from '@material-ui/icons/Check';
import Clear from '@material-ui/icons/Clear';
import React from 'react';

import SingleChoicePage from './SingleChoicePage';

import { localize } from '../utils/localization';

const createResponses = ([yesLabel, noLabel]) => [
  {
    icon: <Check style={{ color: green[500], fontSize: 36 }} />,
    label: yesLabel,
    value: 1,
  },
  {
    icon: <Clear style={{ color: red[500], fontSize: 36 }} />,
    label: noLabel,
    value: 0,
  },
];

const localizationData = {
  cn: {
    responses: ['是', '否'],
  },

  de: {
    responses: ['Ja', 'Nein'],
  },

  en: {
    responses: ['Yes', 'No'],
  },

  es: {
    responses: ['Sí', 'No'],
  },

  fr: {
    responses: ['Oui', 'Non'],
  },

  it: {
    responses: ['Si', 'No'],
  },

  ru: {
    responses: ['Да', 'Hет'],
  },
};

/**
 * Shortcut for a common use-case where we need to create a survey page
 * with a simple yes/no question. Uses `<SingleChoicePage>` internally.
 */
const YesNoChoicePage = ({ language, ...rest }) => (
  <SingleChoicePage
    {...rest}
    responses={createResponses(
      localize(localizationData, language, 'responses')
    )}
    variant="large"
  />
);

export default YesNoChoicePage;
