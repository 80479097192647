import constant from 'lodash-es/constant';

/**
 * Class describing the behaviour of a generic survey that is powered by a
 * simple JSON-based specification.
 */
export class Survey {
  /**
   * Constructor.
   */
  constructor() {
    this._languages = [];

    this._dataCreator = constant({});
    this._metaCreator = constant({});

    this._backmatterByLanguage = {};
    this._pagesByLanguage = {};

    this._variants = {};

    this._title = 'Untitled survey';
    this._subtitle = undefined;
  }

  /**
   * Factory function that creates a new survey from a JSON-like survey
   * specification.
   *
   * @param  {object} spec  the survey specification
   * @return {Survey} the survey instance
   */
  static createFromSpecification(spec) {
    const result = new Survey();

    if (Object.keys(spec.pages || {}).length === 0) {
      throw new Error('Needs at least one survey page!');
    }
    if (Object.keys(spec.variants || {}).length === 0) {
      throw new Error('Needs at least one survey variant!');
    }

    result._title = spec.title || 'Untitled survey';
    result._subtitle = spec.subtitle;

    result._languages = Object.freeze(
      spec.languages ? [...spec.languages] : []
    );

    result._backmatterByLanguage = result._languages.reduce((acc, language) => {
      acc[language.id] = { ...spec.backmatter[language.id] };
      return acc;
    }, {});
    result._pagesByLanguage = result._languages.reduce((acc, language) => {
      acc[language.id] = { ...spec.pages[language.id] };
      return acc;
    }, {});

    result._variants = { ...spec.variants };

    if (typeof spec.data === 'function') {
      result._dataCreator = spec.data;
    } else if (typeof spec.data === 'object') {
      result._dataCreator = constant(spec.data);
    } else {
      result._dataCreator = constant({});
    }

    if (typeof spec.meta === 'function') {
      result._metaCreator = spec.meta;
    } else if (typeof spec.meta === 'object') {
      result._metaCreator = constant(spec.meta);
    } else {
      result._metaCreator = constant({});
    }

    let totalWeight = 0.0;

    Object.values(result._variants).forEach((variant) => {
      if (!('weight' in variant)) {
        variant.weight = 1;
      }
      if (variant.weight < 0 || !isFinite(variant.weight)) {
        throw new Error('Invalid weight for variant ' + variant);
      }
      totalWeight += variant.weight;
    });

    Object.values(result._variants).forEach((variant) => {
      variant.probability = variant.weight / totalWeight;
    });

    return result;
  }

  /**
   * Returns the ID of a page that has the given index in the given variant.
   *
   * @param  {number} index  the index of the current page
   * @param  {string} variant  the current variant
   * @return {string|undefined} the ID of the page that has the given index
   *         in the given variant, or undefined if the index is not valid
   *         for the given variant or the variant does not exist
   */
  getPageIdFromIndex(index, variant) {
    const variantData = this._variants[variant];
    return variantData && variantData.items
      ? variantData.items[index]
      : undefined;
  }

  /**
   * Returns the object describing the backmatter of the survey based on the
   * language code. Returns undefined if the survey has no backmatter for the
   * given language.
   */
  getBackmatter(language) {
    return this._backmatterByLanguage[language];
  }

  /**
   * Returns the subtitle of the backmatter page if the current language has a
   * backmatter page.
   *
   * @return {string} the entire title of the survey
   */
  getBackmatterSubtitle(language) {
    return this.getPropertyOfBackmatter(language, 'subtitle');
  }

  /**
   * Returns the title of the backmatter page if the current language has a
   * backmatter page.
   *
   * @return {string} the entire title of the survey
   */
  getBackmatterTitle(language) {
    return this.getPropertyOfBackmatter(language, 'title');
  }

  /**
   * Returns the React component instance to be rendered on the backmatter page
   * in the given language.
   *
   * @param  {string} id  the ID of the page
   * @param  {object} options.data  the data record of the survey
   * @param  {string} options.language  the language of the page
   * @param  {string} options.responses  the responses of the user
   * @return {React.Element|undefined} the element to be rendered on the
   *         page or undefined if there is no backmatter page
   */
  getContentOfBackmatter(options) {
    const { data, language, responses } = options;
    const component = this.getPropertyOfBackmatter(language, 'component');
    if (component === undefined) {
      return undefined;
    } else if (typeof component === 'function') {
      return component({ data, language, responses });
    } else {
      return component;
    }
  }

  /**
   * Returns the React component instance to be rendered on the survey page
   * with the given page ID in the given language.
   *
   * @param  {string} id  the ID of the page
   * @param  {object} options.data  the data record of the survey
   * @param  {string} options.language  the language of the page
   * @param  {string} options.responses  the responses of the user so far
   * @return {React.Element|undefined} the element to be rendered on the
   *         page or undefined if the page does not exist or does not have a
   *         content element
   */
  getContentOfPageById(id, options) {
    const { data, language, responses } = options;
    const component = this.getPropertyOfPageById(id, language, 'component');
    if (component === undefined) {
      return undefined;
    } else if (typeof component === 'function') {
      return component({ data, language, responses });
    } else {
      return component;
    }
  }

  /**
   * Returns an object containing the default responses of the survey for the
   * given survey variant.
   */
  getDefaultResponses(language, variant) {
    const variantData = this._variants[variant];
    const result = {};

    if (variantData) {
      for (const pageId of variantData.items || []) {
        Object.assign(
          result,
          this.getPropertyOfPageById(pageId, language, 'defaults')
        );
      }
    }

    return result;
  }

  /**
   * Returns the function that constructs the data record of the survey. Values
   * in the data record may be accessed by questions so you can use this to
   * provide some random input that stays the same during the same completion
   * of the survey but changes between completions.
   */
  getDataCreator() {
    return this._dataCreator;
  }

  /**
   * Returns the function that constructs the additional metadata to
   * record into the survey object after the completion of the survey.
   *
   * This can be used to lift some of the responses into the metadata so
   * they can be accessed even if the responses are encrypted.
   */
  getMetadataCreator() {
    return this._metaCreator;
  }

  /**
   * Returns the number of pages in the given survey variant.
   *
   * @param  {string} variant  the ID of the variant
   * @return {number} the number of pages in the given variant; zero if the
   *         variant does not exist
   */
  getNumPagesInVariant(variant) {
    return this.getPagesInVariant(variant).length;
  }

  /**
   * Returns the object describing a page in the survey based on the page ID and
   * the language.
   */
  getPageById(id, language) {
    const pages = language ? this._pagesByLanguage[language] : undefined;
    return pages && id ? pages[id] : undefined;
  }

  /**
   * Returns the list of all pages in the given survey variant, in order.
   *
   * @param  {string} variant  the ID of the variant
   * @return {string[]} the IDs of the pages in the given variant; empty
   *         list if the variant does not exist
   */
  getPagesInVariant(variant) {
    const variantData = this._variants[variant];
    return variantData && variantData.items ? [...variantData.items] : [];
  }

  /**
   * Returns a property of the backmatter page in the given language.
   *
   * @param  {string} language  the language of the backmatter
   * @param  {string} property  the name of the property to retrieve
   */
  getPropertyOfBackmatter(language, property) {
    const backmatter = this.getBackmatter(language);
    return backmatter ? backmatter[property] : undefined;
  }

  /**
   * Returns a property of the survey page with the given page ID in the
   * given language.
   *
   * @param  {string} id  the ID of the page
   * @param  {string} language  the language of the page
   * @param  {string} property  the name of the property to retrieve
   */
  getPropertyOfPageById(id, language, property) {
    const page = this.getPageById(id, language);
    return page ? page[property] : undefined;
  }

  /**
   * Returns the subtitle of the entire survey.
   *
   * @return {string} the entire title of the survey
   */
  getSubtitle() {
    return this._subtitle || '';
  }

  /**
   * Returns the subtitle of the survey page with the given page ID in
   * the given language.
   *
   * @param  {string} id  the ID of the page
   * @param  {string} language  the language of the page
   * @return {string|undefined} the subtitle of the page or undefined if the
   *         page does not exist or does not have a subtitle
   */
  getSubtitleOfPageById(id, language) {
    return this.getPropertyOfPageById(id, language, 'subtitle');
  }

  /**
   * Returns an array containing the supported languages of the survey.
   *
   * Each item in the array will be an object with at least three keys: `id` is
   * the code of the language that will be written into the survey metadata,
   * `nativeName` is the name of the language in the language itself, and
   * `englishName` is the name of the language in English.
   *
   * Optionally, a fourth key named `disabled` may be present. If it is present
   * and its value is `true`, the language is temporarily disabled.
   *
   * @return {object[]} the supported languages of the survey
   */
  getSupportedLanguages() {
    return this._languages;
  }

  /**
   * Returns the title of the survey page with the given page ID in the
   * given language.
   *
   * @param  {string} id  the ID of the page
   * @param  {string} language  the language of the page
   * @return {string|undefined} the title of the page or undefined if the
   *         page does not exist or does not have a title
   */
  getTitleOfPageById(id, language) {
    return this.getPropertyOfPageById(id, language, 'title');
  }

  /**
   * Returns the title of the entire survey.
   *
   * @return {string} the entire title of the survey
   */
  getTitle() {
    return this._title || 'Welcome';
  }

  /**
   * Returns the soft validator function of the survey page with the given page ID
   * in the given language.
   *
   * Soft validator functions are executed when the user attempts to navigate to
   * the next page of a survey and may return a message that will be displayed
   * to the user before moving on.
   *
   * @param  {string} id  the ID of the page
   * @param  {string} language  the language of the page
   * @return {function|undefined} the soft validator function of the page or undefined
   *         if the page does not have a soft validator function
   */
  getSoftValidatorOfPageById(id, language) {
    return this.getPropertyOfPageById(id, language, 'softValidator');
  }

  /**
   * Returns the identifiers of all the variants of the survey.
   *
   * @return {string[]}  the IDs of the survey variants
   */
  getVariants() {
    return Object.keys(this._variants);
  }

  /**
   * Returns the weight of the given survey variant, i.e. the probability of
   * generating this variant when starting the survey.
   *
   * @param  {string} variant  the ID of the variant
   * @return {number} the probability of the variant; zero if the variant does
   *         not exist
   */
  getProbabilityOfVariant(variant) {
    const variantData = this._variants[variant];
    return variantData ? variantData.probability : 0.0;
  }

  /**
   * Returns whether the survey has backmatter for the given language.
   */
  hasBackmatter(language) {
    return this.getBackmatter(language) !== undefined;
  }

  /**
   * Returns whether the page with the given ID is visible, given the current
   * survey responses.
   *
   * @param {string} id        the ID of the page
   * @param {object} options.responses the responses that the user has submitted so far
   */
  isPageVisible(id, options) {
    const { language, responses } = options;
    const visible = this.getPropertyOfPageById(id, language, 'visible');
    if (visible) {
      if (typeof visible === 'function') {
        return visible(responses);
      } else {
        return !!visible;
      }
    } else {
      return true;
    }
  }

  /**
   * Returns the number of variants that the survey has.
   */
  get numVariants() {
    return Object.keys(this._variants).length;
  }

  /**
   * Returns whether the survey should automatically be saved after submitting
   * the given page.
   */
  shouldSaveAfterSubmittingPage(id, language) {
    return !!this.getPropertyOfPageById(id, language, 'saveOnSubmit');
  }

  /**
   * Returns whether the responses given by the user to the questions on the
   * given page are valid.
   *
   * @param {string} id        the ID of the page
   * @param {string} options.language  the language of the page
   * @param {object} options.responses the responses that the user has submitted so far
   */
  validatePageById(id, options) {
    const { language, responses } = options;
    const validator = this.getPropertyOfPageById(id, language, 'validator');
    if (validator) {
      return validator(responses);
    } else {
      return true;
    }
  }

  /**
   * Returns whether the survey page with the given ID in the given response
   * will submit the response implicitly when the user selects his/her response.
   */
  willSubmitPageImplicitly(id, language) {
    return !this.getPropertyOfPageById(id, language, 'manualSubmission');
  }
}
