/**
 * @file Synchronization-related action factories.
 */

import { createAction } from 'redux-actions';

import {
  CANCEL_SYNCHRONIZATION,
  DISABLE_AUTOMATIC_SYNC,
  ENABLE_AUTOMATIC_SYNC,
  NOTIFY_SURVEY_SUBMISSION_CANCELLED,
  NOTIFY_SURVEY_SUBMISSION_ERROR,
  NOTIFY_SURVEY_SUBMISSION_STARTED,
  NOTIFY_SURVEY_SUBMISSION_SUCCESSFUL,
  REQUEST_SYNCHRONIZATION,
  SET_ONLINE_STATE,
  SET_SURVEY_SUBMISSION_STATUS,
} from './types';

/**
 * Creates an action that cancels any synchronization processes that might
 * currently be in progress.
 *
 * This action is picked up by a Redux saga that will cancel the synchronizaiton.
 */
export const cancelSynchronization = createAction(CANCEL_SYNCHRONIZATION);

/**
 * Creates an action that disables automatic synchronization in the
 * background.
 */
export const disableAutomaticSync = createAction(DISABLE_AUTOMATIC_SYNC);

/**
 * Creates an action that enables automatic synchronization in the background.
 */
export const enableAutomaticSync = createAction(ENABLE_AUTOMATIC_SYNC);

/**
 * Action thunk that toggles the current state of the automatic
 * synchronization.
 */
export const toggleAutomaticSync = () => (dispatch, getState) => {
  if (getState().sync.automatic) {
    dispatch(disableAutomaticSync());
  } else {
    dispatch(enableAutomaticSync());
  }
};

/**
 * Notifies the store that the submission of the survey with the given
 * ID has been cancelled.
 */
export const notifySurveySubmissionCancelled = createAction(
  NOTIFY_SURVEY_SUBMISSION_CANCELLED,
  (id) => ({ id })
);

/**
 * Notifies the store that the submission of the survey with the given
 * ID has failed.
 */
export const notifySurveySubmissionError = createAction(
  NOTIFY_SURVEY_SUBMISSION_ERROR,
  (id) => ({ id })
);

/**
 * Notifies the store that the submission of the survey with the given
 * ID has started.
 */
export const notifySurveySubmissionStarted = createAction(
  NOTIFY_SURVEY_SUBMISSION_STARTED,
  (id) => ({ id })
);

/**
 * Notifies the store that the submission of the survey with the given
 * ID has finished successfully.
 */
export const notifySurveySubmissionSuccessful = createAction(
  NOTIFY_SURVEY_SUBMISSION_SUCCESSFUL,
  (id) => ({ id })
);

/**
 * Creates an action that requests the pending surveys to be synchronized
 * with the server.
 *
 * This action is picked up by a Redux saga that will start synchronizing
 * individual items.
 *
 * Synchronization can be started in 'auto' and 'manual' mode. The manual
 * mode belongs to explicit user interactions (i.e. the user clicking on
 * a "Synchronize" button), while the automatic mode belongs to a background
 * saga that attempt to sync when automatic synchronization is turned on.
 */
export const requestSynchronization = createAction(
  REQUEST_SYNCHRONIZATION,
  (mode = 'manual') => ({ mode })
);

/**
 * Creates an action that sets whether the application is currently online
 * or offline.
 */
export const setOnlineState = createAction(SET_ONLINE_STATE, (online) => ({
  online,
}));

/**
 * Sets the status of a survey submission explicitly.
 */
export const setSurveySubmissionStatus = createAction(
  SET_SURVEY_SUBMISSION_STATUS,
  (id, status) => ({ id, status })
);
