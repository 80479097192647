/**
 * Actions related to the update mechanism of the application.
 */

import { createAction } from 'redux-actions';

import {
  NOTIFY_CHECKING_FOR_UPDATES,
  NOTIFY_ERROR_WHILE_UPDATING,
  NOTIFY_READY_FOR_OFFLINE_USE,
  NOTIFY_UPDATE_AVAILABLE,
  NOTIFY_UPDATE_DOWNLOADED,
  NOTIFY_UPDATE_DOWNLOADING,
  NOTIFY_UPDATE_INSTALLED,
  REQUEST_CHECK_FOR_UPDATES,
  SET_UPDATE_PROGRESS,
} from './types';

/**
 * Action creator that creates an action that notifies the store that
 * the app is currently checking for updates.
 */
export const notifyCheckingForUpdates = createAction(
  NOTIFY_CHECKING_FOR_UPDATES
);

/**
 * Action creator that creates an action that notifies the store that
 * there was an error while updating the app.
 */
export const notifyErrorWhileUpdating = createAction(
  NOTIFY_ERROR_WHILE_UPDATING
);

/**
 * Action creator that creates an action that notifies the store that the
 * app is ready for offline use.
 */
export const notifyReadyForOfflineUse = createAction(
  NOTIFY_READY_FOR_OFFLINE_USE
);

/**
 * Action creator that creates an action that notifies the store that
 * a new update was detected.
 */
export const notifyUpdateAvailable = createAction(NOTIFY_UPDATE_AVAILABLE);

/**
 * Action creator that creates an action that notifies the store that
 * a new update was detected and is being downloaded.
 */
export const notifyUpdateDownloading = createAction(NOTIFY_UPDATE_DOWNLOADING);

/**
 * Action creator that creates an action that notifies the store that
 * a new update is ready to be installed.
 */
export const notifyUpdateDownloaded = createAction(NOTIFY_UPDATE_DOWNLOADED);

/**
 * Action creator that creates an action that notifies the store that
 * a new update has been installed (or the application has been cached for
 * the first time).
 */
export const notifyUpdateInstalled = createAction(NOTIFY_UPDATE_INSTALLED);

/**
 * Action creator that requests the saga managing the updates to check for
 * updates on the remote server.
 */
export const requestCheckForUpdates = createAction(REQUEST_CHECK_FOR_UPDATES);

/**
 * Action creator that creates an action that updates the progress of the
 * current update download.
 */
export const setUpdateProgress = createAction(
  SET_UPDATE_PROGRESS,
  (progress) => ({ progress })
);
