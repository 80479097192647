/**
 * Actions related to the onboarding process that happens during
 * first installation.
 */

import { createAction } from 'redux-actions';

import {
  FINISH_ONBOARDING,
  ONBOARING_PASSWORD_ENTERED,
  SET_DEVICE_NAME,
} from './types';

import { prompt } from '../components/dialogs';
import config from '../config';

/**
 * Action factory that sets the registered name of the device to a
 * given value.
 */
export const setDeviceName = createAction(SET_DEVICE_NAME, (deviceName) => ({
  deviceName,
}));

/**
 * Action factory that signals that the onboarding process has finished.
 */
export const finishOnboarding = createAction(FINISH_ONBOARDING);

/**
 * Action factory that lets the state store know that the user has entered
 * the valid password to unlock access to the application in general.
 */
export const unlockAccessToApplication = createAction(
  ONBOARING_PASSWORD_ENTERED
);

export const promptOrGenerateDeviceName = async ({
  title,
  message,
  placeholder,
} = {}) => {
  if (typeof config.deviceNameGenerator === 'function') {
    return config.deviceNameGenerator();
  } else {
    return prompt(title, message, placeholder);
  }
};
