import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * React component that shows a circular countdown indicator that counts
 * the number of seconds left till a given deadline.
 */
export class CountdownIndicator extends React.Component {
  render() {
    const {
      color,
      deadline,
      hideWhenExpired,
      maxSeconds,
      reversed,
      style,
      ...rest
    } = this.props;
    const secondsRemaining =
      deadline !== undefined
        ? Math.max(deadline - Date.now(), 0) / 1000
        : Number.POSITIVE_INFINITY;

    this._scheduleNextRender(secondsRemaining, maxSeconds);

    if (secondsRemaining > maxSeconds) {
      return null;
    }

    const fraction = Math.min(
      Math.max(secondsRemaining / maxSeconds, 0.0),
      1.0
    );

    if (hideWhenExpired && fraction <= 0) {
      return null;
    }

    const roundedSecondsRemaining = Math.ceil(secondsRemaining);
    const formattedTime =
      roundedSecondsRemaining >= 60
        ? `${Math.floor(roundedSecondsRemaining / 60).toFixed()}:${(
            roundedSecondsRemaining % 60
          )
            .toFixed()
            .padStart(2, '0')}`
        : roundedSecondsRemaining.toFixed();

    return (
      <div {...rest}>
        <CircularProgress
          variant="static"
          size={40}
          min={0}
          max={1}
          value={reversed ? fraction : 1 - fraction}
          style={{ color: color, position: 'relative', top: 4, left: 4 }}
        />
        <div
          style={{
            color: color,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          {formattedTime}
        </div>
      </div>
    );
  }

  _scheduleNextRender(secondsRemaining, maxSeconds) {
    if (secondsRemaining <= 0) {
      // deadline reached, no need for another render
      return;
    }

    const secondsUntilNextRender =
      secondsRemaining > maxSeconds ? secondsRemaining - maxSeconds : 0.08;
    setTimeout(() => this.forceUpdate(), secondsUntilNextRender * 1000);
  }
}

CountdownIndicator.propTypes = {
  color: PropTypes.string,
  deadline: PropTypes.number,
  hideWhenExpired: PropTypes.bool,
  maxSeconds: PropTypes.number,
  reversed: PropTypes.bool,
  style: PropTypes.object,
};

CountdownIndicator.defaultProps = {
  maxSeconds: 10,
};

export default CountdownIndicator;
