import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import PropTypes from 'prop-types';
import React from 'react';

import { ResponseButtonBar } from '../components';

import './SingleChoicePage.css';
import { useIsExtraSmallScreen } from '../utils/hooks';

/**
 * React component that shows a generic text on a page and then allows
 * the user to pick one from a set of pre-defined responses.
 *
 * The children of the component must contain the question itself.
 * Buttons will be rendered at the bottom.
 */
const SingleChoicePage = ({
  allowOtherResponse,
  children,
  footer,
  getCurrentResponse,
  header,
  headerClassName,
  onSubmit,
  otherResponseDialogTitle,
  otherResponseLabel,
  questionStyle,
  responseKey,
  responses,
  variant,
  vertical,
}) => {
  const isExtraSmallScreen = useIsExtraSmallScreen();
  let body;

  switch (questionStyle) {
    case 'card':
      body = (children || header) && (
        <Card
          raised
          className={clsx({
            SingleChoicePage: true,
            ['SingleChoicePage-' + headerClassName]: !!headerClassName,
          })}
        >
          {header && <header className={headerClassName}>{header}</header>}
          <CardContent>{children}</CardContent>
        </Card>
      );
      break;

    default:
      body = (
        <div
          className={clsx({
            SingleChoicePage: true,
            ['SingleChoicePage-' + questionStyle]: true,
          })}
        >
          {children}
        </div>
      );
  }

  return (
    <React.Fragment>
      {body}
      {footer}
      <ResponseButtonBar
        allowOtherResponse={allowOtherResponse}
        onSubmit={(response) => onSubmit({ [responseKey]: response })}
        otherResponseDialogTitle={otherResponseDialogTitle}
        otherResponseLabel={otherResponseLabel}
        responses={responses}
        value={getCurrentResponse(responseKey)}
        variant={variant}
        vertical={vertical || isExtraSmallScreen}
      />
    </React.Fragment>
  );
};

SingleChoicePage.propTypes = {
  allowOtherResponse: PropTypes.bool,
  getCurrentResponse: PropTypes.func,
  onSubmit: PropTypes.func,
  otherResponseDialogTitle: PropTypes.string,
  otherResponseLabel: PropTypes.string,
  questionStyle: PropTypes.oneOf(['card', 'plain', 'large']),
  responseKey: PropTypes.string,
  responses: PropTypes.array.isRequired,
  variant: PropTypes.oneOf(['dense', 'normal', 'large']),
  vertical: PropTypes.bool,
};

SingleChoicePage.defaultProps = {
  otherResponseLabel: 'Other',
  questionStyle: 'card',
  variant: 'normal',
};

export default SingleChoicePage;
