/**
 * @file Saga that automatically locks the screen after a given number
 * of seconds when it is safe to do so.
 */

import { call, delay, put, race } from 'redux-saga/effects';

import { lockScreen } from '../actions/lock';
import { isScreenLockable } from '../selectors/lock';
import { waitFor, waitWhile } from './helpers';

/**
 * Saga that automatically locks the screen after a given number
 * of seconds when it is safe to do so.
 *
 * @param  {Number}  seconds  the maximum number of seconds the sidebar
 *         is allowed to stay unlocked (but closed) for.
 */
export default function* lockScreenSaga(interval = 300) {
  while (true) {
    yield call(waitFor, isScreenLockable);

    const result = yield race({
      notDangerousAnymore: call(waitWhile, isScreenLockable),
      timeout: delay(interval * 1000),
    });

    if (result.timeout) {
      yield put(lockScreen());
    }
  }
}
