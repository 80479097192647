import Box from '@material-ui/core/Box';

import PropTypes from 'prop-types';
import React from 'react';

const groupBy = (size) => (numbers) => {
  let start = 0;
  let length = numbers.length;
  const result = [];

  while (start < length) {
    result.push(numbers.slice(start, start + size));
    start += size;
  }

  return result;
};

const intersperseWith = (separator) => (array) => {
  const result = [];
  let index = 0;

  for (const item of array) {
    result.push(item);
    if (typeof separator === 'function') {
      result.push(separator(item, index, array));
    } else {
      result.push(separator);
    }
    index++;
  }

  if (result.length > 0) {
    result.pop();
  }

  return result;
};

const splitToRows = groupBy(5);
const joinRows = intersperseWith((_, index) => <br key={index} />);

const NumberBox = ({ children }) => (
  <Box style={{ minWidth: 80, textAlign: 'center' }}>{children}</Box>
);

/**
 * React component that renders a single list of numbers for the CWM numbers
 * variant.
 */
const NumberList = ({ children, ...props }) => (
  <Box display="flex" flexDirection="column" className="x-large" {...props}>
    {children
      ? joinRows(
          splitToRows(children).map((row, rowIndex) => (
            <Box
              key={rowIndex}
              display="flex"
              flexDirection="row"
              flexWrap="wrap"
              justifyContent="center"
            >
              {row.map((number, index) => (
                <NumberBox key={index}>{number}</NumberBox>
              ))}
            </Box>
          ))
        )
      : 'ERROR'}
  </Box>
);

NumberList.propTypes = {
  children: PropTypes.arrayOf(PropTypes.number),
};

export default NumberList;
