import clsx from 'clsx';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import React from 'react';

import './TextFieldPage.css';

/**
 * React component that shows a generic text on a page, extended with a text box
 * that the user can use to enter a free-text response.
 */
const TextFieldPage = ({
  centered,
  getCurrentResponse,
  instructions,
  onFinish,
  onSubmit,
  onUpdate,
  responseKey,
  prefix,
  size,
  suffix,
  ...rest
}) => {
  const inputProps = {};

  if (prefix) {
    inputProps.startAdornment = (
      <InputAdornment position="start">{prefix}</InputAdornment>
    );
  }

  if (suffix) {
    inputProps.endAdornment = (
      <InputAdornment position="end">{suffix}</InputAdornment>
    );
  }

  const textField = (
    <TextField
      className={clsx({
        large: size === 'large',
        small: size === 'small',
        centered,
      })}
      value={getCurrentResponse(responseKey)}
      variant="outlined"
      size={size === 'large' ? 'medium' : size}
      InputProps={inputProps}
      {...rest}
      autoComplete="disabled"
      onChange={(event) =>
        onUpdate({ [responseKey]: event.target.value }, { log: false })
      }
    />
  );

  if (instructions) {
    return (
      <>
        {instructions}
        {textField}
      </>
    );
  } else {
    return textField;
  }
};

TextFieldPage.propTypes = {
  centered: PropTypes.bool,
  instructions: PropTypes.node,
  getCurrentResponse: PropTypes.func,
  onSubmit: PropTypes.func,
  onUpdate: PropTypes.func,
  prefix: PropTypes.string,
  responseKey: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  suffix: PropTypes.string,
};

TextFieldPage.defaultProps = {
  size: 'medium',
};

export default TextFieldPage;
