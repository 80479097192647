import React from 'react';
import { connect } from 'react-redux';

import {
  restartSurvey,
  navigateToNextSurveyPage,
  updateResponse,
} from '../actions/surveys';

import './SurveyPage.css';

/**
 * Generic survey page component that provides common logic to all survey
 * pages.
 */
const SurveyPage = ({ content }) => content || <noscript />;

export default connect(
  // mapStateToProps
  (state, ownProps) => {
    const { meta, responses } = state.surveys.current;
    const { language } = meta;
    const { content } = ownProps;

    return {
      content,
      language,
      getCurrentResponse: (key) => responses[key],
    };
  },

  // mapDispatchToProps
  (dispatch, ownProps) => ({
    onCancelSurvey: () => {
      dispatch(restartSurvey());
    },
    onSubmitResponse: (response) => {
      const { onSubmit, survey } = ownProps;
      dispatch(updateResponse(response || {}));
      dispatch(navigateToNextSurveyPage(survey));
      if (onSubmit) {
        onSubmit();
      }
    },
    onUpdateResponse: (response, options) => {
      dispatch(updateResponse(response || {}, options || {}));
    },
  }),

  // mergeProps
  (stateProps, dispatchProps, ownProps) => {
    const props = {
      ...ownProps,
      ...stateProps,
      ...dispatchProps,
    };

    // For the 'content' prop, we need to clone the React element given
    // there and inject some of the actions into the content element as
    // well to make it possible for the page content to submit responses.
    props.content = props.content
      ? React.cloneElement(props.content, {
          getCurrentResponse: props.getCurrentResponse,
          language: props.language,
          onCancel: props.onCancelSurvey,
          onFinish: props.onFinishSurvey,
          onSubmit: props.onSubmitResponse,
          onUpdate: props.onUpdateResponse,
        })
      : null;

    return props;
  }
)(SurveyPage);
