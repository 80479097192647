/**
 * @file Part of the state object that holds the current survey being
 * recorded.
 */

import mapValues from 'lodash-es/mapValues';
import { handleActions } from 'redux-actions';
import u from 'updeep';

import config from '../../config';

/**
 * Default state of the current survey being recorded.
 */
const defaultState = {
  currentPage: -1,
  log: [],
  maxPageReached: -1,
  numPages: -1,
  meta: {
    cancelled: false,
    language: undefined,
    mode: config.primarySurveyMode,
    startedAt: undefined,
    duration: undefined,
    variant: undefined,
  },
  responses: {},
};

/**
 * Reducer that handles actions related to changing the state of the sidebar.
 */
const reducer = handleActions(
  {
    FINISH_SURVEY: (state, { payload }) =>
      u(
        {
          meta: u({
            ...mapValues(payload.extraMetadata || {}, u.constant),
            duration: state.meta.startedAt
              ? Math.max(0, payload.time - state.meta.startedAt)
              : 0,
            cancelled: payload.hasOwnProperty('cancelled')
              ? !!payload.cancelled
              : false,
          }),
          log: [...state.log, [payload.time - state.meta.startedAt, 'finish']],
        },
        state
      ),

    RESTART_SURVEY: (state) => ({
      currentPage: -1,
      maxPageReached: -1,
      numPages: -1,
      log: [],
      meta: {
        cancelled: false,
        language: undefined,
        mode: state.meta.mode,
        startedAt: undefined,
        duration: undefined,
        variant: undefined,
      },
      responses: {},
    }),

    SET_CURRENT_SURVEY_MODE: (state, { payload }) =>
      u.updateIn('meta.mode', payload.mode, state),

    SET_CURRENT_SURVEY_PAGE: (state, { payload }) => {
      const { index, time } = payload;
      const { currentPage, maxPageReached, meta } = state;
      const newLogs = [];
      const newPage = index < 0 ? currentPage : index;

      if (newPage !== currentPage) {
        newLogs.push([time - meta.startedAt, 'navigate', newPage]);
      }

      return u(
        {
          currentPage: newPage,
          log: [...state.log, ...newLogs],
          maxPageReached: Math.max(maxPageReached, newPage),
        },
        state
      );
    },

    SHOW_BACKMATTER: (state, { payload }) =>
      u(
        {
          currentPage: 'backmatter',
        },
        state
      ),

    START_NEW_SURVEY: (state, { payload }) => ({
      currentPage: 0,
      data: { ...payload.data },
      maxPageReached: 0,
      numPages: payload.numPages,
      log: [[0, 'start']],
      meta: {
        cancelled: false,
        language: payload.language,
        mode: state.meta.mode,
        startedAt: payload.time,
        duration: undefined,
        variant: payload.variant,
      },
      responses: { ...payload.defaultResponses },
    }),

    UPDATE_RESPONSE: (state, { payload }) => {
      const { log, meta } = state;
      const { options, responses, time } = payload;
      const newLogs = [];
      const newResponseKeys = Object.keys(responses);
      const effectiveOptions = {
        log: true,
        ...options,
      };

      if (
        effectiveOptions.log &&
        newResponseKeys &&
        newResponseKeys.length > 0
      ) {
        newLogs.push([time - meta.startedAt, 'response', ...newResponseKeys]);
      }

      return u(
        {
          log: [...log, ...newLogs],
          responses: responses,
        },
        state
      );
    },
  },
  defaultState
);

export default reducer;
