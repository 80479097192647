import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Back from '@material-ui/icons/ArrowBackIos';
import Forward from '@material-ui/icons/ArrowForwardIos';
import PropTypes from 'prop-types';
import React from 'react';

import './IntroductionPage.css';

/**
 * React component that shows a generic text on a page and provides a button
 * with which the user can acknowledge the text and move on to the next
 * page.
 */
const IntroductionPage = ({
  cancelText,
  children,
  onFinish,
  onSubmit,
  responseKey,
  submitText,
}) => (
  <Card raised className="IntroductionPage">
    <CardContent>{children}</CardContent>
    <CardActions style={{ justifyContent: 'center' }}>
      <Button
        startIcon={<Back />}
        onClick={() =>
          onFinish(
            responseKey
              ? {
                  [responseKey]: false,
                }
              : {}
          )
        }
        color="secondary"
      >
        {cancelText}
      </Button>
      <Button
        endIcon={<Forward />}
        onClick={() =>
          onSubmit(
            responseKey
              ? {
                  [responseKey]: true,
                }
              : {}
          )
        }
        color="primary"
      >
        {submitText}
      </Button>
    </CardActions>
  </Card>
);

IntroductionPage.propTypes = {
  cancelText: PropTypes.string,
  onFinish: PropTypes.func,
  onSubmit: PropTypes.func,
  responseKey: PropTypes.string.isRequired,
  submitText: PropTypes.string,
};

IntroductionPage.defaultProps = {
  cancelText: 'Cancel Survey',
  submitText: 'Next Page',
};

export default IntroductionPage;
