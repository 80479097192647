import { DateTime, Duration } from 'luxon';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import BeachAccess from '@material-ui/icons/BeachAccess';
import BugReport from '@material-ui/icons/BugReport';
import Edit from '@material-ui/icons/Edit';
import Laptop from '@material-ui/icons/Laptop';
import Tablet from '@material-ui/icons/TabletAndroid';
import Warning from '@material-ui/icons/Warning';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { TransitionGroup } from 'react-transition-group';

import { SwipeableItem } from './swipeable';

import { RemoveButton, SubmitAgainButton } from './buttons';

import {
  markSurveyAsUnsubmittedById,
  removeSurveyById,
} from '../actions/surveys';
import config from '../config';
import { SurveyMode, SynchronizationStatus } from '../model';
import { Slide } from '../transitions';
import { fromMillis } from '../utils';

import './SurveyList.css';

/**
 * Formats the start time of the given survey.
 *
 * Note that the time part is never included in the formatted value, only the
 * start date. This is to protect the anonymity of the respondents.
 */
const formatFinishTime = ({ meta }) => {
  let time = meta.startedAt || 0;
  if (time <= 0) {
    return 'unknown date';
  } else {
    return fromMillis(time, 'local').toLocaleString(DateTime.DATETIME_FULL);
  }
};

/**
 * Formats the duration of the given survey.
 */
const formatDuration = ({ meta }) => {
  let duration = meta.duration || 0;
  if (duration <= 0) {
    return 'duration unknown';
  } else {
    duration = Duration.fromMillis(duration).shiftTo('seconds', 'milliseconds');
    return 'took ' + duration.toFormat('s.SSS') + ' s';
  }
};

/**
 * Creates an icon for the given survey item.
 */
const iconForItem = ({ meta }) => {
  let Icon;

  const { cancelled, mode } = meta || {};
  const consent = true;

  switch (mode) {
    case SurveyMode.TOUCHSCREEN:
      Icon = Tablet;
      break;

    case SurveyMode.PAPER_AND_PENCIL:
      Icon = Edit;
      break;

    case SurveyMode.BROWSER:
      Icon = Laptop;
      break;

    default:
      Icon = BugReport;
  }

  const className =
    consent && cancelled
      ? 'consentGivenButCancelled'
      : consent
      ? 'consentGiven'
      : 'consentNotGiven';

  return (
    <ListItemIcon>
      <Icon className={className} />
    </ListItemIcon>
  );
};

/**
 * Returns whether the given item is cancelled due to inactivity.
 */
const itemIsInactive = ({ meta }) =>
  meta.cancelled && meta.cancellationReason === 'inactive';

/**
 * Returns whether the given item should show a warning icon.
 */
const itemHasError = ({ meta }) =>
  !meta.cancelled &&
  (meta.duration < 0 ||
    meta.duration <= config.responseTimeThresholds.min * 1000 ||
    meta.duration >= config.responseTimeThresholds.max * 1000);

/**
 * Creates a primary and a secondary label for the given survey item.
 */
const labelsForItem = ({ meta }) =>
  meta
    ? {
        primary:
          `Variant ${meta.variant}` +
          (meta.sport ? `; ${meta.sport}` : '') +
          (meta.language ? `; language: ${meta.language}` : ''),
        secondary: `${formatFinishTime({
          meta,
        })} (${formatDuration({ meta })})`,
      }
    : {
        primary: 'Survey item with no metadata',
      };

/**
 * Warning icon to show next to list items if the survey was cancelled due to
 * inactivity.
 */
const sunbedIcon = (
  <ListItemSecondaryAction>
    <IconButton>
      <BeachAccess style={{ color: '#888' }} />
    </IconButton>
  </ListItemSecondaryAction>
);

/**
 * Warning icon to show next to list items if the response duration is
 * undefined or suspiciously low.
 */
const warningIcon = (
  <ListItemSecondaryAction>
    <IconButton>
      <Warning style={{ color: '#ffcc00' }} />
    </IconButton>
  </ListItemSecondaryAction>
);

/**
 * Default list item renderer for surveys.
 */
const renderListItem = (item, { onSubmitAgain, onRemove }) => (
  <SwipeableItem
    leftButtons={
      onSubmitAgain ? [<SubmitAgainButton backgroundColor="#2196f3" />] : []
    }
    rightButtons={onRemove ? [<RemoveButton backgroundColor="#ff0000" />] : []}
    onLeftAction={onSubmitAgain ? () => onSubmitAgain(item.id) : undefined}
    onRightAction={onRemove ? () => onRemove(item.id) : undefined}
  >
    <ListItem key={item.id}>
      <ListItemIcon>{iconForItem(item)}</ListItemIcon>
      <ListItemText {...labelsForItem(item)} />
      {SynchronizationStatus.isBeingSynchronized(item.status) && (
        <CircularProgress variant="indeterminate" />
      )}
      {itemIsInactive(item)
        ? sunbedIcon
        : itemHasError(item)
        ? warningIcon
        : null}
    </ListItem>
  </SwipeableItem>
);

/**
 * React component that renders summaries of multiple recorded survey
 * items in a list.
 */
const SurveyList = ({
  canRemove,
  canSubmitAgain,
  children,
  items,
  onRemove,
  onSubmitAgain,
  selector,
  ...rest
}) =>
  items && items.length ? (
    <List className="SurveyList" {...rest}>
      <TransitionGroup>
        {items.map((item) => (
          <Slide direction="left" key={item.id}>
            {(children || renderListItem)(item, {
              onRemove: canRemove ? onRemove : undefined,
              onSubmitAgain: canSubmitAgain ? onSubmitAgain : undefined,
            })}
          </Slide>
        ))}
      </TransitionGroup>
    </List>
  ) : (
    <Typography align="center" color="textSecondary" style={{ margin: 'auto' }}>
      No surveys match the selected filter.
    </Typography>
  );

SurveyList.propTypes = {
  ...List.propTypes,
  canRemove: PropTypes.bool,
  canSubmitAgain: PropTypes.bool,
  children: PropTypes.func,
  items: PropTypes.array.isRequired,
  onRemove: PropTypes.func,
  onSubmitAgain: PropTypes.func,
};

export default connect(
  // mapStateToProps
  (state, ownProps) => ({
    items: ownProps
      .selector(state)
      .map((surveyId) => state.surveys.stored.byId[surveyId]),
    style: {
      overflow: 'auto',
      WebkitOverflowScrolling: 'touch',
    },
  }),
  // mapDispatchToProps
  (dispatch, ownProps) => ({
    onRemove: (surveyId) => {
      dispatch(removeSurveyById(surveyId));
    },
    onSubmitAgain: (surveyId) => {
      dispatch(markSurveyAsUnsubmittedById(surveyId));
    },
  })
)(SurveyList);

SurveyList.propTypes = {
  canRemove: PropTypes.bool,
  canSubmitAgain: PropTypes.bool,
  selector: PropTypes.func.isRequired,
};

SurveyList.defaultProps = {
  canRemove: true,
  canSubmitAgain: true,
};
