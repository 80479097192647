import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import find from 'lodash-es/find';
import { matchSorter } from 'match-sorter';
import React, { useMemo } from 'react';
import { useIsExtraSmallScreen } from '../utils/hooks';

import { localize } from '../utils/localization';

const localizationData = {
  en: {
    label: 'Choose your sport',
    helperText: 'Please click on the outlined area to select your sport',
  },
  fr: {
    label: 'Choisissez votre sports',
    helperText:
      'Veuillez cliquer sur la zone délimitée pour sélectionner votre sport',
  },
  es: {
    label: 'Elige tu deporte',
    helperText:
      'Por favor, haz clic en el área marcada para seleccionar tu deporte',
  },
};

const filterOptions = (options, { inputValue }) =>
  matchSorter(options, inputValue, { keys: ['name'] });

/**
 * Extracts the name of a sport from the data object received in the
 * sport selector.
 */
const sportToName = (sport) => sport.name;

/**
 * Converts a sport to the sport code that we will enter in the response.
 */
const sportToValue = (sport) => sport.code;

/**
 * Render function that renders the main textbox of the country selector.
 */
const renderInput = (localize) => ({ inputProps, ...props }) => (
  <TextField
    {...props}
    label={localize('label')}
    helperText={localize('helperText')}
    variant="outlined"
    fullWidth
    inputProps={{
      ...inputProps,
      autoComplete: 'disabled',
    }}
  />
);

/**
 * Render function that renders a single item in the autocompletion dropdown
 * of the country selector.
 */
const renderOption = (sport) => sportToName(sport);

/**
 * List of sports supported by the selector.
 */
const sportsByLanguage = require('../assets/sports.json');

const createLookupSportByCode = (language) => {
  const sports = sportsByLanguage[language] || [];
  return (code) => find(sports, ['code', code]);
};

/**
 * React component that allows the user to select a sport from a list of
 * sports.
 */
const SportSelector = ({ language, onChange, value }) => {
  const isExtraSmallScreen = useIsExtraSmallScreen();

  const inputRenderer = useMemo(
    () => renderInput(localize(localizationData, language)),
    [language]
  );

  const sports = sportsByLanguage[language];

  const lookupSportByCode = useMemo(() => createLookupSportByCode(language), [
    language,
  ]);

  const selectedSport = useMemo(() => lookupSportByCode(value), [
    lookupSportByCode,
    value,
  ]);

  return (
    <Autocomplete
      autoComplete
      disableClearable
      options={sports}
      onChange={(_event, value) => onChange(sportToValue(value))}
      style={isExtraSmallScreen ? { padding: 8 } : { width: 640 }}
      filterOptions={filterOptions}
      getOptionLabel={sportToName}
      renderInput={inputRenderer}
      renderOption={renderOption}
      value={selectedSport}
    />
  );
};

export default SportSelector;
