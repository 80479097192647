import React, { useCallback } from 'react';

import { CountrySelector } from '../components';

/**
 * React component that shows an autocompletion textbox from which the
 * respondent can select a country.
 */
const SelectCountryPage = ({
  getCurrentResponse,
  language,
  responseKey,
  onSubmit,
}) => {
  const onChange = useCallback((value) => onSubmit({ [responseKey]: value }), [
    onSubmit,
    responseKey,
  ]);
  return (
    <CountrySelector
      language={language}
      onChange={onChange}
      value={getCurrentResponse(responseKey)}
    />
  );
};

export default SelectCountryPage;
