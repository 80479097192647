/**
 * Selector that returns the progress of the current update as a
 * number between 0 and 1.
 */
export const getUpdateProgress = (state) => state.ui.updates.progress;

/**
 * Selector that returns whether there are updates available.
 */
export const hasUpdateAvailable = (state) => state.ui.updates.available;

/**
 * Selector that returns whether the application is cached for offline use.
 */
export const isCachedForOfflineUse = (state) =>
  state.ui.updates.cachedForOfflineUse;

/**
 * Selector that returns whether the application is _not_ cached for offline use.
 */
export const isNotCachedForOfflineUse = (state) =>
  !isCachedForOfflineUse(state) &&
  state.ui.updates.cachedForOfflineUse !== undefined;

/**
 * Selector that returns whether we are currently checking for updates.
 */
export const isCheckingForUpdates = (state) => state.ui.updates.checking;

/**
 * Selector that returns whether an update is currently being downloaded.
 */
export const isUpdateBeingDownloaded = (state) => state.ui.updates.downloading;

/**
 * Selector that returns whether there was an error while downloading
 * updates.
 */
export const wasErrorWhileUpdating = (state) => state.ui.updates.error;
