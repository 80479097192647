import get from 'lodash-es/get';
import { call, put, select } from 'redux-saga/effects';

import {
  finishOnboarding,
  promptOrGenerateDeviceName,
  setDeviceName,
  unlockAccessToApplication,
} from '../actions/onboarding';
import { promptAndValidateSidebarPassword } from '../actions/sidebar';
import config from '../config';
import { SurveyMode } from '../model';
import { getOnboardingStatus } from '../selectors/onboarding';

/**
 * Saga that implements the onboarding process of the application.
 *
 * This saga is started when the app is loaded. First, it will wait
 * until the state of the store is restored from the backing storage;
 * then it checks whether the onboarding process has finished already.
 * If it did not finish yet, it will open a prompt dialog where the
 * user can enter the name of the device, and then dispatches an event
 * that finishes the onboarding process.
 */
export default function* onboardingSaga() {
  let state;

  state = yield select(getOnboardingStatus);
  if (state === 'completed') {
    return;
  }

  while (state !== 'completed') {
    if (state === 'notStarted') {
      let needsPasswordToUnlock =
        get(config, 'primarySurveyMode') !== SurveyMode.BROWSER;
      let canUnlock = !needsPasswordToUnlock;

      if (needsPasswordToUnlock) {
        // App is running in touchscreen node; needs a sysadmin to unlock the
        // app and permit installation
        const { passwordValid } = yield call(
          promptAndValidateSidebarPassword,
          /* locked = */ true,
          {
            title: 'Unlock the application',
            message:
              'Please enter the password required to unlock access to this ' +
              'application.',
          }
        );
        canUnlock = passwordValid;
      } else {
        canUnlock = true;
      }

      if (canUnlock) {
        yield put(unlockAccessToApplication());
      }
    } else if (state === 'passwordValid') {
      // Ask for the device name and then finish onboarding
      const deviceName = yield call(promptOrGenerateDeviceName, {
        title: 'Device setup',
        message:
          'Please enter the name of this device. Surveys submitted from ' +
          'this device will be identified by this name.',
        placeholder: 'E.g., "iPad 1"',
      });

      yield put(setDeviceName(deviceName));
      yield put(finishOnboarding());
    }

    state = yield select(getOnboardingStatus);
  }
}
