/**
 * @file Exports a reducer that combines all dialog-related reducers.
 */

import { combineReducers } from 'redux';

import admin from './admin';
import inactivity from './inactivity';

export default combineReducers({
  admin,
  inactivity,
});
