import React from 'react';

const defaultStyle = {
  height: '100%',
  textAlign: 'center',
  width: '100%',
};

export const SwipeableItemButton = ({
  backgroundColor,
  color,
  icon,
  label,
  style,
}) => {
  const effectiveStyle = {
    ...defaultStyle,
    ...style,
    backgroundColor,
    color: color || 'white',
  };
  return (
    <div style={effectiveStyle}>
      <div className="switem-button-content">
        {icon ? <div className="switem-button-icon">{icon}</div> : null}
        {label}
      </div>
    </div>
  );
};

SwipeableItemButton.defaultProps = {
  backgroundColor: '#4488ff',
};
