/**
 * @file Part of the state object that holds the statistics of submitted
 * surveys, fetched directly from the server.
 */

import { handleActions } from 'redux-actions';
import u from 'updeep';

/**
 * Default state of the survey statistics.
 */
const defaultState = {
  loaded: false,
  loading: false,
  error: null,
  result: null,
};

/**
 * Reducer that handles actions related to loading the statistics of the
 * submitted surveys from the server.
 */
const reducer = handleActions(
  {
    STARTED_LOADING_SURVEY_STATISTICS: u({
      loaded: false,
      loading: true,
      error: null,
      result: null,
    }),

    FINISHED_LOADING_SURVEY_STATISTICS: (state, { payload }) =>
      u(
        {
          loaded: true,
          loading: false,
          error: null,
          result: payload,
        },
        state
      ),

    SURVEY_STATISTICS_LOADING_ERROR: (state, { payload }) =>
      u(
        {
          loaded: false,
          loading: false,
          error: payload,
          result: null,
        },
        state
      ),
  },
  defaultState
);

export default reducer;
