import { Online } from 'react-detect-offline';
import { connect } from 'react-redux';

import { setOnlineState } from '../actions/sync';

/**
 * React component that tracks whether the application is currently online
 * or offline.
 */
export default connect(
  // mapStateToProps
  (state) => ({
    // turn off polling -- all modern browsers now support navigator.onLine and
    // the window.online and window.offline events.
    polling: false,
  }),
  // mapDispatchToProps
  (dispatch) => ({
    onChange: (newState) => {
      dispatch(setOnlineState(newState));
    },
  })
)(Online);
