/**
 * @file Saga that responds to "check for update" actions and checks on the
 * server whether there is an updated version available.
 */

import { call, cancel, select, take } from 'redux-saga/effects';

import { REQUEST_CHECK_FOR_UPDATES, SET_ONLINE_STATE } from '../actions/types';
import { isApplicationOnline } from '../selectors/sync';

/**
 * Forces the app-cache mechanism to check for updates.
 */
function checkForUpdates() {
  if ('serviceWorker' in navigator) {
    // Service workers are forced to update simply by reloading the page
    window.location.reload();
  } else if (window.applicationCache) {
    if (window.applicationCache.status !== 0) {
      window.applicationCache.update();
    } else {
      console.warn(
        'Application is not prepared for offline mode (status = ' +
          window.applicationCache.status +
          ')'
      );
    }
  } else {
    console.warn('Application cache not supported');
  }
}

/**
 * Saga that responds to "check for update" actions and checks on the
 * server whether there is an updated version available.
 *
 * The saga also checks for updates automatically when the device comes
 * online.
 */
export default function* updaterSaga() {
  let lastTask;

  while (true) {
    yield take([REQUEST_CHECK_FOR_UPDATES, SET_ONLINE_STATE]);
    if (lastTask) {
      yield cancel(lastTask);
      lastTask = undefined;
    }

    const isOnline = yield select(isApplicationOnline);
    if (isOnline) {
      lastTask = yield call(checkForUpdates);
    }
  }
}
