import React from 'react';
import './NavigationProgressDots.css';

import CheckCircle from '@material-ui/icons/CheckCircle';
import RadioButtonChecked from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUnchecked from '@material-ui/icons/RadioButtonUnchecked';

import range from 'lodash-es/range';
import { connect } from 'react-redux';

import {
  getSurveyProgressAsFraction,
  isSurveyInProgress,
} from '../../selectors/surveys';

const NavigationProgressDots = ({
  currentPageIndex,
  isSurveyInProgress,
  numPages,
}) =>
  isSurveyInProgress ? (
    <div className="NavigationProgressDots">
      {range(numPages).map((index) =>
        currentPageIndex > index ? (
          <CheckCircle key={index} className="done" fontSize="small" />
        ) : currentPageIndex === index ? (
          <RadioButtonChecked
            key={index}
            className="current"
            fontSize="small"
          />
        ) : (
          <RadioButtonUnchecked
            key={index}
            className="upcoming"
            fontSize="small"
          />
        )
      )}
    </div>
  ) : null;

export default connect((state) => ({
  currentPageIndex: getSurveyProgressAsFraction(state)[0],
  isSurveyInProgress: isSurveyInProgress(state),
  numPages: getSurveyProgressAsFraction(state)[1],
}))(NavigationProgressDots);
