/**
 * Enum containing constants describing the supported surveying modes.
 */
export const SurveyMode = {
  BROWSER: 'browser',
  PAPER_AND_PENCIL: 'paperAndPencil',
  TOUCHSCREEN: 'touchscreen',
};

const descriptions = {
  [SurveyMode.PAPER_AND_PENCIL]: 'You are now in paper-and-pencil mode',

  // No description for browser or touchscreen mode; this is intentional as we
  // don't want these to show banners on the UI.
};

/**
 * Function that maps a survey mode to a human-readable description.
 */
export function describeMode(mode) {
  return descriptions[mode];
}

export default SurveyMode;
