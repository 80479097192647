import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Switch from '@material-ui/core/Switch';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { toggleAutomaticSync } from '../../actions/sync';
import { isAutomaticSyncEnabled } from '../../selectors/sync';

const AutoSyncSwitch = ({ enabled, onToggle }) => (
  <ListItem button onClick={onToggle}>
    <ListItemText inset primary="Submit automatically" />
    <ListItemSecondaryAction>
      <Switch checked={enabled} onChange={onToggle} />
    </ListItemSecondaryAction>
  </ListItem>
);

AutoSyncSwitch.propTypes = {
  enabled: PropTypes.bool,
  onToggle: PropTypes.func,
};

export default connect(
  // mapStateToProps
  (state) => ({
    enabled: isAutomaticSyncEnabled(state),
  }),
  // mapDispatchToProps
  (dispatch) => ({
    onToggle: () => {
      dispatch(toggleAutomaticSync());
    },
  })
)(AutoSyncSwitch);
