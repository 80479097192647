import introPage from './intro.md';

import cwmInstructionsPage from './cwm-instructions.md';

import cwmDopingPage from './cwm-doping.md';
import cwmLicensePage from './cwm-license.md';
import cwmMedicinePage from './cwm-medicine.md';
import cwmSupplementsPage from './cwm-supplements.md';
import cwmUrineOrBloodSamplePage from './cwm-urine-or-blood-sample.md';

const messages = require('./messages.yaml');

export default {
  introPage,
  messages,
  cwmPages: {
    instructions: cwmInstructionsPage,

    cwmDoping: cwmDopingPage,
    cwmLicense: cwmLicensePage,
    cwmMedicine: cwmMedicinePage,
    cwmSupplements: cwmSupplementsPage,
    cwmUrineOrBloodSample: cwmUrineOrBloodSamplePage,
  },
};
