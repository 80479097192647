import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import url from 'url';

import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';

import { startNewSurvey } from '../actions/surveys';
import { useIsExtraSmallScreen } from '../utils/hooks';

import './LanguageSelector.css';

const webRoot = process.env.PUBLIC_URL;

/**
 * Returns the in-app URL of the image for the given language.
 *
 * @param  {object} language object that describes the language
 * @param  {string} language.image URL of the image representing the language,
 *         relative to the public root of the application
 * @param  {string} language.id short code representing a file in the
 *         `/img/flags` URL of the app (alternative to a full image URL)
 * @return {string} the image URL, falling back to a generic image if needed
 */
const getImageUrlForLanguage = (language) =>
  language.image
    ? url.resolve(webRoot, language.image)
    : url.resolve(webRoot, `img/flags/${language.id}.png`);

/**
 * Renders a single tile in the language grid.
 *
 * @param  {object} language object that describes the language
 * @param  {string} language.id  unique identifier of the language being rendered
 * @param  {string} language.nativeName  name of the language in the language
 *         itself
 * @param  {string} language.englishName  name of the language in English
 * @param  {function} onSubmit  function to call without arguments when the
 *         user clicks on the tile
 * @return {React.Element} the rendered tile
 */
const renderLanguageTile = (language, onSubmit) => (
  <ImageListItem
    key={language.id}
    onClick={language.disabled ? undefined : onSubmit}
    className={clsx(
      'LanguageTile',
      language.disabled && 'LanguageTileDisabled'
    )}
  >
    <img src={getImageUrlForLanguage(language)} alt={language.nativeName} />
    <ImageListItemBar
      title={language.nativeName}
      subtitle={language.englishName}
      className="titleContainer"
      classes={{ title: 'title', subtitle: 'subtitle' }}
      disabled={!!language.disabled}
    />
  </ImageListItem>
);

/**
 * React component that allows the user to pick a language from the list
 * of languages supported by a survey.
 */
const LanguageSelector = ({ languages, onStart }) => {
  const isExtraSmallScreen = useIsExtraSmallScreen();
  return (
    <ImageList
      rowHeight={isExtraSmallScreen ? 125 : 250}
      className="LanguageSelector"
      cols={isExtraSmallScreen ? 1 : Math.min(languages.length, 2)}
      gap={5}
      style={{
        padding: '0 5px',
      }}
    >
      {languages.map((language) =>
        renderLanguageTile(language, () => onStart(language.id))
      )}
    </ImageList>
  );
};

LanguageSelector.propTypes = {
  languages: PropTypes.array,
  onStart: PropTypes.func,
};

export default connect(
  // mapStateToProps
  (state, ownProps) => ({
    languages: ownProps.survey ? ownProps.survey.getSupportedLanguages() : [],
  }),

  // mapDispatchToProps
  (dispatch, ownProps) => ({
    onStart: (language) => {
      const { survey } = ownProps;
      if (survey) {
        dispatch(startNewSurvey(survey, language));
      }
    },
  })
)(LanguageSelector);
