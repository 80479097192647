import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

import defer from 'p-defer';
import React from 'react';
import ReactDOM from 'react-dom';

// Helper React Components {{
class DialogWrapper extends React.Component {
  constructor() {
    super();
    this.state = { open: true };
  }

  close() {
    this.setState({ open: false });
  }

  render() {
    const { children, ...rest } = this.props;
    return (
      <Dialog {...rest} open={this.state.open}>
        {children}
      </Dialog>
    );
  }
}
// }}

function show(dialogClass) {
  const deferred = defer();
  const div = document.createElement('div');
  document.body.appendChild(div);
  function cleanup() {
    ReactDOM.unmountComponentAtNode(div);
    document.body.removeChild(div);
  }

  async function setDialog(dlg) {
    if (!dlg) {
      return;
    }

    let result;

    try {
      result = await dlg.promise;
    } catch (e) {
      setTimeout(() => cleanup(), 2000);
      deferred.reject(e);
    }

    setTimeout(() => cleanup(), 2000);

    deferred.resolve(result);
  }

  ReactDOM.render(React.createElement(dialogClass, { ref: setDialog }), div);

  return deferred.promise;
}

function alert(title, message) {
  if (typeof message === 'undefined') {
    message = title;
    title = 'Alert';
  }

  class DialogContainer extends React.Component {
    constructor() {
      super();
      this._dialog = null;
      this.deferred = defer();
      this.promise = this.deferred.promise;

      this._setDialogRef = this._setDialogRef.bind(this);
    }

    _setDialogRef(value) {
      this._dialog = value;
    }

    render() {
      return (
        <DialogWrapper ref={this._setDialogRef}>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            <DialogContentText>{message}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                this.deferred.resolve();
                this._dialog.close();
              }}
            >
              OK
            </Button>
          </DialogActions>
        </DialogWrapper>
      );
    }
  }

  return show(DialogContainer);
}

function confirm(title, message, options = {}) {
  if (message === undefined) {
    message = title;
    title = 'Confirm';
  }

  class DialogContainer extends React.Component {
    constructor() {
      super();
      this._dialog = null;
      this.deferred = defer();
      this.promise = this.deferred.promise;

      this._setDialogRef = this._setDialogRef.bind(this);
    }

    _setDialogRef(value) {
      this._dialog = value;
    }

    render() {
      return (
        <DialogWrapper ref={this._setDialogRef}>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            <div>{message}</div>
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              onClick={() => {
                this.deferred.resolve(false);
                this._dialog.close();
              }}
            >
              {options['noLabel'] || 'No'}
            </Button>
            <Button
              color="primary"
              onClick={() => {
                this.deferred.resolve(true);
                this._dialog.close();
              }}
            >
              {options['yesLabel'] || 'Yes'}
            </Button>
          </DialogActions>
        </DialogWrapper>
      );
    }
  }
  return show(DialogContainer);
}

function prompt(title, message, options) {
  const opts = {
    password: false,
    defaultValue: '',
    ...options,
  };

  if (arguments.length === 1) {
    message = title;
    title = 'Prompt';
  }

  const defaultValue = opts.defaultValue || '';

  class DialogContainer extends React.Component {
    constructor() {
      super();
      this.state = { text: defaultValue };
      this.deferred = defer();
      this.promise = this.deferred.promise;

      this._dialog = null;
      this._input = null;

      this._cancel = this._cancel.bind(this);
      this._handleKeyPress = this._handleKeyPress.bind(this);
      this._setDialogRef = this._setDialogRef.bind(this);
      this._submit = this._submit.bind(this);
      this._updateText = this._updateText.bind(this);
    }

    _cancel() {
      this.deferred.resolve(null);
      this._dialog.close();
    }

    _handleKeyPress(event) {
      if (event.charCode === 13) {
        event.preventDefault();
        this._submit();
      } else if (event.charCode === 27) {
        event.preventDefault();
        this._cancel();
      }
    }

    _setDialogRef(value) {
      this._dialog = value;
    }

    _submit() {
      this.deferred.resolve(this.state.text);
      this._dialog.close();
    }

    _updateText(event) {
      this.setState({ text: event.target.value });
    }

    render() {
      return (
        <DialogWrapper ref={this._setDialogRef}>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            <DialogContentText>{message}</DialogContentText>
            <div>
              <TextField
                autoFocus
                fullWidth
                value={this.state.text}
                name="prompt"
                type={opts.password ? 'password' : 'text'}
                onChange={this._updateText}
                onKeyPress={this._handleKeyPress}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button color="secondary" onClick={this._cancel}>
              Cancel
            </Button>
            <Button color="primary" onClick={this._submit}>
              OK
            </Button>
          </DialogActions>
        </DialogWrapper>
      );
    }
  }
  return show(DialogContainer);
}

export { alert, confirm, prompt };
