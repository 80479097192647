/**
 * @file Part of the state object that holds the state of the admin dialog.
 */

import { handleActions } from 'redux-actions';
import u from 'updeep';

/**
 * Default state of the admin dialog.
 */
const defaultState = {
  open: false,
  selectedTab: 'submitted',
  apiCredentials: undefined,
};

/**
 * Reducer that handles actions related to changing the state of the sidebar.
 */
const reducer = handleActions(
  {
    CLOSE_ADMIN_DIALOG: (state) =>
      u({ apiCredentials: u.constant(undefined), open: false }, state),

    OPEN_ADMIN_DIALOG: (state, { payload }) =>
      u(
        {
          open: true,
          selectedTab:
            payload.tab !== undefined ? payload.tab : state.selectedTab,
        },
        state
      ),

    SET_ADMIN_DIALOG_SELECTED_TAB: (state, { payload }) =>
      u({ selectedTab: payload.tab }, state),

    SET_API_CREDENTIALS: (state, { payload }) =>
      u({ apiCredentials: payload }, state),
  },
  defaultState
);

export default reducer;
