import Chip from '@material-ui/core/Chip';
import LinearProgress from '@material-ui/core/LinearProgress';
import Update from '@material-ui/icons/Cached';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { closeSidebar } from '../../actions/sidebar';
import { requestCheckForUpdates } from '../../actions/updates';
import { isApplicationOnline } from '../../selectors/sync';
import {
  getUpdateProgress,
  hasUpdateAvailable,
  isCheckingForUpdates,
  isNotCachedForOfflineUse,
  isUpdateBeingDownloaded,
  wasErrorWhileUpdating,
} from '../../selectors/updates';

/**
 * React component that shows whether an update is available for the
 * current application or not.
 */
const UpdateStatusDisplay = ({
  available,
  checking,
  downloading,
  error,
  notCachedForOfflineUse,
  online,
  progress,
  onCheckForUpdates,
  onInstall,
  onRetry,
}) =>
  available ? (
    <ListItem button onClick={!downloading ? onInstall : undefined}>
      <ListItemIcon>
        {downloading ? (
          <Update className="spinning" />
        ) : (
          <Chip label="1" className="updateAvailable" />
        )}
      </ListItemIcon>
      <ListItemText
        disableTypography={downloading}
        primary={downloading ? 'Downloading update...' : 'Update downloaded'}
        secondary={
          downloading ? (
            <LinearProgress
              value={progress > 0 ? progress * 100 : undefined}
              variant={progress > 0 ? 'determinate' : 'indeterminate'}
            />
          ) : (
            'Click here to install'
          )
        }
      />
    </ListItem>
  ) : checking ? (
    <ListItem>
      <ListItemIcon>
        <Update className="spinning" />
      </ListItemIcon>
      <ListItemText disableTypography primary="Checking for updates..." />
    </ListItem>
  ) : error ? (
    <ListItem button onClick={onRetry}>
      <ListItemIcon>
        <Chip label="!" className="updateError" />
      </ListItemIcon>
      <ListItemText
        primary="Error while updating"
        secondary="Click here to try again"
      />
    </ListItem>
  ) : notCachedForOfflineUse ? (
    <ListItem button disabled={!online} onClick={onCheckForUpdates}>
      <ListItemIcon>
        <Chip label="!" className="updateError" />
      </ListItemIcon>
      <ListItemText primary="Not ready for offline use" />
    </ListItem>
  ) : (
    <ListItem button disabled={!online} onClick={onCheckForUpdates}>
      <ListItemIcon>
        <Update />
      </ListItemIcon>
      <ListItemText primary="Check for updates" />
    </ListItem>
  );

UpdateStatusDisplay.propTypes = {
  available: PropTypes.bool,
  checking: PropTypes.bool,
  downloading: PropTypes.bool,
  error: PropTypes.bool,
  notCachedForOfflineUse: PropTypes.bool,
  onCheckForUpdates: PropTypes.func,
  onInstall: PropTypes.func,
  onRetry: PropTypes.func,
  progress: PropTypes.number,
};

export default connect(
  // mapStateToProps
  (state) => ({
    available: hasUpdateAvailable(state),
    checking: isCheckingForUpdates(state),
    downloading: isUpdateBeingDownloaded(state),
    error: isApplicationOnline(state) && wasErrorWhileUpdating(state),
    notCachedForOfflineUse: isNotCachedForOfflineUse(state),
    online: isApplicationOnline(state),
    progress: getUpdateProgress(state),
  }),
  // mapDispatchToProps
  (dispatch) => ({
    onCheckForUpdates: () => {
      dispatch(requestCheckForUpdates());
    },
    onInstall: () => {
      dispatch(closeSidebar());
      if ('serviceWorker' in navigator) {
        window.location.reload(true);
      } else if (window.applicationCache) {
        try {
          window.applicationCache.swapCache();
        } catch (e) {
          console.warn('Error while swapping out old cached version');
        }
        window.location.reload(true);
      } else {
        window.location.reload(true);
      }
    },
    onRetry: () => {
      if ('serviceWorker' in navigator) {
        window.location.reload(true);
      } else if (window.applicationCache) {
        try {
          window.applicationCache.update();
        } catch (e) {
          dispatch(closeSidebar());
          window.location.reload(true);
        }
      } else {
        window.location.reload(true);
      }
    },
  })
)(UpdateStatusDisplay);
