/**
 * React component that renders the header of the main application.
 */

import PropTypes from 'prop-types';
import React from 'react';

import survey from '../../survey';

import { ExitButton, LockScreenButton } from '../buttons';
import { SidebarToggleButton } from '../sidebar';

import NavigationProgressDots from './NavigationProgressDots';

import './Header.css';

const Header = ({ sidebarEnabled }) => (
  <div className="Header">
    {sidebarEnabled && <SidebarToggleButton floating />}
    <NavigationProgressDots />
    <ExitButton survey={survey} />
    <LockScreenButton />
  </div>
);

Header.propTypes = {
  sidebarEnabled: PropTypes.bool,
};

export default Header;
