import concat from 'lodash-es/concat';
import difference from 'lodash-es/difference';
import mapValues from 'lodash-es/mapValues';
import range from 'lodash-es/range';
import pickRandom from 'pick-random';

import { fetchTemplate } from '../utils';

const dilute = (primarySet, keep, remainingSet) => {
  const itemsToKeep = pickRandom(primarySet, { count: keep });
  const itemsToAdd = difference(remainingSet, primarySet);
  return concat(
    itemsToKeep,
    pickRandom(itemsToAdd, { count: primarySet.length - itemsToKeep.length })
  ).sort();
};

export const pickNumbersForCWM = (options) => {
  const { count, keep } = {
    count: 15,
    keep: 3,
    ...options,
  };

  const twoDigitNumbers = range(11, 100);
  const pickableTwoDigitNumbers = twoDigitNumbers.filter(
    (number) =>
      number >= 30 && number % 5 !== 0 && number !== 13 && number % 11 !== 0
  );
  const createInitialAndDilutedSet = (value) => {
    // Don't sort the initialSet -- this is intentional; users might be biased
    // towards selecting the number in the center so we keep the ordering random.
    const initialSet = pickRandom(pickableTwoDigitNumbers, { count });
    const dilutedSet = dilute(initialSet, value, twoDigitNumbers);
    return { initialSet, dilutedSet };
  };

  if (typeof keep === 'object') {
    return mapValues(keep, (value) => createInitialAndDilutedSet(value));
  } else {
    return createInitialAndDilutedSet(keep);
  }
};

export const fetchTemplateAndFormat = (template, numbers) => {
  const formattedNumbers = mapValues(numbers, (items) => items.join('\u00a0 '));
  return fetchTemplate(template, formattedNumbers);
};
