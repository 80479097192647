/**
 * @file Generic utility functions that do not fit elsewhere.
 */

import random from 'lodash-es/random';
import sample from 'lodash-es/sample';
import { DateTime } from 'luxon';
import Mustache from 'mustache';

/**
 * Wraps a JavaScript object in an array if it is not an array yet.
 *
 * @param  {object}  input  the input object to wrap
 * @return {object[]}  an empty array if the input object is
 *         undefined, or the input itself if it is already an
 *         array, or the input wrapped in an array otherwise
 */
export function arrify(input) {
  if (input === undefined) {
    return [];
  } else if (Array.isArray(input)) {
    return input;
  } else {
    return [input];
  }
}

/**
 * Helper function that fetches raw textual data from the given URL.
 *
 * @param {string} url the URL to fetch the text from
 * @param {object} options options to pass to the underlying `fetch()` call
 * @return {Promise<string>} a promise that will resolve to the text
 *         fetched from the URL or that will reject if the URL returns an
 *         error response
 */
export async function fetchText(url, options) {
  const response = await fetch(url, {
    credentials: 'include',
    ...options,
  });

  if (response.ok) {
    return response.text();
  } else {
    throw new Error(
      `error while retrieving ${url} (code=${response.status}, status=${response.statusText})`
    );
  }
}

/**
 * Helper function that fetches raw textual data from the given URL, then
 * replaces some predefined markers in it using Mustache.js.
 *
 * @param {string} url the URL to fetch the text from
 * @param {object} data key-value pairs mapping the tokens to replace to
 *        their values. Tokens in the input text must be surrounded with
 *        double curly braces (i.e. `{{foo}}` will be replaced with the
 *        value corresponding to the key `foo` in this object).
 * @param {object} options options to pass to the underlying `fetch()` call
 * @return {Promise<string>} a promise that will resolve to the text
 *         fetched from the URL or that will reject if the URL returns an
 *         error response
 */
export async function fetchTemplate(url, data, options) {
  const text = await fetchText(url, options);
  return Mustache.render(text, data);
}

/**
 * Returns a Luxon date object from a timestamp that is assumed to be
 * a UNIX timestamp in UTC.
 *
 * @param {number} timestamp  the timestamp to convert
 * @param {string|undefined}  zone  the timezone of the _returned_ date
 *        object; defaults to "UTC"
 * @return {DateTime}  the datetime object
 */
export function fromMillis(timestamp, zone = 'UTC') {
  const date = DateTime.fromMillis(timestamp, { zone });
  if (zone === 'UTC') {
    return date;
  } else {
    return date.setZone(zone);
  }
}

/**
 * Helper function to generate a human-readable "completion code" that
 * participants of the survey can use as a proof that they went through
 * the survey.
 *
 * The completion code consists of parts separated by spaces. Each part may be
 * a randomly chosen word from a set of words, or a randomly chosen number from
 * a range of numbers, optionally restricted to numbers divisible by a given
 * other number.
 */
export function generateCompletionCode(spec) {
  function generatePart(partSpec) {
    const { type } = partSpec;
    switch (type) {
      case 'choice':
        return sample(partSpec.items);

      case 'number':
        const { range, divisibleBy } = partSpec;
        while (true) {
          const number = random(range[0], range[1] - 1);
          if (divisibleBy !== undefined && number % divisibleBy !== 0) {
            continue;
          }
          return number;
        }

      default:
        return '';
    }
  }

  return spec.map(generatePart).join(' ');
}

/**
 * Calls a generator function in a loop until it returns a value
 * for which a tester function returns false, then returns what
 * the generator function returned.
 *
 * @param  {function}  generator  the generator function; must be
 *         callable with no arguments
 * @param  {function|Array|Object}  existing  the tester function
 *         or an array or an object. When it is a function, the
 *         values from the generator will be passed through this
 *         function and the first generated value for which the
 *         tester returns false will be returned. When it is an
 *         array, the function will return values from the
 *         generator that are not in this array. When it is an
 *         object, the function will return values that are not
 *         keys of the object.
 * @return {object} the first object from the generator function
 *         that is rejected by the tester function as outlined above
 */
export function pickUnused(generator, existing) {
  if (Array.isArray(existing)) {
    while (1) {
      const value = generator();
      if (existing.indexOf(value) < 0) {
        return value;
      }
    }
  } else if (typeof existing === 'function') {
    while (1) {
      const value = generator();
      if (!existing(value)) {
        return value;
      }
    }
  } else {
    while (1) {
      const value = generator();
      if (!existing.hasOwnProperty(value)) {
        return value;
      }
    }
  }
}

/**
 * Returns the current date and time as a UNIX timestamp.
 *
 * @return {number} the current date and time as a UNIX timestamp.
 */
export function now() {
  return DateTime.local().toUTC().valueOf();
}

/**
 * Obfuscates the given UNIX timestamp by clearing the minutes and
 * seconds and rounding the hours to midnight or noon.
 *
 * @param {number|undefined} timestamp  the timestamp to obfuscate
 * @return {number|undefined} the obfuscated timestamp or undefined
 *         if the input was undefined
 */
export function obfuscateTimestamp(timestamp) {
  if (timestamp === undefined) {
    return undefined;
  } else {
    // Recover the time object from the timestamp (which is always in
    // UTC), convert it back to the local timezone, check whether it's
    // morning or afternoon, adjust the hours accordingly in local time,
    // and then convert back to UTC.
    const timeObj = fromMillis(timestamp, 'local');
    return timeObj
      .set({
        hours: timeObj.hour < 12 ? 0 : 12,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
      })
      .toUTC()
      .valueOf();
  }
}

/**
 * Sanitizes a URL by replacing all occurrences of `%PUBLIC_URL%` in it with
 * the actual public URL of the app and then ensuring that the URL is absolute.
 */
export function sanitizeUrl(url) {
  const result = new URL(
    url.replace('%PUBLIC_URL%', process.env.PUBLIC_URL),
    document.baseURI
  ).href;
  return result;
}

/**
 * Given an array of strings, returns an array of objects where each item
 * has two keys: `label`, containing the original item, and `value`, containing
 * a 1-based index of the original item. This is useful to create single-choice
 * survey pages.
 */
export function toIndexedChoices(choices) {
  return choices.map((item, index) => ({
    label: item,
    value: index + 1,
  }));
}
