/**
 * @file Part of the state object that holds the state of the onboarding
 * process during first startup.
 */

import { handleActions } from 'redux-actions';
import u from 'updeep';

/**
 * Default state of the onboarding process at first startup.
 */
const defaultState = {
  complete: false,
  deviceName: '',
  passwordValid: false,
};

/**
 * Reducer that handles actions related to the onboarding process.
 */
const reducer = handleActions(
  {
    FINISH_ONBOARDING: u({ complete: true }),
    ONBOARING_PASSWORD_ENTERED: u({ passwordValid: true }),
    SET_DEVICE_NAME: (state, { payload }) =>
      u({ deviceName: payload.deviceName }, state),
  },
  defaultState
);

export default reducer;
