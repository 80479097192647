import { SwipeableItemButton } from '../swipeable';
import React from 'react';

/**
 * Button that can be used on a swipeable list item to trigger a removal.
 */
export default ({ backgroundColor }) => (
  <SwipeableItemButton
    backgroundColor={backgroundColor || '#ff0000'}
    label="Remove"
  />
);
