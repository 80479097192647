/**
 * @file Cryptography-related functions.
 */

import crypto from 'crypto-browserify';
import { pbkdf2 } from 'pbkdf2';
import pify from 'pify';

/**
 * Promisified variant of the PBKDF2 password hashing function.
 */
const pbkdf2AsPromised = pify(pbkdf2);

/**
 * Default parameters of the PBKDF2 password hashing algorithm used
 * throughout the application. The key and the salt length is given in
 * bytes.
 */
const defaultPasswordHashOptions = {
  hash: 'sha256',
  iterations: 29000,
  keyLength: 30,
  saltLength: 16,
};

/**
 * Decrypts a JavaScript object that was encrypted before with
 * `encryptObject()`. See the documentation of `encryptObject()`
 * for the details of the encryption format.
 *
 * @param {string} key  the key that was used during encryption
 * @param {string} data the data to decrypt. When omitted, the
 *        function will return a partially applied version of
 *        itself that only requires the data object.
 * @return {object} the decrypted object
 */
export function decryptObject(key, data) {
  if (arguments.length === 2) {
    return decryptObject(key)(data);
  }

  const keyBuffer = Buffer.from(key, 'base64');
  return (data) => {
    const separatorIndex = data.indexOf('$');
    if (separatorIndex < 0) {
      throw new Error('cannot extract IV from data');
    }

    const iv = Buffer.from(data.substr(0, separatorIndex - 1), 'base64');
    const decipher = crypto.createDecipheriv('aes256', keyBuffer, iv);
    const decrypted =
      decipher.update(data.substr(separatorIndex + 1), 'base64', 'utf8') +
      decipher.final('utf8');
    return JSON.parse(decrypted);
  };
}

/**
 * Generates a password hash from the given password.
 *
 * @param {string} password  the password for which we need its hash
 * @param {object} options   overrides of the password hashing options
 * @param {string} options.hash  the hashing algorithm, e.g., 'sha256'
 * @param {number} options.iterations  the number of iterations
 * @param {number} options.keyLength   the key length, in bytes
 * @param {number} options.saltLength  the salt length, in bytes
 * @return {Promise<string>} a promise that will resolve to the password
 *         hash, containing the name of the algorithm used for encoding
 *         (e.g., `pbkdf2-sha256`), the number of iterations, the salt
 *         length, and the base64-encoded hash
 */
export function encryptPassword(password, options) {
  const effectiveOptions = {
    ...defaultPasswordHashOptions,
    ...options,
  };

  const salt = crypto.randomBytes(effectiveOptions.saltLength);
  return pbkdf2AsPromised(
    password,
    salt,
    effectiveOptions.iterations,
    effectiveOptions.keyLength,
    effectiveOptions.hash
  ).then((buffer) =>
    [
      '',
      `pbkdf2-${effectiveOptions.hash}`,
      effectiveOptions.iterations,
      salt.toString('base64'),
      buffer.toString('base64'),
    ].join('$')
  );
}

/**
 * Encrypts some JavaScript object with the given encryption key
 * using AES256 encryption. The encrypted representation will contain the
 * AES initialization vector (IV), base64-encoded, followed by a $
 * sign, followed by the AES256-encrypted JSON representation of the
 * object, also base64-encoded.
 *
 * @param  {string}  key  the key to use during the encryption, in
 *         base64-encoded format
 * @param  {object}  object  the object to encrypt. When omitted,
 *         we will return a partially applied version of this function
 *         that only requires the object as its input argument.
 * @return {string}  the encrypted representation of the object
 */
export function encryptObject(key, object) {
  if (arguments.length === 2) {
    return encryptObject(key)(object);
  }

  const keyBuffer = Buffer.from(key, 'base64');
  return (object) => {
    const iv = crypto.randomBytes(16);
    const cipher = crypto.createCipheriv('aes256', keyBuffer, iv);
    return (
      iv.toString('base64') +
      '$' +
      cipher.update(JSON.stringify(object), 'utf8', 'base64') +
      cipher.final('base64')
    );
  };
}

/**
 * Generates the given number of random bytes, base64-encodes them and returns
 * them as a string.
 *
 * @param  {number} length  the number of bytes to generate
 * @return {string}  a base64-encoded string containing the given number of
 *         random bytes
 */
export function generateRandomBytes(length) {
  return crypto.randomBytes(length).toString('base64');
}

/**
 * Validates a password against a hash string, assuming that the hash string
 * contains the name of the hashing algorithm, the hash parameters
 * (dependent on the hashing algorithm), and the result of the hash (typically
 * in base64-encoded form), all separated by $ signs.
 *
 * @param {string} hash      the hash to compare the password with
 * @param {string} password  the password to validate. When undefined, the
 *        function will return another function that validates against the
 *        given hash unconditionally.
 * @return {Promise<bool>}  a promise that resolves to a boolean denoting
 *         whether the password matches the hash, or if the password is
 *         undefined, a partially applied version of the function that
 *         needs only the password as its argument
 */
export function validatePassword(hash, password) {
  if (arguments.length === 2) {
    return validatePassword(hash)(password);
  }

  const parts = hash.split('$');
  if (parts[0] === '') {
    parts.splice(0, 1);
  }

  if (parts.length < 2) {
    throw new Error(
      'password hash needs at least two parts, separated by $ signs'
    );
  }

  const algorithm = parts.shift();
  if (algorithm.substr(0, 7) !== 'pbkdf2-') {
    throw new Error('only pbkdf2 hashes are supported at the moment');
  }

  if (parts.length !== 3) {
    throw new Error(
      'pbkdf2 hashes need two parameters: iteration count and salt'
    );
  }

  const subtype = algorithm.substr(7);
  const iterations = Number.parseInt(parts[0], 10);
  if (isNaN(iterations) || iterations < 1) {
    throw new Error('invalid iteration count: ' + parts[0]);
  }

  const salt = Buffer.from(parts[1], 'base64');
  const digest = Buffer.from(parts[2], 'base64');

  return function (password) {
    if (!password) {
      return Promise.resolve(false);
    }
    return pbkdf2AsPromised(
      password,
      salt,
      iterations,
      digest.length,
      subtype
    ).then((observed) => observed.equals(digest));
  };
}
