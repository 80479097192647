/**
 * @file Part of the state object that holds all the surveys that have
 * been stored so far.
 */

import eq from 'lodash-es/eq';
import mapValues from 'lodash-es/mapValues';
import pickBy from 'lodash-es/pickBy';
import { handleActions } from 'redux-actions';
import { LOAD } from 'redux-storage';
import u from 'updeep';

import { SynchronizationStatus } from '../../model';

/**
 * Default state of the current survey being recorded.
 */
const defaultState = {
  byId: {},
  order: [],
};

/**
 * Helper that creates a function that sets the synchronization status
 * of the survey with the given ID to the given value and returns the
 * new state object.
 *
 * @param {string} status  the new status from the `SynchronizationStatus` enum
 */
const setSynchronizationStatus = (status) => (state, { payload }) => {
  const { id } = payload;
  if (state.byId[id] !== undefined) {
    return u({ byId: { [id]: { status } } }, state);
  } else {
    return state;
  }
};

/**
 * Reducer that handles actions related to storing surveys.
 */
const reducer = handleActions(
  {
    MARK_SURVEY_AS_UNSUBMITTED_BY_ID: setSynchronizationStatus(
      SynchronizationStatus.NEW
    ),

    NOTIFY_SURVEY_SUBMISSION_CANCELLED: setSynchronizationStatus(
      SynchronizationStatus.NEW
    ),

    NOTIFY_SURVEY_SUBMISSION_ERROR: setSynchronizationStatus(
      SynchronizationStatus.ERROR
    ),

    NOTIFY_SURVEY_SUBMISSION_STARTED: setSynchronizationStatus(
      SynchronizationStatus.UPLOADING
    ),

    NOTIFY_SURVEY_SUBMISSION_SUCCESSFUL: setSynchronizationStatus(
      SynchronizationStatus.UPLOADED
    ),

    REMOVE_SURVEY_BY_ID: (state, { payload }) =>
      u(
        {
          byId: u.constant(u.omit(payload.id, state.byId)),
          order: u.constant(u.reject(eq(payload.id), state.order)),
        },
        state
      ),

    REMOVE_SURVEYS_BY_IDS: (state, { payload }) => {
      const { ids } = payload;
      if (!ids || ids.length === 0) {
        return state;
      }

      return u(
        {
          byId: u.constant(u.omit(ids, state.byId)),
          order: u.constant(
            u.reject((id) => ids.indexOf(id) >= 0, state.order)
          ),
        },
        state
      );
    },

    SAVE_SURVEY: (state, { payload }) => {
      const { id, encryptedSurvey } = payload;
      if (state.byId[id] !== undefined) {
        throw new Error('survey ID already taken:', id);
      }
      return u(
        {
          byId: {
            [id]: {
              ...encryptedSurvey,
              id,
            },
          },
          order: [...state.order, id],
        },
        state
      );
    },

    SET_SURVEY_SUBMISSION_STATUS: (state, { payload }) => {
      const { id, status } = payload;
      if (state.byId[id] !== undefined) {
        return u({ byId: { [id]: { status } } }, state);
      } else {
        return state;
      }
    },

    [LOAD]: (state) => {
      // When the state object is restored from the local storage, we need
      // to find all the surveys that are marked as currently being uploaded,
      // and replace their status with 'new'. (Obviously, there cannot be
      // any surveys that are being uploaded if we have just restored the
      // state object)
      const surveysByIds = pickBy(state.byId, ({ status }) =>
        SynchronizationStatus.isBeingSynchronized(status)
      );
      const updates = {
        byId: mapValues(surveysByIds, () => ({
          status: SynchronizationStatus.NEW,
        })),
      };
      return Object.keys(surveysByIds).length ? u(updates, state) : state;
    },
  },
  defaultState
);

export default reducer;
