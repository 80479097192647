import mapValues from 'lodash-es/mapValues';
import startCase from 'lodash-es/startCase';
import sortBy from 'lodash-es/sortBy';
import sum from 'lodash-es/sum';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { loadSurveyStatistics } from '../actions/surveys';

import './StatisticsDisplay.css';

/**
 * React component that renders a single bar in the bar charts.
 */
const Bar = ({ color, width }) => (
  <div
    className="bar"
    style={{ backgroundColor: color || '#2196f3', width: Math.floor(width) }}
  />
);

const barColors = ['#2196f3', '#ffc107', '#4caf50', '#f44336'];

/**
 * React component that fetches the statistics of the submitted
 * surveys from the server and displays them in a nice human-readable
 * format.
 */
class StatisticsDisplay extends React.Component {
  componentDidMount() {
    if (this.props.requestData) {
      this.props.requestData();
    }
  }

  render() {
    const { error, loading } = this.props;
    if (error) {
      return (
        <Typography align="center" color="error" style={{ margin: 'auto' }}>
          Error while loading survey stats
        </Typography>
      );
    } else if (loading) {
      return <CircularProgress style={{ margin: 'auto' }} />;
    } else {
      return this._renderResult();
    }
  }

  _renderResult() {
    const { stats } = this.props;
    const keys = stats ? Object.keys(stats) : [];
    if (!keys || keys.length === 0) {
      return (
        <Typography
          align="center"
          color="textSecondary"
          style={{ margin: 'auto' }}
        >
          No statistics to show
        </Typography>
      );
    }

    return (
      <div className="StatisticsDisplay">
        <Table>
          {keys.sort().map((key) => this._renderResultSection(stats[key], key))}
        </Table>
      </div>
    );
  }

  _renderResultSectionHeader(key) {
    return (
      <TableHead>
        <TableRow>
          <TableCell colSpan={3}>By {startCase(key).toLowerCase()}</TableCell>
        </TableRow>
      </TableHead>
    );
  }

  _renderResultSection(stats, key) {
    if (key === 'total') {
      return null;
    }

    if (key === 'date' || key === 'variant') {
      return this._renderStackedResultSection(stats, key);
    }

    const values = sortBy(Object.keys(stats), (x) => -stats[x]);
    const total = sum(Object.values(stats));

    return (
      <React.Fragment key={key}>
        {this._renderResultSectionHeader(key)}
        <TableBody>
          {values.map((value, index) => (
            <TableRow key={index}>
              <TableCell>{value}</TableCell>
              <TableCell align="right">{stats[value]}</TableCell>
              <TableCell style={{ whiteSpace: 'nowrap' }}>
                <Bar
                  width={total > 0 ? (stats[value] / total) * 200 : 0}
                  color={barColors[0]}
                />
                <span className="barLabel">
                  {total > 0 ? ((stats[value] / total) * 100.0).toFixed(2) : 0}%
                </span>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </React.Fragment>
    );
  }

  _renderStackedResultSection(stats, key) {
    const statsSums = mapValues(stats, (values) => sum(values));
    const values = sortBy(Object.keys(statsSums), (x) => -statsSums[x]);
    const total = sum(Object.values(statsSums));

    return (
      <React.Fragment key={key}>
        {this._renderResultSectionHeader(key)}
        <TableBody>
          {values.map((value, index) => (
            <TableRow key={index}>
              <TableCell>{value}</TableCell>
              <TableCell align="right">{stats[value].join('/')}</TableCell>
              <TableCell style={{ whiteSpace: 'nowrap' }}>
                {stats[value].map((subValue, subIndex) => (
                  <Bar
                    key={index + '_' + subIndex}
                    width={(subValue / total) * 200}
                    color={barColors[subIndex]}
                  />
                ))}
                <span className="barLabel">
                  {((statsSums[value] / total) * 100.0).toFixed(2)}%
                </span>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </React.Fragment>
    );
  }
}

StatisticsDisplay.propTypes = {
  requestData: PropTypes.func,
};

export default connect(
  // mapStateToProps
  (state) => ({
    error: !!state.surveys.stats.error,
    loading: !!state.surveys.stats.loading,
    stats: state.surveys.stats.loaded ? state.surveys.stats.result : undefined,
  }),

  // mapDispatchToProps
  (dispatch) => ({
    requestData: () => {
      dispatch(loadSurveyStatistics());
    },
  })
)(StatisticsDisplay);
