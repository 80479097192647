import { SwipeableItemButton } from '../swipeable';
import React from 'react';

/**
 * Button that can be used to mark a survey as "to be submitted".
 */
export default ({ backgroundColor }) => (
  <SwipeableItemButton
    backgroundColor={backgroundColor || '#2196f3'}
    label="Submit again"
  />
);
