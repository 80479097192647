import Cloud from '@material-ui/icons/Cloud';
import CloudOff from '@material-ui/icons/CloudOff';
import CloudUpload from '@material-ui/icons/CloudUpload';
import React from 'react';
import { connect } from 'react-redux';

import { isApplicationOnline, isSyncInProgress } from '../../selectors/sync';

import './OnlineStateIndicator.css';

/**
 * React component that indicates whether the app is currently online or not.
 */
const OnlineStateIndicator = ({ dispatch, isSyncInProgress, online }) => (
  <div className="OnlineStateIndicator">
    {!online ? (
      <CloudOff style={{ color: 'white' }} />
    ) : isSyncInProgress ? (
      <CloudUpload style={{ color: 'white' }} />
    ) : (
      <Cloud style={{ color: 'white' }} />
    )}
  </div>
);

export default connect(
  // mapStateToProps
  (state) => ({
    online: isApplicationOnline(state),
    isSyncInProgress: isSyncInProgress(state),
  })
)(OnlineStateIndicator);
