import { connect } from 'react-redux';

import CountdownIndicator from './CountdownIndicator';

import './SurveyTimeoutIndicator.css';

export default connect(
  // mapStateToProps
  (state) => ({
    className: 'SurveyTimeoutIndicator',
    color: 'white',
    deadline: state.surveys.timeout.deadline,
  }),
  // mapDispatchToProps
  (dispatch) => ({})
)(CountdownIndicator);
