/**
 * @file Sagas related to the synchronization of surveys to a remote server.
 */

import get from 'lodash-es/get';

import {
  all,
  call,
  cancel,
  cancelled,
  delay,
  fork,
  put,
  race,
  select,
  take,
} from 'redux-saga/effects';

import config from '../config';

import {
  notifySurveySubmissionCancelled,
  notifySurveySubmissionError,
  notifySurveySubmissionStarted,
  notifySurveySubmissionSuccessful,
  requestSynchronization,
  setSurveySubmissionStatus,
} from '../actions/sync';
import {
  CANCEL_SYNCHRONIZATION,
  DISABLE_AUTOMATIC_SYNC,
  ENABLE_AUTOMATIC_SYNC,
  REQUEST_SYNCHRONIZATION,
  SAVE_SURVEY,
  SET_ONLINE_STATE,
} from '../actions/types';
import Api from '../api';
import {
  getNextSurveyIdToSyncExcluding,
  getSurveyById,
  isSyncInProgress,
} from '../selectors/surveys';
import { isAutomaticSyncEnabled, isApplicationOnline } from '../selectors/sync';

/**
 * Saga that submits a single survey to the remote server and dispatches
 * appropriate actions to update the state of the store.
 *
 * @param {string} mode  the submission mode; can be either `auto or
 *        `manual`. See the documentation of `submitPendingSurveys()` for a
 *        detailed explanation.
 */
function* submitSingleSurvey(surveyId, mode) {
  const { status } = yield select(getSurveyById(surveyId));
  yield put(notifySurveySubmissionStarted(surveyId));

  let successful = false;

  try {
    const survey = yield select(getSurveyById(surveyId));
    yield call(Api.submit, survey);
    successful = true;
  } catch (e) {
    console.error(e);
  } finally {
    if (yield cancelled()) {
      yield put(notifySurveySubmissionCancelled(surveyId));
    } else if (successful) {
      yield put(notifySurveySubmissionSuccessful(surveyId));
    } else {
      if (mode === 'manual') {
        yield put(notifySurveySubmissionError(surveyId));
      } else if (status !== undefined) {
        yield put(setSurveySubmissionStatus(surveyId, status));
      }
    }
  }

  return successful;
}

/**
 * Saga that processes all surveys from the store that are not yet submitted
 * and submits them one by one.
 *
 * @param {string} mode  the submission mode; can be either `auto or
 *        `manual`. When invoked in `manual` mode, we assume that the
 *        submission process was initiated explicitly by the user so we
 *        can mark those surveys with an error where the submission failed.
 *        When invoked in `auto` mode, we assume that this is an automatic
 *        submission attempt behind the scenes; surveys that fail to submit
 *        will be put back to their original state.
 */
function* submitPendingSurveys(mode = 'manual') {
  const excludedIds = [];
  const nextSurveyIdSelector = getNextSurveyIdToSyncExcluding(excludedIds);

  while (true) {
    // Check if we were cancelled
    if (yield cancelled()) {
      break;
    }

    // Get the ID of the next survey to submit
    const surveyId = yield select(nextSurveyIdSelector);
    if (surveyId === undefined) {
      // No more items to submit.
      break;
    }

    // TODO(ntamas): maybe parallelize this to some extent?
    const result = yield* submitSingleSurvey(surveyId, mode);
    if (!result) {
      // Submission failed, exclude this ID from further consideration and
      // continue.
      excludedIds.push(surveyId);
    }
  }
}

/**
 * Saga that triggers an automatic synchronization at regular intervals.
 *
 * @param {number} interval  maximum number of milliseconds between two
 *        consecutive synchronization attempts.
 */
function* synchronizePeriodically(interval) {
  if (interval === undefined) {
    interval = get(config, 'sync.interval', 600) * 1000;
  }

  while (true) {
    const isOnline = yield select(isApplicationOnline);
    if (isOnline) {
      const isSyncing = yield select(isSyncInProgress);
      if (!isSyncing) {
        yield put(requestSynchronization('auto'));
      }
    }

    yield race({
      action: take([SAVE_SURVEY, SET_ONLINE_STATE]),
      timeout: delay(interval),
    });
  }
}

/**
 * Saga that watches for manual synchronization request actions and starts
 * synchronization when such a request arrives. Requests that arrive while
 * a synchronization is in progress will cancel the current synchronization
 * and start a new one.
 */
function* manualSyncSaga() {
  let lastTask;
  while (true) {
    const action = yield take([
      REQUEST_SYNCHRONIZATION,
      CANCEL_SYNCHRONIZATION,
    ]);
    if (lastTask) {
      yield cancel(lastTask);
      lastTask = undefined;
    }
    if (action.type === REQUEST_SYNCHRONIZATION) {
      lastTask = yield fork(
        submitPendingSurveys,
        action.payload.mode || 'manual'
      );
    }
  }
}

/**
 * Saga that watches actions that turn the automatic synchronization on or
 * off. When the automatic synchronization is enabled, forks another saga
 * that will attempt to synchronize periodically.
 */
function* autoSyncSaga() {
  let syncTask;

  while (true) {
    const enabled = yield select(isAutomaticSyncEnabled);

    if (enabled && syncTask === undefined) {
      syncTask = yield fork(synchronizePeriodically);
    } else if (!enabled && syncTask !== undefined) {
      yield cancel(syncTask);
      syncTask = undefined;
    }

    yield take([ENABLE_AUTOMATIC_SYNC, DISABLE_AUTOMATIC_SYNC]);
  }
}

/**
 * Saga that unifies automatic and manual synchronization handling.
 */
export default function* syncSaga() {
  yield all([autoSyncSaga(), manualSyncSaga()]);
}
