import PropTypes from 'prop-types';
import React from 'react';
import QRCodeBase from 'qrcode.react';

/**
 * Customized QR code component that is also clickable when its value is a URL.
 */
const QRCode = ({ value, ...rest }) => {
  const code = <QRCodeBase value={value} {...rest} />;
  if (
    value &&
    (value.substr(0, 7) === 'http://' || value.substr(0, 8) === 'https://')
  ) {
    return (
      <a href={value} target="_blank" rel="noopener noreferrer">
        {code}
      </a>
    );
  } else {
    return code;
  }
};

QRCode.propTypes = {
  value: PropTypes.string,
  ...QRCodeBase.propTypes,
};

export default QRCode;
