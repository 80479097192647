/**
 * @file Sidebar-related action factories.
 */

import { createAction } from 'redux-actions';

import { Dialogs } from '../components';

import { setAPICredentialsForAdminDialog } from './admin';
import { LOCK_SIDEBAR, SET_SIDEBAR_VISIBILITY, UNLOCK_SIDEBAR } from './types';

/**
 * Creates an action that closes the sidebar of the app.
 */
export const closeSidebar = createAction(SET_SIDEBAR_VISIBILITY, () => ({
  visible: false,
}));

/**
 * Creates an action that closes and locks the sidebar of the app.
 */
export const closeAndLockSidebar = createAction(LOCK_SIDEBAR);

/**
 * Creates an action that opens the sidebar of the app unconditionally.
 * This is not exported; use unlockAndOpenSidebar() instead, which takes
 * care of asking the user for the sidebar password if needed.
 */
const openSidebar = createAction(SET_SIDEBAR_VISIBILITY, () => ({
  visible: true,
}));

/**
 * Creates an action that unlocks the sidebar of the app unconditionally.
 * This is not exported; use unlockAndOpenSidebar() instead, which takes
 * care of asking the user for the sidebar password if needed.
 */
const unlockSidebarUnconditionally = createAction(UNLOCK_SIDEBAR);

/**
 * Function that receives whether the sidebar is currently locked, and
 * if so, manages the password prompt that should be shown.
 *
 * In particular, the function will return a promise that resolves to
 * true if the user has successfully unlocked the sidebar or if the
 * sidebar is not locked. When the sidebar is locked but there is no
 * sidebar password set, the function also returns true unconditonally.
 * Otherwise, if the sidebar is locked and password-protected, prompts
 * the user for the password, validates it and returns true if the
 * password entered was valid.
 *
 * @param  {boolean} locked  whether the sidebar is locked at the moment
 * @param  {object}  options additional options that can be used to tweak
 *         the title and message in the password prompt dialog
 * @param  {string}  options.title  the title of the password prompt
 * @param  {string}  options.message  the message of the password prompt
 * @return {Promise<Object>} an object containing the entered password and
 *         whether the user has successfully unlocked the sidebar
 */
export const promptAndValidateSidebarPassword = async (
  locked = true,
  options = {}
) => {
  if (locked) {
    const { hasSidebarPassword, validateSidebarPassword } = await import(
      '../dynamic/sidebar-password'
    );

    if (hasSidebarPassword) {
      const password = await Dialogs.prompt(
        options.title || 'Restricted area',
        options.message || 'Please enter the password to unlock the sidebar.',
        { password: true }
      );

      if (!password) {
        return { cancelled: true };
      }

      const passwordIsValid = await validateSidebarPassword(password);
      if (passwordIsValid) {
        return { password, passwordValid: true };
      }

      await Dialogs.alert(
        'Access denied',
        'The password that you have entered was invalid.'
      );

      return { passwordValid: false };
    } else {
      return { password: '', pssswordValid: true };
    }
  } else {
    return { password: '', passwordValid: true };
  }
};

/**
 * Creates an action that unlocks the sidebar if needed (asking the user for
 * the sidebar password).
 */
export const unlockSidebar = () => async (dispatch, getState) => {
  const { locked } = getState().ui.sidebar;
  const { password, passwordValid } = await promptAndValidateSidebarPassword(
    locked
  );
  if (passwordValid) {
    if (password) {
      dispatch(
        setAPICredentialsForAdminDialog({
          username: 'admin',
          password,
        })
      );
    }
    dispatch(unlockSidebarUnconditionally());
  }
};

/**
 * Creates an action that unlocks the sidebar if needed (asking the user for
 * the sidebar password), then opens the sidebar of the app if the password
 * was valid.
 */
export const unlockAndOpenSidebar = () => async (dispatch, getState) => {
  const { locked } = getState().ui.sidebar;
  const { password, passwordValid } = await promptAndValidateSidebarPassword(
    locked
  );
  if (passwordValid) {
    if (password) {
      dispatch(
        setAPICredentialsForAdminDialog({
          username: 'admin',
          password,
        })
      );
    }
    dispatch(unlockSidebarUnconditionally());
    dispatch(openSidebar());
  }
};
