import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import React from 'react';

import './GenericTextPage.css';

/**
 * React component that shows a generic text on a page.
 */
const GenericTextPage = ({
  children,
  onSubmit,
  preventSubmission,
  submitText,
}) => (
  <Card raised className="GenericTextPage">
    <CardContent>{children}</CardContent>
    {!preventSubmission ? (
      <CardActions style={{ justifyContent: 'center' }}>
        <Button size="large" onClick={() => onSubmit()} color="primary">
          {submitText}
        </Button>
      </CardActions>
    ) : null}
  </Card>
);

GenericTextPage.defaultProps = {
  submitText: 'Next Page',
};

export default GenericTextPage;
