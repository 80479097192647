/**
 * @file Sidebar-related selector functions.
 */

/**
 * Selector function that returns whether the sidebar is unlocked but
 * closed (a potentially insecure configuration), given the state object.
 */
export const isSidebarClosedButUnlocked = (state) =>
  !state.ui.sidebar.open && !state.ui.sidebar.locked;

/**
 * Selector function that returns whether the sidebar is locked.
 */
export const isSidebarLocked = (state) => state.ui.sidebar.locked;

/**
 * Selector function that returns whether the sidebar is open.
 */
export const isSidebarOpen = (state) => state.ui.sidebar.open;
