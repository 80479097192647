/**
 * @file Helper functions for commonly occurring code patterns within
 * sagas.
 */

import { select, take } from 'redux-saga/effects';

/**
 * Generator function that returns when the given selector starts
 * returning true.
 *
 * @param {function} selector  selector function that returns a boolean
 *        value to watch when given the current state.
 * @param {string|string[]}  actions  action or actions to wait for when
 *        the selector returns false. Default is to wait for any action
 *        dispatched on the store.
 */
export function* waitFor(selector, actions = '*') {
  while (true) {
    if (yield select(selector)) {
      return;
    }

    yield take(actions);
  }
}

/**
 * Generator function that waits as long as the given selector stays
 * true, and returns when the selector becomes false.
 *
 * @param {function} selector  selector function that returns a boolean
 *        value to watch when given the current state.
 * @param {string|string[]}  actions  action or actions to wait for when
 *        the selector returns true. Default is to wait for any action
 *        dispatched on the store.
 */
export function* waitWhile(selector, actions = '*') {
  let result;

  while (true) {
    result = yield select(selector);
    if (!result) {
      return;
    }

    yield take(actions);
  }
}
