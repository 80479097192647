/**
 * Basic configuration settings for the application.
 */

import { nanoid } from 'nanoid';

import { SurveyMode } from './model';

export default {
  allowOfflineUsage: true,
  allowInstallationPrompt: false,

  primarySurveyMode: SurveyMode.BROWSER,

  deviceNameGenerator: () => nanoid(12),

  inactivity: {
    dialogInterval: 300 /* 5 mins */,
    interval: 900 /* 15 mins */,
  },

  lock: {
    enabled: true,
  },

  responseTimeThresholds: {
    min: 60,
    max: 600,
  },

  sidebar: {
    enabled: true,
  },

  sync: {
    automatic: true,
    interval: 30,
  },
};
