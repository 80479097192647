import { isSidebarOpen } from '../selectors/sidebar';
import { isShowingBackmatter, isSurveyInProgress } from '../selectors/surveys';

/**
 * Returns whether we should watch for signs of user inactivity, based on the
 * current state.
 */
export const shouldWatchUserInactivity = (state) =>
  (isSurveyInProgress(state) || isShowingBackmatter(state)) &&
  !isSidebarOpen(state);

/**
 * Returns whether we should _warn_ the user when he is inactive instead of
 * simply returning to the front page, based on the current state.
 */
export const shouldWarnUserAboutInactivity = (state) =>
  !isShowingBackmatter(state);

/**
 * Returns whether the inactivity dialog is visible.
 */
export const isInactivityDialogVisible = (state) =>
  state.dialogs.inactivity.open;
