import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Tablet from '@material-ui/icons/TabletAndroid';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { setDeviceName } from '../../actions/onboarding';
import { prompt } from '../../components/dialogs';
import { getDeviceName } from '../../selectors/onboarding';

/**
 * React component that allows the user to edit the name of the
 * device that is used to identify surveys submitted from this
 * device.
 */
const DeviceNameDisplay = ({ name, onChange }) => (
  <ListItem button onClick={onChange}>
    <ListItemIcon>
      <Tablet />
    </ListItemIcon>
    <ListItemText
      primary={name || 'Anonymous device'}
      secondary="Click here to rename device"
    />
  </ListItem>
);

DeviceNameDisplay.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
};

export default connect(
  // mapStateToProps
  (state) => ({
    name: getDeviceName(state),
  }),
  // mapDispatchToProps
  (dispatch) => ({
    onChange: async () => {
      const deviceName = await prompt(
        'Rename device',
        'Enter the new name of the device. Surveys that are recorded later ' +
          'on this device will use the new name. Already recorded surveys ' +
          'will not be affected.'
      );
      if (deviceName && deviceName.length > 0) {
        dispatch(setDeviceName(deviceName));
      }
    },
  })
)(DeviceNameDisplay);
