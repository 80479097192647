import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import {
  notifyCheckingForUpdates,
  notifyErrorWhileUpdating,
  notifyUpdateDownloading,
  notifyUpdateDownloaded,
  notifyUpdateInstalled,
  setUpdateProgress,
} from '../actions/updates';

/**
 * React component that listens to events from the application cache
 * and updates the relevant parts of the state object when the application
 * cache is being updated or when the app detects that a new version is
 * available and ready to install.
 */
class AppCacheListener extends React.Component {
  constructor(props) {
    super(props);

    this._call = this._call.bind(this);
    this._onChecking = this._call.bind(this, 'onCheckingForUpdates');
    this._onErrorWhileUpdating = this._call.bind(this, 'onErrorWhileUpdating');
    this._onFirstDownloadCached = this._call.bind(
      this,
      'onFirstDownloadCached'
    );
    this._onNoUpdateFound = this._call.bind(this, 'onNoUpdateFound');
    this._onProgress = this._onProgress.bind(this);
    this._onUpdateDownloaded = this._call.bind(this, 'onUpdateDownloaded');
    this._onUpdateInProgress = this._call.bind(this, 'onUpdateInProgress');
  }

  componentDidMount() {
    const { applicationCache } = window;

    if (applicationCache) {
      applicationCache.addEventListener('cached', this._onFirstDownloadCached);
      applicationCache.addEventListener('checking', this._onChecking);
      applicationCache.addEventListener(
        'downloading',
        this._onUpdateInProgress
      );
      applicationCache.addEventListener('error', this._onErrorWhileUpdating);
      applicationCache.addEventListener('noupdate', this._onNoUpdateFound);
      applicationCache.addEventListener('progress', this._onProgress);
      applicationCache.addEventListener(
        'updateready',
        this._onUpdateDownloaded
      );
    }
  }

  componentWillUnmount() {
    const { applicationCache } = window;

    if (applicationCache) {
      applicationCache.removeEventListener(
        'cached',
        this._onFirstDownloadCached
      );
      applicationCache.removeEventListener('checking', this._onChecking);
      applicationCache.removeEventListener(
        'downloading',
        this._onUpdateInProgress
      );
      applicationCache.removeEventListener('error', this._onErrorWhileUpdating);
      applicationCache.removeEventListener('noupdate', this._onNoUpdateFound);
      applicationCache.removeEventListener('progress', this._onProgress);
      applicationCache.removeEventListener(
        'updateready',
        this._onUpdateDownloaded
      );
    }
  }

  render() {
    return null;
  }

  _call(handlerName) {
    if (this.props[handlerName] !== undefined) {
      this.props[handlerName]();
    }
  }

  _onProgress(event) {
    if (this.props.onReportProgress && event.loaded && event.total) {
      this.props.onReportProgress(event.loaded / event.total);
    }
  }
}

AppCacheListener.propTypes = {
  onCheckingForUpdates: PropTypes.func,
  onErrorWhileUpdating: PropTypes.func,
  onFirstDownloadCached: PropTypes.func,
  onNoUpdateFound: PropTypes.func,
  onReportProgress: PropTypes.func,
  onUpdateInProgress: PropTypes.func,
  onUpdateDownloaded: PropTypes.func,
};

export default connect(
  // mapStateToProps
  (state) => ({}),
  // mapDispatchToProps
  (dispatch) => ({
    onCheckingForUpdates: () => {
      dispatch(notifyCheckingForUpdates());
    },

    onErrorWhileUpdating: () => {
      dispatch(notifyErrorWhileUpdating());
    },

    onFirstDownloadCached: () => {
      dispatch(notifyUpdateInstalled());
    },

    onNoUpdateFound: () => {
      dispatch(notifyUpdateInstalled());
    },

    onReportProgress: (progress) => {
      dispatch(setUpdateProgress(progress));
    },

    onUpdateInProgress: () => {
      dispatch(notifyUpdateDownloading());
    },

    onUpdateDownloaded: () => {
      dispatch(notifyUpdateDownloaded());
    },
  })
)(AppCacheListener);
