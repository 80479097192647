/**
 * @file Contains the global state store of the application.
 */

import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import storage from 'redux-storage';
import debounce from 'redux-storage-decorator-debounce';
import filter from 'redux-storage-decorator-filter';
import createEngine from 'redux-storage-engine-localstorage';
import createSagaMiddleware from 'redux-saga';
import thunkMiddleware from 'redux-thunk';

import reducer from './reducers';
import rootSaga from './sagas';

/**
 * The storage middleware that defines how the state object can be stored
 * in the application and which parts of the state object are blacklisted.
 */
const storageEngine = debounce(
  filter(
    createEngine('wada-prevalence-survey-state'),
    [
      // Whitelisted keys
    ],
    [
      // Blacklisted keys
      ['dialogs', 'admin'],
      ['dialogs', 'inactivity'],
      ['surveys', 'stats'],
      ['surveys', 'timeout'],
      ['sync', 'online'],
      ['ui', 'lock'],
      ['ui', 'updates'],
    ]
  ),
  500 /* milliseconds for debouncing */
);

/**
 * The saga middleware that manages long-running background processes
 * such as synchronization with the remote server.
 */
const sagaMiddleware = createSagaMiddleware();

/**
 * The storage middleware that defines which storage engine to use and
 * what actions should not trigger saving the state.
 */
const storageMiddleware = storage.createMiddleware(storageEngine, []);

/**
 * Prepares the Redux middlewares and the Redux DevTools extension that we
 * use to extend the behaviour of the Redux store.
 *
 * @param {object} reducer  the reducer function of the store
 */
const createEnhancedStore = composeWithDevTools({})(
  applyMiddleware(thunkMiddleware, sagaMiddleware, storageMiddleware)
)(createStore);

/**
 * The global state store of the application.
 */
const store = createEnhancedStore(storage.reducer(reducer));

// Load the state of the application back from the persistent storage
const load = storage.createLoader(storageEngine);
load(store)
  .then(() => {
    // Spin up the root saga
    sagaMiddleware.run(rootSaga);
  })
  .catch((error) => {
    console.warn('Failed to load the previous state of the app');
    console.error(error);
  });

// TODO: when the state is loaded back, reset the "loading in progress"
// states of all the surveys because the promises are not running for sure

export default store;
