import clsx from 'clsx';
import range from 'lodash-es/range';
import shuffle from 'lodash-es/shuffle';
import Markdown from 'markdown-to-jsx';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';

import { ResponseButtonBar } from '../components';

import './SSCPage.css';

function generateResponses(numQuestions, orWord) {
  const result = [];
  for (let i = 0; i < numQuestions; i++) {
    result.push({
      label: i === 0 ? `0\u00a0${orWord}\u00a0${numQuestions}` : String(i),
      value: i,
    });
  }
  return result;
}

/**
 * React component that shows an SSC-type question and the corresponding
 * response buttons.
 *
 * The children of the component must contain the question itself.
 * Buttons will be rendered at the bottom based on the value of the
 * `numQuestions` prop.
 */
class SSCPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      order: undefined,
    };
  }

  componentDidMount() {
    this._maybePropsChanged(undefined, this.props);
  }

  componentWillReceiveProps(newProps) {
    this._maybePropsChanged(this.props, newProps);
  }

  componentWillUnmount() {
    this._maybePropsChanged(this.props, undefined);
  }

  render() {
    const {
      footer,
      header,
      headerClassName,
      getCurrentResponse,
      numQuestions,
      onSubmit,
      orWord,
      prompt,
      responseKey,
      statements,
    } = this.props;

    return (
      <React.Fragment>
        <Card
          raised
          className={clsx({
            SSCPage: true,
            ['SSCPage-' + headerClassName]: !!headerClassName,
          })}
        >
          {header && <header className={headerClassName}>{header}</header>}
          <CardContent>
            <ul style={{ marginBottom: 0, paddingBottom: 0 }}>
              {(this.state.order || []).map((index) => (
                <li style={{ paddingBottom: '1em' }} key={index}>
                  <Markdown options={{ forceInline: true }}>
                    {statements[index]}
                  </Markdown>
                </li>
              ))}
            </ul>
            {footer && (
              <Typography
                align="center"
                type="primary"
                style={{ color: '#888', fontSize: '1rem', marginBottom: '1em' }}
              >
                {footer}
              </Typography>
            )}
            {prompt && (
              <Typography align="center" variant="title">
                {prompt}
              </Typography>
            )}
          </CardContent>
        </Card>
        <ResponseButtonBar
          responses={generateResponses(numQuestions, orWord)}
          onSubmit={(response) => onSubmit({ [responseKey]: response })}
          value={getCurrentResponse(responseKey)}
        />
      </React.Fragment>
    );
  }

  _maybePropsChanged(oldProps, newProps) {
    const oldStatements = oldProps ? oldProps.statements : [];
    const newStatements = newProps ? newProps.statements : [];

    if (oldStatements !== newStatements) {
      const order = shuffle(range(newStatements ? newStatements.length : 0));
      this.setState({ order });

      if (newProps && newProps.orderKey && newProps.onUpdate) {
        newProps.onUpdate({
          [newProps.orderKey]: order,
        });
      }
    }
  }
}

SSCPage.propTypes = {
  footer: PropTypes.string,
  header: PropTypes.string,
  getCurrentResponse: PropTypes.func,
  numQuestions: PropTypes.number.isRequired,
  orWord: PropTypes.string,
  onSubmit: PropTypes.func,
  onUpdate: PropTypes.func,
  orderKey: PropTypes.string,
  prompt: PropTypes.string,
  responseKey: PropTypes.string.isRequired,
};

SSCPage.defaultProps = {
  orWord: 'or',
};

export default SSCPage;
