import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Back from '@material-ui/icons/ArrowBackIos';
import Forward from '@material-ui/icons/ArrowForwardIos';
import Check from '@material-ui/icons/Check';
import PropTypes from 'prop-types';
import React from 'react';

import { localize } from '../utils/localization';

import './NavigationControls.css';

const localizationData = {
  de: {
    backButtonTitle: 'Vorherige Seite',
    finishButtonTitle: 'Umfrage beenden',
    forwardButtonTitle: 'Nächste Seite',
    returnToFrontPageTitle: 'Zurück zur Startseite',
    startButtonTitle: 'Umfrage beginnen',
  },

  en: {
    backButtonTitle: 'Previous page',
    finishButtonTitle: 'Finish the survey',
    forwardButtonTitle: 'Next page',
    returnToFrontPageTitle: 'Return to front page',
    startButtonTitle: 'Start the survey',
  },

  es: {
    backButtonTitle: 'Página anterior',
    finishButtonTitle: 'Terminar la encuesta',
    forwardButtonTitle: 'Página siguiente',
    returnToFrontPageTitle: 'Volver a la portada',
    startButtonTitle: 'Empezar la encuesta',
  },

  fr: {
    backButtonTitle: 'Page précédente',
    finishButtonTitle: 'Terminer le questionnaire',
    forwardButtonTitle: 'Page suivante',
    returnToFrontPageTitle: 'Retour à la première page',
    startButtonTitle: 'Commencer le questionnaire',
  },

  it: {
    backButtonTitle: 'Pagina precedente',
    finishButtonTitle: 'Termina il sondaggio',
    forwardButtonTitle: 'Pagina seguente',
    returnToFrontPageTitle: 'Ritorna alla prima pagina',
    startButtonTitle: 'Inizia il sondaggio',
  },

  nl: {
    backButtonTitle: 'Vorige pagina',
    finishButtonTitle: 'De enquête voltooien',
    forwardButtonTitle: 'Volgende pagina',
  },

  pl: {
    backButtonTitle: 'Poprzednia strona',
    finishButtonTitle: 'ZakoŃcz ankietę',
    forwardButtonTitle: 'Następna strona',
  },

  pt: {
    backButtonTitle: 'Página anterior',
    finishButtonTitle: 'Concluir o questionário',
    forwardButtonTitle: 'Página seguinte',
  },

  ru: {
    backButtonTitle: 'ПРЕДЫДУЩАЯ СТРАНИЦА',
    finishButtonTitle: 'ЗАВЕРШИТЬ ОПРОС',
    forwardButtonTitle: 'СЛЕДУЮЩАЯ СТРАНИЦА',
    returnToFrontPageTitle: 'Вернуться на первую страницу',
    startButtonTitle: 'Hачать опрос',
  },
};

localizationData.br = localizationData.pt;

/**
 * Helper function to create a card with a button that will invoke the
 * given function when clicked.
 *
 * @param  {function|null}  onClick  the function to call when the button is
 *         clicked
 * @param  {object} bundle  the localization bundle that is used to produce the
 *         labels for the buttons
 * @param  {string} language  the language code to use to look up the labels in
 *         the localization bundle
 * @param  {string} key  the key of the title of the button in the localization
 *         bundle
 * @param  {bool}   secondary  whether the action is a secondary action;
 *         affects the styling of the button
 * @param  {React.Element|undefined}  startIcon  the icon at the start (left)
 *         of the button
 * @param  {React.Element|undefined}  endIcon  the icon at the start (left)
 *         of the button
 * @return {React.Element|null}  the button to render; null if the action
 *         is falsy
 */
const renderButton = ({ bundle, key, onClick, language, secondary, ...rest }) =>
  onClick ? (
    <Card>
      <Button
        {...rest}
        onClick={onClick}
        size="large"
        variant={secondary ? 'text' : 'contained'}
        color={secondary ? 'default' : 'primary'}
      >
        {localize(bundle, language, key)}
      </Button>
    </Card>
  ) : null;

/**
 * Navigation controls to be placed at the bottom of each survey page.
 */
export const NavigationControls = ({
  language,
  onBack,
  onFinishSurvey,
  onForward,
  onReturnToFrontPage,
  onStart,
}) => (
  <div className="NavigationControls">
    {renderButton({
      onClick: onBack,
      bundle: localizationData,
      language,
      key: 'backButtonTitle',
      secondary: true,
      startIcon: <Back />,
    })}
    {renderButton({
      onClick: onStart,
      bundle: localizationData,
      language,
      key: 'startButtonTitle',
      endIcon: <Forward />,
    })}
    {renderButton({
      onClick: onFinishSurvey,
      bundle: localizationData,
      language,
      key: 'finishButtonTitle',
    })}
    {renderButton({
      onClick: onReturnToFrontPage,
      bundle: localizationData,
      language,
      key: 'returnToFrontPageTitle',
      startIcon: <Check />,
    })}
    {renderButton({
      onClick: onForward,
      bundle: localizationData,
      language,
      key: 'forwardButtonTitle',
      endIcon: <Forward />,
    })}
  </div>
);

NavigationControls.propTypes = {
  language: PropTypes.string,
  onBack: PropTypes.func,
  onFinishSurvey: PropTypes.func,
  onForward: PropTypes.func,
  onReturnToFrontPage: PropTypes.func,
  onStart: PropTypes.func,
};

export default NavigationControls;
