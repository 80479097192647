import clsx from 'clsx';
import Chip from '@material-ui/core/Chip';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Check from '@material-ui/icons/Check';
import Sync from '@material-ui/icons/Sync';
import SyncProblem from '@material-ui/icons/SyncProblem';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { openAdminDialogUnconditionally } from '../../actions/admin';
import {
  cancelSynchronization,
  requestSynchronization,
} from '../../actions/sync';
import { isApplicationOnline, isSyncInProgress } from '../../selectors/sync';
import {
  countUnsynchronizedSurveys,
  countSubmittedSurveys,
  countSurveysWithSynchronizationErrors,
} from '../../selectors/surveys';

import './SyncStatusSummary.css';

/**
 * Renders a list item in the status list with the given label, count and
 * the given class name for the count. Renders nothing if the count is
 * negative or zero.
 */
function renderListItem(label, count, className, listItemProps = {}) {
  if (count <= 0) {
    return null;
  }
  return (
    <ListItem button {...listItemProps}>
      <ListItemIcon>
        <Chip
          label={count}
          className={clsx({
            SyncStatusSummary: true,
            [className]: count > 0,
          })}
        />
      </ListItemIcon>
      <ListItemText primary={label} />
    </ListItem>
  );
}

/**
 * React component that summarizes how many surveys are waiting to
 * be submitted in various synchronization states.
 */
const SyncStatusSummary = ({
  isSyncInProgress,
  numItemsSubmitted,
  numItemsToSubmit,
  numItemsWithErrors,
  onCancelSynchronization,
  onRequestSynchronization,
  onShowSurveys,
  online,
}) => (
  <React.Fragment>
    <ListSubheader>Surveys</ListSubheader>
    {renderListItem('Submitted', numItemsSubmitted, 'submitted', {
      onClick: () => onShowSurveys('submitted'),
    })}
    {renderListItem(
      'To submit',
      numItemsToSubmit - numItemsWithErrors,
      'toSubmit',
      {
        onClick: () => onShowSurveys('toSubmit'),
      }
    )}
    {renderListItem('Error while submitting', numItemsWithErrors, 'error', {
      onClick: () => onShowSurveys('errors'),
    })}
    {numItemsToSubmit > 0 ? (
      <ListItem
        button
        disabled={!online}
        onClick={
          isSyncInProgress ? onCancelSynchronization : onRequestSynchronization
        }
      >
        <ListItemIcon>
          {!isSyncInProgress && numItemsWithErrors > 0 ? (
            <SyncProblem className="SyncStatusSummary syncProblem" />
          ) : (
            <Sync
              className={clsx({
                SyncStatusSummary: true,
                'rotating-ccw': isSyncInProgress,
              })}
            />
          )}
        </ListItemIcon>
        <ListItemText
          primary={isSyncInProgress ? 'Synchronizing...' : 'Synchronize now'}
          secondary={
            isSyncInProgress
              ? numItemsToSubmit === 1
                ? 'Only one item left'
                : `${numItemsToSubmit} item(s) left`
              : `Touch to upload ${numItemsToSubmit} item(s)`
          }
        />
      </ListItem>
    ) : (
      <ListItem button onClick={() => onShowSurveys('statistics')}>
        <ListItemIcon>
          <Check className="SyncStatusSummary check" />
        </ListItemIcon>
        <ListItemText primary="All surveys submitted" />
      </ListItem>
    )}
  </React.Fragment>
);

SyncStatusSummary.propTypes = {
  isSyncInProgress: PropTypes.bool,
  numItemsSubmitted: PropTypes.number,
  numItemsToSubmit: PropTypes.number,
  numItemsWithErrors: PropTypes.number,
  onCancelSynchronization: PropTypes.func,
  onRequestSynchronization: PropTypes.func,
  online: PropTypes.bool,
};

export default connect(
  // mapStateToProps
  (state) => ({
    numItemsSubmitted: countSubmittedSurveys(state),
    numItemsToSubmit: countUnsynchronizedSurveys(state),
    numItemsWithErrors: countSurveysWithSynchronizationErrors(state),
    isSyncInProgress: isSyncInProgress(state),
    online: isApplicationOnline(state),
  }),
  // mapDispatchToProps
  (dispatch) => ({
    onCancelSynchronization: () => {
      dispatch(cancelSynchronization());
    },
    onRequestSynchronization: () => {
      dispatch(requestSynchronization());
    },
    onShowSurveys: (which) => {
      dispatch(openAdminDialogUnconditionally(which));
    },
  })
)(SyncStatusSummary);
