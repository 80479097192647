/**
 * @file Exports a reducer that combines all UI-related reducers.
 */

import { combineReducers } from 'redux';

import lock from './lock';
import sidebar from './sidebar';
import updates from './updates';

export default combineReducers({
  lock,
  sidebar,
  updates,
});
